/**
 * 根据身份证号，取得行政区划的js
 */


var id_card_area = {

    //根据身份证号取得发证地，身份证号前六位映射到地区的结果例如“110101--北京市东城区”“411202--河南省三门峡市湖滨区”
    getAreaByIdCard : function(idCard) {
        if(!idCard || idCard.length < 6) return '';
        var provinceNo = idCard.substring(0, 2) + '0000';
        var cityNo = idCard.substring(0, 4) + '00';
        var countyNo = idCard.substring(0, 6);

        var provinceName = this.data.province[provinceNo];
        var cityName = this.data.city[cityNo];
        var countyName = this.data.county[countyNo];

        var result;
        result = (provinceName ? provinceName : '')  + '' + (cityName ? cityName : '') + '' + (countyName ? countyName : '');

        return result;
    },

    //映射数据，2011年3月从ip138的身份证号前六位查询的js中整理出来
    data:{
        province:
        {
            110000:"北京市",
            120000:"天津市",
            130000:"河北省",
            140000:"山西省",
            150000:"内蒙古自治区",
            210000:"辽宁省",
            220000:"吉林省",
            230000:"黑龙江省",
            310000:"上海市",
            320000:"江苏省",
            330000:"浙江省",
            340000:"安徽省",
            350000:"福建省",
            360000:"江西省",
            370000:"山东省",
            410000:"河南省",
            420000:"湖北省",
            430000:"湖南省",
            440000:"广东省",
            450000:"广西壮族自治区",
            460000:"海南省",
            500000:"重庆市",
            510000:"四川省",
            520000:"贵州省",
            530000:"云南省",
            540000:"西藏自治区",
            610000:"陕西省",
            620000:"甘肃省",
            630000:"青海省",
            640000:"宁夏回族自治区",
            650000:"新疆维吾尔自治区",
            710000:"台湾省",
            810000:"香港特别行政区",
            820000:"澳门特别行政区"
        },
        city:{
            130100:"石家庄市",
            130200:"唐山市",
            130300:"秦皇岛市",
            130400:"邯郸市",
            130500:"邢台市",
            130600:"保定市",
            130700:"张家口市",
            130800:"承德市",
            130900:"沧州市",
            131000:"廊坊市",
            131100:"衡水市",
            132100:"邯郸地区",
            132200:"邢台地区",
            132300:"石家庄市",
            132400:"保定地区",
            132500:"张家口地区",
            132600:"承德地区",
            132800:"廊坊地区",
            132900:"沧州地区",
            133000:"衡水地区",
            140100:"太原市",
            140200:"大同市",
            140300:"阳泉市",
            140400:"长治市",
            140500:"晋城市",
            140600:"朔州市",
            140700:"晋中市",
            140800:"运城市",
            140900:"忻州市",
            141000:"临汾市",
            141100:"吕梁市",
            142100:"雁北地区",
            142200:"忻州地区",
            142300:"吕梁地区",
            142400:"晋中地区",
            142600:"临汾地区",
            142700:"运城地区",
            150100:"呼和浩特市",
            150200:"包头市",
            150300:"乌海市",
            150400:"赤峰市",
            150500:"通辽市",
            150600:"鄂尔多斯市",
            150700:"呼伦贝尔市",
            150800:"巴彦淖尔市",
            150900:"乌兰察布市",
            152100:"呼伦贝尔盟",
            152200:"兴安盟",
            152300:"哲里木盟",
            152500:"锡林郭勒盟",
            152600:"乌兰察布盟",
            152700:"伊克昭盟",
            152800:"巴彦淖尔盟",
            152900:"阿拉善盟",
            210100:"沈阳市",
            210200:"大连市",
            210300:"鞍山市",
            210400:"抚顺市",
            210500:"本溪市",
            210600:"丹东市",
            210700:"锦州市",
            210800:"营口市",
            210900:"阜新市",
            211000:"辽阳市",
            211100:"盘锦市",
            211200:"铁岭市",
            211300:"朝阳市",
            211400:"葫芦岛市",
            212100:"铁岭市",
            212200:"朝阳市",
            220100:"长春市",
            220200:"吉林市",
            220300:"四平市",
            220400:"辽源市",
            220500:"通化市",
            220600:"白山市",
            220700:"松原市",
            220800:"白城市",
            222300:"白城地区",
            222400:"延边朝鲜族自治州",
            230100:"哈尔滨市",
            230200:"齐齐哈尔市",
            230300:"鸡西市",
            230400:"鹤岗市",
            230500:"双鸭山市",
            230600:"大庆市",
            230700:"伊春市",
            230800:"佳木斯市",
            230900:"七台河市",
            231000:"牡丹江市",
            231100:"黑河市",
            231200:"绥化市",
            232100:"松花江地区",
            232300:"绥化地区",
            232400:"佳木斯地区",
            232500:"牡丹江地区",
            232600:"黑河地区",
            232700:"大兴安岭地区",
            320100:"南京市",
            320200:"无锡市",
            320300:"徐州市",
            320400:"常州市",
            320500:"苏州市",
            320600:"南通市",
            320700:"连云港市",
            320800:"淮安市",
            320900:"盐城市",
            321000:"扬州市",
            321100:"镇江市",
            321200:"泰州市",
            321300:"宿迁市",
            330100:"杭州市",
            331000:"台州市",
            331100:"丽水市",
            332500:"丽水地区",
            332600:"台州地区",
            332700:"舟山地区",
            330200:"宁波市",
            330300:"温州市",
            330400:"嘉兴市",
            330500:"湖州市",
            330600:"绍兴市",
            330700:"金华市",
            330800:"衢州市",
            330900:"舟山市",
            341200:"阜阳市",
            341300:"宿州市",
            341400:"巢湖市",
            341500:"六安市",
            341600:"亳州市",
            341700:"池州市",
            341800:"宣城市",
            342100:"阜阳地区",
            342200:"宿县地区",
            342300:"滁县地区",
            342400:"六安地区",
            342500:"宣城地区",
            342600:"巢湖地区",
            342700:"徽州地区",
            342800:"安庆地区",
            342900:"池州地区",
            340100:"合肥市",
            340200:"芜湖市",
            340300:"蚌埠市",
            340400:"淮南市",
            340500:"马鞍山市",
            340600:"淮北市",
            340700:"铜陵市",
            340800:"安庆市",
            341000:"黄山市",
            341100:"滁州市",
            350900:"宁德市",
            352100:"南平地区",
            352200:"宁德地区",
            352600:"龙岩地区",
            352700:"三明市",
            350100:"福州市",
            350200:"厦门市",
            350300:"莆田市",
            350400:"三明市",
            350500:"泉州市",
            350600:"漳州市",
            350700:"南平市",
            350800:"龙岩市",
            360100:"南昌市",
            360200:"景德镇市",
            361100:"上饶市",
            362100:"赣州地区",
            362200:"宜春地区",
            362300:"上饶地区",
            360300:"萍乡市",
            360400:"九江市",
            360500:"新余市",
            360600:"鹰潭市",
            360700:"赣州市",
            360800:"吉安市",
            360900:"宜春市",
            361000:"抚州市",
            362400:"吉安地区",
            362500:"抚州地区",
            362600:"九江地区",
            370200:"青岛市",
            370300:"淄博市",
            370400:"枣庄市",
            370500:"东营市",
            370600:"烟台市",
            370700:"潍坊市",
            370800:"济宁市",
            370900:"泰安市",
            371000:"威海市",
            371100:"日照市",
            371200:"莱芜市",
            371300:"临沂市",
            371400:"德州市",
            371500:"聊城市",
            371600:"滨州市",
            370100:"济南市",
            371700:"菏泽市",
            372100:"烟台地区",
            372200:"潍坊地区",
            372300:"滨州地区",
            372400:"德州地区",
            372500:"聊城市",
            372600:"泰安地区",
            372700:"济宁地区",
            372800:"临沂地区",
            372900:"菏泽地区",
            410600:"鹤壁市",
            410700:"新乡市",
            410800:"焦作市",
            410900:"濮阳市",
            411000:"许昌市",
            411100:"漯河市",
            411200:"三门峡市",
            411300:"南阳市",
            410100:"郑州市",
            410200:"开封市",
            410300:"洛阳市",
            410400:"平顶山市",
            410500:"安阳市",
            411400:"商丘市",
            411500:"信阳市",
            411600:"周口市",
            411700:"驻马店市",
            412300:"商丘地区",
            412400:"开封地区",
            412500:"三门峡市",
            412700:"周口地区",
            412800:"驻马店地区",
            412900:"南阳市",
            413000:"信阳地区",
            420500:"宜昌市",
            420600:"襄樊市",
            420700:"鄂州市",
            420800:"荆门市",
            420900:"孝感市",
            421000:"荆州市",
            421100:"黄冈市",
            421200:"咸宁市",
            421300:"随州市",
            422100:"黄冈地区",
            422200:"孝感地区",
            422900:"省直辖行政单位",
            429000:"省直辖行政单位",
            420100:"武汉市",
            420200:"黄石市",
            420300:"十堰市",
            420400:"沙市",
            422300:"咸宁地区",
            422400:"荆州地区",
            422500:"随州地区",
            422600:"郧阳地区",
            422700:"宜昌地区",
            422800:"恩施土家族苗族自治州",
            430100:"长沙市",
            430200:"株洲市",
            430300:"湘潭市",
            430400:"衡阳市",
            432100:"湘潭地区",
            432200:"岳阳地区",
            432300:"益阳地区",
            432400:"常德地区",
            432500:"娄底地区",
            432600:"邵阳地区",
            432700:"衡阳地区",
            432800:"郴州地区",
            432900:"零陵地区",
            430500:"邵阳市",
            430600:"岳阳市",
            430700:"常德市",
            430800:"张家界市",
            430900:"益阳市",
            431000:"郴州市",
            431100:"永州市",
            431200:"怀化市",
            431300:"娄底市",
            433000:"怀化地区",
            433100:"湘西土家族苗族自治州",
            440100:"广州市",
            440200:"韶关市",
            440300:"深圳市",
            440400:"珠海市",
            440500:"汕头市",
            440600:"佛山市",
            440700:"江门市",
            441800:"清远市",
            441900:"东莞市",
            442000:"中山市",
            442700:"佛山市",
            442900:"湛江市",
            445100:"潮州市",
            445200:"揭阳市",
            440800:"湛江市",
            440900:"茂名市",
            441200:"肇庆市",
            441300:"惠州市",
            441400:"梅州市",
            441500:"汕尾市",
            441600:"河源市",
            441700:"阳江市",
            445300:"云浮市",
            451000:"百色市",
            451100:"贺州市",
            451200:"河池市",
            451300:"来宾市",
            451400:"崇左市",
            452100:"南宁地区",
            452200:"柳州地区",
            450100:"南宁市",
            450200:"柳州市",
            450300:"桂林市",
            450400:"梧州市",
            450500:"北海市",
            450600:"防城港市",
            450700:"钦州市",
            450800:"贵港市",
            450900:"玉林市",
            452300:"桂林地区",
            452400:"贺州地区",
            452500:"玉林地区",
            452600:"百色地区",
            452700:"河池地区",
            452800:"钦州地区",
            460100:"海口市",
            460200:"三亚市",
            500300:"市",
            510300:"自贡市",
            510400:"攀枝花市",
            510500:"泸州市",
            510600:"德阳市",
            510700:"绵阳市",
            510800:"广元市",
            510900:"遂宁市",
            511000:"内江市",
            512200:"万县地区",
            512300:"涪陵地区",
            512500:"宜宾地区",
            512900:"南充地区",
            513000:"达川地区",
            513100:"雅安地区",
            513200:"阿坝藏族羌族自治州",
            513300:"甘孜藏族自治州",
            513400:"凉山彝族自治州",
            510100:"成都市",
            510200:"重庆市",
            511100:"乐山市",
            511200:"万县市",
            511300:"南充市",
            511400:"眉山市",
            511500:"宜宾市",
            511600:"广安市",
            511700:"达州市",
            511800:"雅安市",
            511900:"巴中市",
            512000:"资阳市",
            513500:"黔江地区",
            513600:"广安市",
            513700:"巴中地区",
            513800:"眉山地区",
            513900:"资阳地区",
            520100:"贵阳市",
            520200:"六盘水市",
            520300:"遵义市",
            520400:"安顺市",
            522100:"遵义地区",
            522200:"铜仁地区",
            522300:"黔西南布依族苗族自治州",
            522400:"毕节地区",
            522500:"安顺地区",
            522600:"黔东南苗族侗族自治州",
            522700:"黔南布依族苗族自治州",
            530600:"昭通市",
            530700:"丽江市",
            530800:"思茅市",
            530900:"临沧市",
            532100:"昭通地区",
            532200:"曲靖地区",
            532300:"楚雄彝族自治州",
            533000:"保山地区",
            533100:"德宏傣族景颇族自治州",
            533200:"丽江地区",
            533300:"怒江傈僳族自治州",
            530100:"昆明市",
            530300:"曲靖市",
            530400:"玉溪市",
            530500:"保山市",
            532400:"玉溪地区",
            532500:"红河哈尼族彝族自治州",
            532600:"文山壮族苗族自治州",
            532700:"思茅地区",
            532800:"西双版纳傣族自治州",
            532900:"大理白族自治州",
            533400:"迪庆藏族自治州",
            533500:"临沧地区",
            542200:"山南地区",
            542300:"日喀则地区",
            542400:"那曲地区",
            540100:"拉萨市",
            542100:"昌都地区",
            542500:"阿里地区",
            542600:"林芝地区",
            542700:"江孜地区",
            610100:"西安市",
            610900:"安康市",
            611000:"商洛市",
            612100:"渭南地区",
            612200:"咸阳地区",
            612300:"汉中地区",
            612400:"安康地区",
            610200:"铜川市",
            610300:"宝鸡市",
            610400:"咸阳市",
            610500:"渭南市",
            610600:"延安市",
            610700:"汉中市",
            610800:"榆林市",
            612500:"商洛地区",
            612600:"延安地区",
            612700:"榆林地区",
            620800:"平凉市",
            620900:"酒泉市",
            621000:"庆阳市",
            621100:"定西市",
            621200:"陇南市",
            622100:"酒泉地区",
            622200:"张掖地区",
            622300:"武威地区",
            622400:"定西地区",
            622600:"陇南地区",
            622700:"平凉地区",
            622800:"庆阳地区",
            622900:"临夏回族自治州",
            623000:"甘南藏族自治州",
            620100:"兰州市",
            620200:"嘉峪关市",
            620300:"金昌市",
            620400:"白银市",
            620500:"天水市",
            620600:"武威市",
            620700:"张掖市",
            630100:"西宁市",
            632500:"海南藏族自治州",
            632600:"果洛藏族自治州",
            632700:"玉树藏族自治州",
            632800:"海西蒙古族藏族自治州",
            632100:"海东地区",
            632200:"海北藏族自治州",
            632300:"黄南藏族自治州",
            640100:"银川市",
            640200:"石嘴山市",
            640300:"吴忠市",
            640400:"固原市",
            640500:"中卫市",
            642100:"吴忠市",
            642200:"固原地区",
            650100:"乌鲁木齐市",
            650200:"克拉玛依市",
            652100:"吐鲁番地区",
            652200:"哈密地区",
            652300:"昌吉回族自治州",
            652400:"伊犁哈萨克自治州",
            652500:"塔城地区",
            652600:"阿勒泰地区",
            654300:"阿勒泰地区",
            659000:"省直辖行政单位",
            652700:" 博尔塔拉蒙古自治州",
            652800:"巴音郭楞蒙古自治州",
            652900:"阿克苏地区",
            653000:"克孜勒苏柯尔克孜自治州",
            653100:"喀什地区",
            653200:"和田地区",
            654000:"伊犁哈萨克自治州",
            654100:"伊犁地区",
            654200:"塔城地区"
        },
        county:{
            110101:"东城区",
            110102:"西城区",
            110103:"崇文区",
            110104:"宣武区",
            110105:"朝阳区",
            110106:"丰台区",
            110107:"石景山区",
            110108:"海淀区",
            110109:"门头沟区",
            110110:"燕山区",
            110111:"房山区",
            110112:"通州区",
            110113:"顺义区",
            110114:"昌平区",
            110115:"大兴区",
            110116:"怀柔区",
            110117:"平谷区",
            110221:"昌平县",
            110222:"顺义县",
            110223:"通县",
            110224:"大兴县",
            110225:"房山县",
            110226:"平谷县",
            110227:"怀柔县",
            110228:"密云县",
            110229:"延庆县",
            120101:"和平区",
            120102:"河东区",
            120103:"河西区",
            120104:"南开区",
            120105:"河北区",
            120106:"红桥区",
            120107:"塘沽区",
            120108:"汉沽区",
            120109:"大港区",
            120110:"东丽区",
            120111:"西青区",
            120112:"津南区",
            120113:"北辰区",
            120114:"武清区",
            120115:"宝坻区",
            120221:"宁河县",
            120222:"武清县",
            120223:"静海县",
            120224:"宝坻县",
            120225:"蓟县",
            130101:"市辖区",
            130102:"长安区",
            130103:"桥东区",
            130104:"桥西区",
            130105:"新华区",
            130106:"郊区",
            130107:"井陉矿区",
            130108:"裕华区",
            130121:"井陉县",
            130122:"获鹿县",
            130123:"正定县",
            130124:"栾城县",
            130125:"行唐县",
            130126:"灵寿县",
            130127:"高邑县",
            130128:"深泽县",
            130129:"赞皇县",
            130130:"无极县",
            130131:"平山县",
            130132:"元氏县",
            130133:"赵县",
            130181:"辛集市",
            130182:"藁城市",
            130183:"晋州市",
            130184:"新乐市",
            130185:"鹿泉市",
            130201:"市辖区",
            130202:"路南区",
            130203:"路北区",
            130204:"古冶区",
            130205:"开平区",
            130206:"新区",
            130207:"丰南区",
            130208:"丰润区",
            130221:"丰润县",
            130222:"丰南县",
            130223:"滦县",
            130224:"滦南县",
            130225:"乐亭县",
            130226:"迁安县",
            130227:"迁西县",
            130228:"遵化县",
            130229:"玉田县",
            130230:"唐海县",
            130281:"遵化市",
            130282:"丰南市",
            130283:"迁安市",
            130301:"市辖区",
            130302:"海港区",
            130303:"山海关区",
            130304:"北戴河区",
            130321:"青龙满族自治县",
            130322:"昌黎县",
            130323:"抚宁县",
            130324:"卢龙县",
            130401:"市辖区",
            130402:"邯山区",
            130403:"丛台区",
            130404:"复兴区",
            130405:"郊区",
            130406:"峰峰矿区",
            130421:"邯郸县",
            130422:"武安县",
            130423:"临漳县",
            130424:"成安县",
            130425:"大名县",
            130426:"涉县",
            130427:"磁县",
            130428:"肥乡县",
            130429:"永年县",
            130430:"邱县",
            130431:"鸡泽县",
            130432:"广平县",
            130433:"馆陶县",
            130434:"魏县",
            130435:"曲周县",
            130481:"武安市",
            130501:"市辖区",
            130502:"桥东区",
            130503:"桥西区",
            130521:"邢台县",
            130522:"临城县",
            130523:"内丘县",
            130524:"柏乡县",
            130525:"隆尧县",
            130526:"任县",
            130527:"南和县",
            130528:"宁晋县",
            130529:"巨鹿县",
            130530:"新河县",
            130531:"广宗县",
            130532:"平乡县",
            130533:"威县",
            130534:"清河县",
            130535:"临西县",
            130581:"南宫市",
            130582:"沙河市",
            130601:"市辖区",
            130602:"新市区",
            130603:"北市区",
            130604:"南市区",
            130621:"满城县",
            130622:"清苑县",
            130623:"涞水县",
            130624:"阜平县",
            130625:"徐水县",
            130626:"定兴县",
            130627:"唐县",
            130628:"高阳县",
            130629:"容城县",
            130630:"涞源县",
            130631:"望都县",
            130632:"安新县",
            130633:"易县",
            130634:"曲阳县",
            130635:"蠡县",
            130636:"顺平县",
            130637:"博野县",
            130638:"雄县",
            130681:"涿州市",
            130682:"定州市",
            130683:"安国市",
            130684:"高碑店市",
            130701:"市辖区",
            130702:"桥东区",
            130703:"桥西区",
            130705:"宣化区",
            130706:"下花园区",
            130721:"宣化县",
            130722:"张北县",
            130723:"康保县",
            130724:"沽源县",
            130725:"尚义县",
            130726:"蔚县",
            130727:"阳原县",
            130728:"怀安县",
            130729:"万全县",
            130730:"怀来县",
            130731:"涿鹿县",
            130732:"赤城县",
            130733:"崇礼县",
            130801:"市辖区",
            130802:"双桥区",
            130803:"双滦区",
            130804:"鹰手营子矿区",
            130821:"承德县",
            130822:"兴隆县",
            130823:"平泉县",
            130824:"滦平县",
            130825:"隆化县",
            130826:"丰宁满族自治县",
            130827:"宽城满族自治县",
            130828:"围场满族蒙古族自治县",
            130901:"市辖区",
            130902:"新华区",
            130903:"运河区",
            130904:"郊区",
            130921:"沧县",
            130922:"青县",
            130923:"东光县",
            130924:"海兴县",
            130925:"盐山县",
            130926:"肃宁县",
            130927:"南皮县",
            130928:"吴桥县",
            130929:"献县",
            130930:"孟村回族自治县",
            130981:"泊头市",
            130982:"任丘市",
            130983:"黄骅市",
            130984:"河间市",
            131001:"市辖区",
            131002:"安次区",
            131003:"广阳区",
            131021:"三河县",
            131022:"固安县",
            131023:"永清县",
            131024:"香河县",
            131025:"大城县",
            131026:"文安县",
            131027:"霸县",
            131028:"大厂回族自治县",
            131081:"霸州市",
            131082:"三河市",
            131101:"市辖区",
            131102:"桃城区",
            131121:"枣强县",
            131122:"武邑县",
            131123:"武强县",
            131124:"饶阳县",
            131125:"安平县",
            131126:"故城县",
            131127:"景县",
            131128:"阜城县",
            131181:"冀州市",
            131182:"深州市",
            132101:"邯郸市",
            132121:"大名县",
            132122:"魏县",
            132123:"曲周县",
            132124:"丘县",
            132125:"鸡泽县",
            132126:"肥乡县",
            132127:"广平县",
            132128:"成安县",
            132129:"临漳县",
            132130:"磁县",
            132131:"武安县",
            132132:"涉县",
            132133:"永年县",
            132134:"邯郸县",
            132135:"馆陶县",
            132201:"南宫市",
            132202:"沙河市",
            132221:"邢台县",
            132222:"沙河县",
            132223:"临城县",
            132224:"内丘县",
            132225:"柏乡县",
            132226:"隆尧县",
            132227:"任县",
            132228:"南和县",
            132229:"宁晋县",
            132230:"南宫县",
            132231:"巨鹿县",
            132232:"新河县",
            132233:"广宗县",
            132234:"平乡县",
            132235:"威县",
            132236:"清河县",
            132237:"临西县",
            132301:"辛集市",
            132302:"藁城市",
            132321:"束鹿县",
            132322:"晋县",
            132323:"深泽县",
            132324:"无极县",
            132325:"藁城县",
            132326:"赵县",
            132327:"栾城县",
            132328:"正定县",
            132329:"新乐县",
            132330:"高邑县",
            132331:"元氏县",
            132332:"赞皇县",
            132333:"井陉县",
            132334:"获鹿县",
            132335:"平山县",
            132336:"灵寿县",
            132337:"行唐县",
            132401:"定州市",
            132402:"涿州市",
            132403:"安国市",
            132404:"高碑店市",
            132421:"易县",
            132422:"满城县",
            132423:"徐水县",
            132424:"涞源县",
            132425:"定兴县",
            132426:"完县",
            132427:"唐县",
            132428:"望都县",
            132429:"涞水县",
            132430:"涿县",
            132431:"清苑县",
            132432:"高阳县",
            132433:"安新县",
            132434:"雄县",
            132435:"容城县",
            132436:"新城县",
            132437:"曲阳县",
            132438:"阜平县",
            132439:"定县",
            132440:"安国县",
            132441:"博野县",
            132442:"蠡县",
            132501:"张家口市",
            132521:"张北市",
            132522:"康保县",
            132523:"沽源县",
            132524:"尚义县",
            132525:"蔚县",
            132526:"阳原县",
            132527:"怀安县",
            132528:"万全县",
            132529:"怀来县",
            132530:"涿鹿县",
            132531:"宣化县",
            132532:"赤城县",
            132533:"崇礼县",
            132601:"承德市",
            132621:"青龙县",
            132622:"宽城满族自治县",
            132623:"兴隆县",
            132624:"平泉县",
            132625:"承德县",
            132626:"滦平县",
            132627:"丰宁满族自治县",
            132628:"隆化县",
            132629:"围场满族蒙古族自治县",
            132801:"廊坊市",
            132821:"三河县",
            132822:"大厂回族自治县",
            132823:"香河县",
            132825:"永清县",
            132826:"固安县",
            132827:"霸县",
            132828:"文安县",
            132829:"大城县",
            132901:"沧州市",
            132902:"泊头市",
            132903:"任丘市",
            132904:"黄骅市",
            132905:"河间市",
            132921:"沧县",
            132922:"河间县",
            132923:"肃宁县",
            132924:"献县",
            132925:"交河县",
            132926:"吴桥县",
            132927:"东光县",
            132928:"南皮县",
            132929:"盐山县",
            132930:"黄骅县",
            132931:"孟村回族自治县",
            132932:"青县",
            132933:"任丘县",
            132934:"海兴县",
            133001:"衡水市",
            133002:"冀州市",
            133021:"衡水县",
            133022:"冀县",
            133023:"枣强县",
            133024:"武邑县",
            133025:"深县",
            133026:"武强县",
            133027:"饶阳县",
            133028:"安平县",
            133029:"故城县",
            133030:"景县",
            133031:"阜城县",
            139001:"武安市",
            140101:"市辖区",
            140102:"南城区",
            140103:"北城区",
            140104:"河西区",
            140105:"小店区",
            140106:"迎泽区",
            140107:"杏花岭区",
            140108:"尖草坪区",
            140109:"万柏林区",
            140110:"晋源区",
            140111:"古交工矿区",
            140112:"南郊区",
            140113:"北郊区",
            140121:"清徐县",
            140122:"阳曲县",
            140123:"娄烦县",
            140181:"古交市",
            140201:"市辖区",
            140202:"城区",
            140203:"矿区",
            140211:"南郊区",
            140212:"新荣区",
            140221:"阳高县",
            140222:"天镇县",
            140223:"广灵县",
            140224:"灵丘县",
            140225:"浑源县",
            140226:"左云县",
            140227:"大同县",
            140230:"左云县",
            140232:"大同县",
            140301:"市辖区",
            140302:"城区",
            140303:"矿区",
            140311:"郊区",
            140321:"平定县",
            140322:"盂县",
            140401:"市辖区",
            140402:"城区",
            140411:"郊区",
            140421:"长治县",
            140423:"襄垣县",
            140424:"屯留县",
            140425:"平顺县",
            140426:"黎城县",
            140427:"壶关县",
            140428:"长子县",
            140429:"武乡县",
            140430:"沁县",
            140431:"沁源县",
            140481:"潞城市",
            140501:"市辖区",
            140502:"城区",
            140511:"郊区",
            140521:"沁水县",
            140522:"阳城县",
            140523:"高平县",
            140524:"陵川县",
            140525:"泽州县",
            140581:"高平市",
            140601:"市辖区",
            140602:"朔城区",
            140603:"平鲁区",
            140621:"山阴县",
            140622:"应县",
            140623:"右玉县",
            140624:"怀仁县",
            140701:"市辖区",
            140702:"榆次区",
            140721:"榆社县",
            140722:"左权县",
            140723:"和顺县",
            140724:"昔阳县",
            140725:"寿阳县",
            140726:"太谷县",
            140727:"祁县",
            140728:"平遥县",
            140729:"灵石县",
            140781:"介休市",
            140801:"市辖区",
            140802:"盐湖区",
            140821:"临猗县",
            140822:"万荣县",
            140823:"闻喜县",
            140824:"稷山县",
            140825:"新绛县",
            140826:"绛县",
            140827:"垣曲县",
            140828:"夏县",
            140829:"平陆县",
            140830:"芮城县",
            140881:"永济市",
            140882:"河津市",
            140901:"市辖区",
            140902:"忻府区",
            140921:"定襄县",
            140922:"五台县",
            140923:"代县",
            140924:"繁峙县",
            140925:"宁武县",
            140926:"静乐县",
            140927:"神池县",
            140928:"五寨县",
            140929:"岢岚县",
            140930:"河曲县",
            140931:"保德县",
            140932:"偏关县",
            140981:"原平市",
            141001:"市辖区",
            141002:"尧都区",
            141021:"曲沃县",
            141022:"翼城县",
            141023:"襄汾县",
            141024:"洪洞县",
            141025:"古县",
            141026:"安泽县",
            141027:"浮山县",
            141028:"吉县",
            141029:"乡宁县",
            141030:"大宁县",
            141031:"隰县",
            141032:"永和县",
            141033:"蒲县",
            141034:"汾西县",
            141081:"侯马市",
            141082:"霍州市",
            141101:"市辖区",
            141102:"离石区",
            141121:"文水县",
            141122:"交城县",
            141123:"兴县",
            141124:"临县",
            141125:"柳林县",
            141126:"石楼县",
            141127:"岚县",
            141128:"方山县",
            141129:"中阳县",
            141130:"交口县",
            141181:"孝义市",
            141182:"汾阳市",
            142121:"阳高县",
            142122:"天镇县",
            142123:"广灵县",
            142124:"灵丘县",
            142125:"浑源县",
            142126:"应县",
            142127:"山阴县",
            142128:"朔县",
            142129:"平鲁县",
            142130:"左云县",
            142131:"右玉县",
            142132:"大同县",
            142133:"怀仁县",
            142201:"忻州市",
            142202:"原平市",
            142222:"定襄县",
            142223:"五台县",
            142225:"代县",
            142226:"繁峙县",
            142227:"宁武县",
            142228:"静乐县",
            142229:"神池县",
            142230:"五寨县",
            142231:"岢岚县",
            142232:"河曲县",
            142233:"保德县",
            142234:"偏关县",
            142301:"孝义市",
            142302:"离石市",
            142303:"汾阳市",
            142321:"汾阳县",
            142322:"文水县",
            142323:"交城县",
            142325:"兴县",
            142326:"临县",
            142327:"柳林县",
            142328:"石楼县",
            142329:"岚县",
            142330:"方山县",
            142332:"中阳县",
            142333:"交口县",
            142401:"榆次市",
            142402:"介休市",
            142421:"榆社县",
            142422:"左权县",
            142423:"和顺县",
            142424:"昔阳县",
            142427:"寿阳县",
            142429:"太谷县",
            142430:"祁县",
            142431:"平遥县",
            142433:"灵石县",
            142601:"临汾市",
            142602:"侯马市",
            142603:"霍州市",
            142621:"曲沃县",
            142622:"翼城县",
            142623:"襄汾县",
            142625:"洪洞县",
            142627:"古县",
            142628:"安泽县",
            142629:"浮山县",
            142630:"吉县",
            142631:"乡宁县",
            142632:"蒲县",
            142633:"大宁县",
            142634:"永和县",
            142635:"隰县",
            142636:"汾西县",
            142701:"运城市",
            142702:"永济市",
            142703:"河津市",
            142723:"芮城县",
            142724:"临猗县",
            142725:"万荣县",
            142726:"新绛县",
            142727:"稷山县",
            142729:"闻喜县",
            142730:"夏县",
            142731:"绛县",
            142732:"平陆县",
            142733:"垣曲县",
            149001:"古交市",
            150101:"市辖区",
            150102:"新城区",
            150103:"回民区",
            150104:"玉泉区",
            150105:"郊区",
            150121:"土默特左旗",
            150122:"托克托县",
            150123:"和林格尔县",
            150124:"清水河县",
            150125:"武川县",
            150201:"市辖区",
            150202:"东河区",
            150203:"昆都伦区",
            150204:"青山区",
            150205:"石拐矿区",
            150206:"白云矿区",
            150207:"郊区",
            150221:"土默特右旗",
            150222:"固阳县",
            150223:"达尔罕茂明安联合旗",
            150301:"市辖区",
            150302:"海勃湾区",
            150303:"海南区",
            150304:"乌达区",
            150401:"市辖区",
            150402:"红山区",
            150403:"元宝山区",
            150404:"松山区",
            150421:"阿鲁科尔沁旗",
            150422:"巴林左旗",
            150423:"巴林右旗",
            150424:"林西县",
            150425:"克什克腾旗",
            150426:"翁牛特旗",
            150428:"喀喇沁旗",
            150429:"宁城县",
            150430:"敖汉旗",
            150501:"市辖区",
            150502:"科尔沁区",
            150521:"科尔沁左翼中旗",
            150522:"科尔沁左翼后旗",
            150523:"开鲁县",
            150524:" 库伦旗",
            150525:"奈曼旗",
            150526:"扎鲁特旗",
            150581:"霍林郭勒市",
            150601:"鄂尔多斯市",
            150602:"东胜区",
            150621:"达拉特旗",
            150622:"准格尔旗",
            150623:"鄂托克前旗",
            150624:"鄂托克旗",
            150625:"杭锦旗",
            150626:"乌审旗",
            150627:"伊金霍洛旗",
            150701:"市辖区",
            150702:"海拉尔区",
            150721:"阿荣旗",
            150722:"莫力达瓦达斡尔族自治旗",
            150723:"鄂伦春自治旗",
            150724:"鄂温克族自治旗",
            150725:"陈巴尔虎旗",
            150726:"新巴尔虎左旗",
            150727:"新巴尔虎右旗",
            150781:"满洲里市",
            150782:"牙克石市",
            150783:"扎兰屯市",
            150784:"额尔古纳市",
            150785:"根河市",
            150801:"市辖区",
            150802:"临河区",
            150821:"五原县",
            150822:"磴口县",
            150823:"乌拉特前旗",
            150824:"乌拉特中旗",
            150825:"乌拉特后旗",
            150826:"杭锦后旗",
            150901:"市辖区",
            150902:"集宁区",
            150921:"卓资县",
            150922:"化德县",
            150923:"商都县",
            150924:"兴和县",
            150925:"凉城县",
            150926:"察哈尔右翼前旗",
            150927:"察哈尔右翼中旗",
            150928:"察哈尔右翼后旗",
            150929:"四子王旗",
            150981:"丰镇市",
            152101:"海拉尔市",
            152102:"满洲里市",
            152103:"扎兰屯市",
            152104:"牙克石市",
            152105:"根河市",
            152106:"额尔古纳市",
            152121:"布特哈旗",
            152122:"阿荣旗",
            152123:"莫力达瓦达斡尔族自治旗",
            152124:"喜桂图旗",
            152125:"额尔古纳右旗",
            152126:"额尔古纳左旗",
            152127:"鄂伦春自治旗",
            152128:"鄂温克族自治旗",
            152129:"新巴尔虎右旗",
            152130:"新巴尔虎左旗",
            152131:"陈巴尔虎旗",
            152201:"乌兰浩特市",
            152202:"阿尔山市",
            152221:"科尔沁右翼前旗",
            152222:" 科尔沁右翼中旗",
            152223:"扎赉特旗",
            152224:"突泉县",
            152301:"通辽市",
            152302:"霍林郭勒市",
            152321:" 通辽县",
            152322:"科尔沁左翼中旗",
            152323:"科尔沁左翼后旗",
            152324:"开鲁县",
            152325:"库伦旗",
            152326:"奈曼旗",
            152327:"扎鲁特旗",
            152501:"二连浩特市",
            152502:"锡林浩特市",
            152522:"阿巴嘎旗",
            152523:"苏尼特左旗",
            152524:"苏尼特右旗",
            152525:"东乌珠穆沁旗",
            152526:"西乌珠穆沁旗",
            152527:"太仆寺旗",
            152528:"镶黄旗",
            152529:"正镶白旗",
            152530:"正蓝旗",
            152531:"多伦县",
            152601:"集宁市",
            152602:"丰镇市",
            152621:"武川县",
            152622:"和林格尔县",
            152623:"清水河县",
            152624:"卓资县",
            152625:"化德县",
            152626:"商都县",
            152627:"兴和县",
            152628:"丰镇县",
            152629:"凉城县",
            152630:"察哈尔右翼前旗",
            152631:"察哈尔右翼中旗",
            152632:"察哈尔右翼后旗",
            152633:"达尔罕茂明安联合旗",
            152634:"四子王旗",
            152701:"东胜市",
            152721:"东胜县",
            152722:"达拉特旗",
            152723:"准格尔旗",
            152724:"鄂托克前旗",
            152725:"鄂托克旗",
            152726:"杭锦旗",
            152727:"乌审旗",
            152728:"伊金霍洛旗",
            152801:"临河市",
            152821:"临河县",
            152822:"五原县",
            152823:"磴口县",
            152824:"乌拉特前旗",
            152825:"乌拉特中旗",
            152826:"乌拉特后旗",
            152827:"杭锦后旗",
            152921:"阿拉善左旗",
            152922:"阿拉善右旗",
            152923:"额济纳旗",
            210101:"市辖区",
            210102:"和平区",
            210103:"沈河区",
            210104:"大东区",
            210105:"皇姑区",
            210106:"铁西区",
            210111:"苏家屯区",
            210112:"东陵区",
            210113:"沈北新区",
            210114:"于洪区",
            210120:"市区",
            210121:"新民县",
            210122:"辽中县",
            210123:"康平县",
            210124:"法库县",
            210181:"新民市",
            210201:"市辖区",
            210202:"中山区",
            210203:"西岗区",
            210204:"沙河口区",
            210211:"甘井子区",
            210212:"旅顺口区",
            210213:"金州区",
            210219:"瓦房店市",
            210220:"市区",
            210221:"金县",
            210222:"新金县",
            210223:"复县",
            210224:"长海县",
            210225:"庄河县",
            210281:"瓦房店市",
            210282:"普兰店市",
            210283:"庄河市",
            210301:"市辖区",
            210302:"铁东区",
            210303:"铁西区",
            210304:"立山区",
            210311:"千山区",
            210319:"开发区",
            210321:"台安县",
            210323:"岫岩满族自治县",
            210381:"海城市",
            210401:"市辖区",
            210402:"新抚区",
            210403:"东洲区",
            210404:"望花区",
            210411:"顺城区",
            210421:"抚顺县",
            210422:"新宾满族自治县",
            210423:"清原满族自治县",
            210501:"市辖区",
            210502:"平山区",
            210503:"溪湖区",
            210504:"明山区",
            210505:"南芬区",
            210511:"南芬区",
            210521:"本溪满族自治县",
            210522:"桓仁满族自治县",
            210601:"市辖区",
            210602:"元宝区",
            210603:"振兴区",
            210604:"振安区",
            210620:"市区",
            210621:"凤城满族自治县",
            210622:"岫岩满族自治县",
            210623:"东沟县",
            210624:"宽甸满族自治县",
            210681:"东港市",
            210682:"凤城市",
            210701:"市辖区",
            210702:"古塔区",
            210703:"凌河区",
            210704:"南票区",
            210705:"葫芦岛区",
            210711:"太和区",
            210719:"锦西市",
            210720:"市区",
            210721:"锦西县",
            210722:"兴城县",
            210723:"绥中县",
            210724:"锦县",
            210725:"北镇满族自治县",
            210726:"黑山县",
            210727:"义县",
            210781:"凌海市",
            210782:"北镇市",
            210801:"市辖区",
            210802:"站前区",
            210803:"西市区",
            210804:"鲅鱼圈区",
            210811:"老边区",
            210821:"营口县",
            210824:"盖县",
            210881:"盖州市",
            210882:"大石桥市",
            210901:"市辖区",
            210902:"海州区",
            210903:"新邱区",
            210904:"太平区",
            210905:"清河门区",
            210911:"细河区",
            210921:"阜新蒙古族自治县",
            210922:"彰武县",
            211001:"市辖区",
            211002:"白塔区",
            211003:"文圣区",
            211004:"宏伟区",
            211005:"弓长岭区",
            211011:"太子河区",
            211021:"辽阳县",
            211022:"灯塔县",
            211081:"灯塔市",
            211101:"市辖区",
            211102:"双台子区",
            211103:"兴隆台区",
            211111:"郊区",
            211121:"大洼县",
            211122:"盘山县",
            211201:"市辖区",
            211202:"银州区",
            211203:"铁法区",
            211204:"清河区",
            211221:"铁岭县",
            211222:"开原县",
            211223:"西丰县",
            211224:"昌图县",
            211225:"康平县",
            211226:"法库县",
            211281:"调兵山市",
            211282:"开原市",
            211301:"市辖区",
            211302:"双塔区",
            211303:"龙城区",
            211321:"朝阳县",
            211322:"建平县",
            211323:"凌源县",
            211324:"喀喇沁左翼蒙古族自治县",
            211325:"建昌县",
            211326:"北票县",
            211381:"北票市",
            211382:"凌源市",
            211401:"市辖区",
            211402:"连山区",
            211403:"龙港区",
            211404:"南票区",
            211421:"绥中县",
            211422:"建昌县",
            211481:"兴城市",
            212101:"铁岭市",
            212102:"铁法市",
            212121:"铁岭县",
            212122:"开原县",
            212123:"西丰县",
            212124:"昌图县",
            212125:"康平县",
            212126:"法库县",
            212225:"建昌县",
            212226:"北票县",
            219001:"瓦房店市",
            219002:"海城市",
            219003:"锦西市",
            219004:"兴城市",
            219005:"铁法市",
            219006:"北票市",
            219007:"开原市",
            220101:"市辖区",
            220102:"南关区",
            220103:"宽城区",
            220104:"朝阳区",
            220105:"二道区",
            220106:"绿园区",
            220111:"郊区",
            220112:"双阳区",
            220120:"市区",
            220121:"榆树县",
            220122:"农安县",
            220123:"九台县",
            220124:"德惠县",
            220125:"双阳县",
            220181:"九台市",
            220182:"榆树市",
            220183:"德惠市",
            220201:"市辖区",
            220202:"昌邑区",
            220203:"龙潭区",
            220204:"船营区",
            220211:"丰满区",
            220220:"市区",
            220221:"永吉县",
            220222:"舒兰县",
            220223:"磐石县",
            220224:"蛟河县",
            220225:"桦甸县",
            220281:"蛟河市",
            220282:"桦甸市",
            220283:"舒兰市",
            220284:"磐石市",
            220301:"市辖区",
            220302:"铁西区",
            220303:"铁东区",
            220319:"公主岭市",
            220321:"怀德县",
            220322:"梨树县",
            220323:"伊通满族自治县",
            220324:"双辽县",
            220381:"公主岭市",
            220382:"双辽市",
            220401:"市辖区",
            220402:"龙山区",
            220403:"西安区",
            220421:"东丰县",
            220422:"东辽县",
            220501:"市辖区",
            220502:"东昌区",
            220503:"二道江区",
            220519:"梅河口市",
            220521:"通化县",
            220522:"集安县",
            220523:"辉南县",
            220524:"柳河县",
            220581:"梅河口市",
            220582:"集安市",
            220601:"市辖区",
            220602:"八道江区",
            220603:"三岔子区",
            220604:"临江区",
            220621:"抚松县",
            220622:"靖宇县",
            220623:"长白朝鲜族自治县",
            220625:"江源县",
            220681:"临江市",
            220701:"市辖区",
            220702:"宁江区",
            220721:"前郭尔罗斯蒙古族自治县",
            220722:"长岭县",
            220723:"乾安县",
            220724:"扶余县",
            220801:"市辖区",
            220802:"洮北区",
            220821:"镇赉县",
            220822:"通榆县",
            220881:"洮南市",
            220882:"大安市",
            222301:"白城市",
            222302:"洮南市",
            222303:"扶余市",
            222304:"大安市",
            222321:"扶余县",
            222322:"洮安县",
            222323:"长岭县",
            222324:"前郭尔罗斯蒙古族自治县",
            222325:"大安县",
            222326:"镇赉县",
            222327:"通榆县",
            222328:"乾安县",
            222401:"延吉市",
            222402:"图们市",
            222403:"敦化市",
            222404:"珲春市",
            222405:"龙井市",
            222406:"和龙市",
            222421:"龙井县",
            222422:"敦化县",
            222423:"和龙县",
            222424:"汪清县",
            222425:"珲春县",
            222426:"安图县",
            222101:"四平市",
            222102:"辽源市",
            222121:"怀德县",
            222122:"梨树县",
            222123:"伊通满族自治县",
            222124:"东丰县",
            222125:"双辽县",
            222201:"通化市",
            222202:"浑江市",
            222221:"海龙县",
            222222:"通化县",
            222223:"柳河县",
            222224:"辉南县",
            222225:"集安县",
            222226:"抚松县",
            222227:"靖宇县",
            222228:"长白朝鲜族自治县",
            229001:"公主岭市",
            229002:"梅河口市",
            229003:"集安市",
            229004:"桦甸市",
            229005:"九台市",
            230101:"市辖区",
            230102:"道里区",
            230103:"南岗区",
            230104:"道外区",
            230105:"太平区",
            230106:"香坊区",
            230107:"动力区",
            230108:"平房区",
            230109:"松北区",
            230110:"香坊区",
            230111:"呼兰区",
            230112:"阿城区",
            230119:"阿城市",
            230121:"呼兰县",
            230122:"阿城县",
            230123:"依兰县",
            230124:"方正县",
            230125:"宾县",
            230126:"巴彦县",
            230127:"木兰县",
            230128:"通河县",
            230129:"延寿县",
            230181:"阿城市",
            230182:"双城市",
            230183:"尚志市",
            230184:"五常市",
            230201:"市辖区",
            230202:"龙沙区",
            230203:"建华区",
            230204:"铁锋区",
            230205:"昂昂溪区",
            230206:"富拉尔基区",
            230207:"碾子山区",
            230208:"梅里斯达斡尔族区",
            230221:"龙江县",
            230222:"讷河县",
            230223:"依安县",
            230224:"泰来县",
            230225:"甘南县",
            230226:"杜尔伯特蒙古族自治县",
            230227:"富裕县",
            230228:"林甸县",
            230229:"克山县",
            230230:"克东县",
            230231:"拜泉县",
            230281:"讷河市",
            230301:"市辖区",
            230302:"鸡冠区",
            230303:"恒山区",
            230304:"滴道区",
            230305:"梨树区",
            230306:"城子河区",
            230307:"麻山区",
            230321:"鸡东县",
            230381:"虎林市",
            230382:"密山市",
            230401:"市辖区",
            230402:"向阳区",
            230403:"工农区",
            230404:"南山区",
            230405:"兴安区",
            230406:"东山区",
            230407:"兴山区",
            230421:"萝北县",
            230422:"绥滨县",
            230501:"市辖区",
            230502:"尖山区",
            230503:"岭东区",
            230504:"岭西区",
            230505:"四方台区",
            230506:"宝山区",
            230521:"集贤县",
            230522:"友谊县",
            230523:"宝清县",
            230524:"饶河县",
            230601:"市辖区",
            230602:"萨尔图区",
            230603:"龙凤区",
            230604:"让胡路区",
            230605:"红岗区",
            230606:"大同区",
            230621:"肇州县",
            230622:"肇源县",
            230623:"林甸县",
            230624:"杜尔伯特蒙古族自治县",
            230701:"市辖区",
            230702:"伊春区",
            230703:"南岔区",
            230704:"友好区",
            230705:"西林区",
            230706:"翠峦区",
            230707:"新青区",
            230708:"美溪区",
            230709:"金山屯区",
            230710:"五营区",
            230711:"乌马河区",
            230712:"汤旺河区",
            230713:"带岭区",
            230714:"乌伊岭区",
            230715:"红星区",
            230716:"上甘岭区",
            230721:"铁力县",
            230722:"嘉荫县",
            230781:"铁力市",
            230801:"市辖区",
            230802:"永红区",
            230803:"向阳区",
            230804:"前进区",
            230805:"东风区",
            230811:"郊区",
            230821:"富锦县",
            230822:"桦南县",
            230823:"依兰县",
            230825:"集贤县",
            230826:"桦川县",
            230827:"宝清县",
            230828:"汤原县",
            230829:"绥滨县",
            230830:"萝北县",
            230831:"同江县",
            230832:"饶河县",
            230833:"抚远县",
            230834:"友谊县",
            230881:"同江市",
            230882:"富锦市",
            230901:"市辖区",
            230902:"新兴区",
            230903:"桃山区",
            230904:"茄子河区",
            230921:"勃利县",
            231001:"市辖区",
            231002:"东安区",
            231003:"阳明区",
            231004:"爱民区",
            231005:"西安区",
            231011:"郊区",
            231020:"绥芬河市",
            231021:"宁安县",
            231022:"海林县",
            231023:"穆棱县",
            231024:"东宁县",
            231025:"林口县",
            231026:"密山县",
            231027:"虎林县",
            231081:"绥芬河市",
            231082:"密山市",
            231083:"海林市",
            231084:"宁安市",
            231085:"穆棱市",
            231101:"市辖区",
            231102:"爱辉区",
            231121:"嫩江县",
            231123:"逊克县",
            231124:"孙吴县",
            231181:"北安市",
            231182:"五大连池市",
            231201:"市辖区",
            231202:"北林区",
            231221:"望奎县",
            231222:"兰西县",
            231223:"青冈县",
            231224:"庆安县",
            231225:"明水县",
            231226:"绥棱县",
            231281:"安达市",
            231282:"肇东市",
            231283:"海伦市",
            232101:"双城市",
            232102:"尚志市",
            232103:"五常市",
            232121:"阿城市",
            232122:"宾县",
            232123:"呼兰县",
            232124:"双城市",
            232125:"五常市",
            232126:"巴彦县",
            232127:"木兰县",
            232128:"通河县",
            232129:"尚志市",
            232130:"方正县",
            232131:"延寿县",
            232301:"绥化市",
            232302:"安达市",
            232303:"肇东市",
            232304:"海伦市",
            232321:"海伦县",
            232322:"肇东县",
            232323:"绥化县",
            232324:"望奎县",
            232325:"兰西县",
            232326:"青冈县",
            232327:"安达县",
            232328:"肇源县",
            232329:"肇州县",
            232330:"庆安县",
            232331:"明水县",
            232332:"绥棱县",
            232401:"佳木斯市",
            232421:"富锦县",
            232422:"桦南县",
            232423:"依兰县",
            232424:"勃利县",
            232425:"集贤县",
            232426:"桦川县",
            232427:"宝清县",
            232428:"汤原县",
            232429:"绥滨县",
            232430:"萝北县",
            232431:"同江县",
            232432:"饶河县",
            232433:"抚远县",
            232501:"牡丹江市",
            232502:"绥芬河市",
            232521:"宁安县",
            232522:"海林县",
            232523:"穆棱县",
            232524:"东宁县",
            232525:"林口县",
            232526:"鸡东县",
            232527:"密山县",
            232528:"虎林县",
            232601:"黑河市",
            232602:"北安市",
            232603:"五大连池市",
            232621:"北安县",
            232622:"嫩江县",
            232623:"德都县",
            232625:"逊克县",
            232626:"孙吴县",
            232701:"加格达奇区",
            232721:"呼玛县",
            232722:"塔河县",
            232723:"漠河县",
            232724:"漠河县",
            239001:"绥芬河市",
            239002:"阿城市",
            239003:"同江市",
            239004:"富锦市",
            239005:"铁力市",
            239006:"密山市",
            310101:"黄浦区",
            310102:"南市区",
            310103:"卢湾区",
            310104:"徐汇区",
            310105:"长宁区",
            310106:"静安区",
            310107:"普陀区",
            310108:"闸北区",
            310109:"虹口区",
            310110:"杨浦区",
            310111:"吴凇区",
            310112:"闵行区",
            310113:"宝山区",
            310114:"嘉定区",
            310115:"浦东新区",
            310116:"金山区",
            310117:"松江区",
            310118:"青浦区",
            310119:"南汇区",
            310120:"奉贤区",
            310221:"上海县",
            310222:"嘉定县",
            310223:"宝山县",
            310224:"川沙县",
            310225:"南汇县",
            310226:"奉贤县",
            310227:"松江县",
            310228:"金山县",
            310229:"青浦县",
            310230:"崇明县",
            320101:"市辖区",
            320102:"玄武区",
            320103:"白下区",
            320104:"秦淮区",
            320105:"建邺区",
            320106:"鼓楼区",
            320107:"下关区",
            320111:"浦口区",
            320112:"大厂区",
            320113:"栖霞区",
            320114:"雨花台区",
            320115:"江宁区",
            320116:"六合区",
            320120:"市区",
            320121:"江宁县",
            320122:"江浦县",
            320123:"六合县",
            320124:"溧水县",
            320125:"高淳县",
            320126:"江都县",
            320201:"市辖区",
            320202:"崇安区",
            320203:"南长区",
            320204:"北塘区",
            320205:"锡山区",
            320206:"惠山区",
            320211:"郊区",
            320212:"马山区",
            320219:"江阴县",
            320221:"江阴县",
            320222:"无锡县",
            320223:"宜兴县",
            320281:"江阴市",
            320282:"宜兴市",
            320283:"锡山市",
            320301:"市辖区",
            320302:"鼓楼区",
            320303:"云龙区",
            320304:"九里区",
            320305:"贾汪区",
            320311:"泉山区",
            320321:"丰县",
            320322:"沛县",
            320323:"铜山县",
            320324:"睢宁县",
            320325:"邳县",
            320326:"新沂县",
            320381:"新沂市",
            320382:"邳州市",
            320401:"市辖区",
            320402:"天宁区",
            320404:"钟楼区",
            320405:"戚墅堰区",
            320411:"郊区",
            320412:"武进区",
            320419:"武进市",
            320421:"武进县",
            320422:"金坛县",
            320423:"溧阳县",
            320481:"溧阳市",
            320482:"金坛市",
            320483:"武进市",
            320501:"市辖区",
            320502:"沧浪区",
            320503:"平江区",
            320504:"金阊区",
            320505:"虎丘区",
            320506:"吴中区",
            320507:"相城区",
            320511:"郊区",
            320520:"常熟市",
            320521:"沙洲县",
            320522:"太仓县",
            320523:"昆山县",
            320524:"吴县",
            320525:"吴江县",
            320581:"常熟市",
            320582:"张家港市",
            320583:"昆山市",
            320584:"吴江市",
            320585:"太仓市",
            320586:"吴县市",
            320601:"市辖区",
            320602:"崇川区",
            320611:"港闸区",
            320621:"海安县",
            320622:"如皋县",
            320623:"如东县",
            320624:"南通县",
            320625:"海门县",
            320626:"启东县",
            320681:"启东市",
            320682:"如皋市",
            320683:"通州市",
            320684:"海门市",
            320701:"市辖区",
            320703:"连云区",
            320704:"云台区",
            320705:"新浦区",
            320706:"海州区",
            320721:"赣榆县",
            320722:"东海县",
            320723:"灌云县",
            320724:"灌南县",
            320801:"市辖区",
            320802:"清河区",
            320803:"楚州区",
            320804:"淮阴区",
            320811:"清浦区",
            320819:"宿迁市",
            320821:"淮阴县",
            320822:"灌南县",
            320823:"沭阳县",
            320824:"宿迁县",
            320825:"泗阳县",
            320826:"涟水县",
            320827:"泗洪县",
            320828:"淮安县",
            320829:"洪泽县",
            320830:"盱眙县",
            320831:"金湖县",
            320881:"宿迁市",
            320882:"淮安市",
            320901:"市辖区",
            320902:"城区",
            320903:"盐都区",
            320911:"郊区",
            320919:"东台市",
            320921:"响水县",
            320922:"滨海县",
            320923:"阜宁县",
            320924:"射阳县",
            320925:"建湖县",
            320926:"大丰县",
            320927:"东台县",
            320928:"盐都县",
            320981:"东台市",
            320982:"大丰市",
            321001:"市辖区",
            321002:"广陵区",
            321003:"邗江区",
            321011:"郊区",
            321019:"仪征市",
            321020:"泰州市",
            321021:"兴化县",
            321022:"高邮县",
            321023:"宝应县",
            321024:"靖江县",
            321025:"泰兴县",
            321026:"江都县",
            321027:"邗江县",
            321028:"泰县",
            321029:"仪征县",
            321081:"仪征市",
            321082:"泰州市",
            321083:"兴化市",
            321084:"高邮市",
            321085:"泰兴市",
            321086:"靖江市",
            321087:"江都市",
            321088:"江都市",
            321101:"市辖区",
            321102:"京口区",
            321111:"润州区",
            321112:"丹徒区",
            321119:"丹阳市",
            321121:"丹徒县",
            321122:"丹阳县",
            321123:"句容县",
            321124:"扬中县",
            321181:"丹阳市",
            321182:"扬中市",
            321183:"句容市",
            321201:"市辖区",
            321202:"海陵区",
            321203:"高港区",
            321281:"兴化市",
            321282:"靖江市",
            321283:"泰兴市",
            321284:"姜堰市",
            321301:"市辖区",
            321302:"宿城区",
            321311:"宿豫区",
            321321:"宿豫县",
            321322:"沭阳县",
            321323:"泗阳县",
            321324:"泗洪县",
            330101:"市辖区",
            330102:"上城区",
            330103:"下城区",
            330104:"江干区",
            330105:"拱墅区",
            330106:"西湖区",
            330107:"滨江区",
            330108:"滨江区",
            330109:"萧山区",
            330110:"余杭区",
            330120:"市区",
            330121:"萧山县",
            330122:"桐庐县",
            330123:"富阳县",
            330124:"临安县",
            330125:"余杭县",
            330126:"建德县",
            330127:"淳安县",
            330181:"萧山市",
            330182:"建德市",
            330183:"富阳市",
            330184:"余杭市",
            330185:"临安市",
            331002:"椒江区",
            331003:"黄岩区",
            331004:"路桥区",
            331021:"玉环县",
            331022:"三门县",
            331023:"天台县",
            331024:"仙居县",
            331081:"温岭市",
            331082:"临海市",
            331001:"市辖区",
            331101:"市辖区",
            331102:"莲都区",
            331121:"青田县",
            331122:"缙云县",
            331123:"遂昌县",
            331124:"松阳县",
            331125:"云和县",
            331126:"庆元县",
            331127:"景宁畲族自治县",
            331181:"龙泉市",
            332501:"丽水市",
            332502:"龙泉市",
            332521:"丽水县",
            332522:"青田县",
            332523:"云和县",
            332524:"龙泉县",
            332525:"庆元县",
            332526:"缙云县",
            332527:"遂昌县",
            332528:"松阳县",
            332529:"景宁畲族自治县",
            332601:"椒江市",
            332602:"临海市",
            332603:"黄岩市",
            332621:"临海县",
            332622:"黄岩县",
            332623:"温岭县",
            332624:"仙居县",
            332625:"天台县",
            332626:"三门县",
            332627:"玉环县",
            332721:"定海县",
            332722:"普陀县",
            332723:"岱山县",
            332724:"嵊泗县",
            330201:"市辖区",
            330202:"镇明区",
            330203:"海曙区",
            330204:"江东区",
            330205:"江北区",
            330206:"北仑区",
            330207:"鄞州区",
            330211:"镇海区",
            330212:"鄞州区",
            330219:"余姚市",
            330221:"镇海县",
            330222:"慈溪县",
            330223:"余姚县",
            330224:"奉化县",
            330225:"象山县",
            330226:"宁海县",
            330227:"鄞县",
            330281:"余姚市",
            330282:"慈溪市",
            330283:"奉化市",
            330301:"市辖区",
            330302:"鹿城区",
            330303:"龙湾区",
            330304:"瓯海区",
            330321:"瓯海县",
            330322:"洞头县",
            330323:"乐清县",
            330324:"永嘉县",
            330325:"瑞安县",
            330326:"平阳县",
            330327:"苍南县",
            330328:"文成县",
            330329:"泰顺县",
            330381:"瑞安市",
            330382:"乐清市",
            330401:"市辖区",
            330402:"秀城区",
            330411:"郊区",
            330419:"海宁市",
            330421:"嘉善县",
            330422:"平湖县",
            330423:"海宁县",
            330424:"海盐县",
            330425:"桐乡县",
            330481:"海宁市",
            330482:"平湖市",
            330483:"桐乡市",
            330501:"市辖区",
            330502:"吴兴区",
            330503:"南浔区",
            330511:"郊区",
            330521:"德清县",
            330522:"长兴县",
            330523:"安吉县",
            330601:"市辖区",
            330602:"越城区",
            330621:"绍兴县",
            330622:"上虞县",
            330623:"嵊县",
            330624:"新昌县",
            330625:"诸暨县",
            330681:"诸暨市",
            330682:"上虞市",
            330683:"嵊州市",
            330701:"市辖区",
            330702:"婺城区",
            330703:"金东区",
            330719:"兰溪市",
            330721:"金华县",
            330722:"永康县",
            330723:"武义县",
            330724:"东阳县",
            330725:"义乌县",
            330726:"浦江县",
            330727:"磐安县",
            330781:"兰溪市",
            330782:"义乌市",
            330783:"东阳市",
            330784:"永康市",
            330801:"市辖区",
            330802:"柯城区",
            330803:"衢江区",
            330821:"衢县",
            330822:"常山县",
            330823:"江山县",
            330824:"开化县",
            330825:"龙游县",
            330881:"江山市",
            330901:"市辖区",
            330902:"定海区",
            330903:"普陀区",
            330921:"岱山县",
            330922:"嵊泗县",
            339001:"余姚市",
            339002:"海宁市",
            339003:"兰溪市",
            339004:"瑞安市",
            339005:"萧山市",
            339006:"江山市",
            339007:"义乌市",
            339008:"东阳市",
            339009:"慈溪市",
            339010:"奉化市",
            339011:"诸暨市",
            341221:"临泉县",
            341222:"太和县",
            341223:"涡阳县",
            341224:"蒙城县",
            341225:"阜南县",
            341226:"颍上县",
            341227:"利辛县",
            341281:"亳州市",
            341282:"界首市",
            341201:"市辖区",
            341202:"颍州区",
            341203:"颍东区",
            341204:"颍泉区",
            341301:"市辖区",
            341302:"墉桥区",
            341321:"砀山县",
            341322:"萧县",
            341323:"灵璧县",
            341324:"泗县",
            341401:"市辖区",
            341402:"居巢区",
            341421:"庐江县",
            341422:"无为县",
            341423:"含山县",
            341424:"和县",
            341501:"市辖区",
            341502:"金安区",
            341503:"裕安区",
            341521:"寿县",
            341522:"霍邱县",
            341523:"舒城县",
            341524:"金寨县",
            341525:"霍山县",
            341601:"市辖区",
            341602:"谯城区",
            341621:"涡阳县",
            341622:"蒙城县",
            341623:"利辛县",
            341701:"市辖区",
            341702:"贵池区",
            341721:"东至县",
            341722:"石台县",
            341723:"青阳县",
            341801:"市辖区",
            341802:"宣州区",
            341821:"郎溪县",
            341822:"广德县",
            341823:"泾县",
            341824:"绩溪县",
            341825:"旌德县",
            341881:"宁国市",
            342101:"阜阳市",
            342121:"阜阳县",
            342122:"临泉县",
            342123:"太和县",
            342124:"涡阳县",
            342125:"蒙城县",
            342126:"亳县",
            342127:"阜南县",
            342128:"颍上县",
            342129:"界首县",
            342130:"利辛县",
            342201:"宿州市",
            342221:"砀山县",
            342222:"萧县",
            342223:"宿县",
            342224:"灵璧县",
            342225:"泗县",
            342226:"怀远县",
            342227:"五河县",
            342228:"固镇县",
            342301:"滁州市",
            342321:"天长县",
            342322:"来安县",
            342323:"滁县",
            342324:"全椒县",
            342325:"定远县",
            342326:"凤阳县",
            342327:"嘉山县",
            342401:"六安市",
            342421:"六安县",
            342422:"寿县",
            342423:"霍邱县",
            342424:"肥西县",
            342425:"舒城县",
            342426:"金寨县",
            342427:"霍山县",
            342501:"宣州市",
            342502:"宁国市",
            342521:"宣州县",
            342522:"郎溪县",
            342523:"广德县",
            342524:"宁国县",
            342525:"当涂县",
            342526:"繁昌县",
            342527:"南陵县",
            342528:"青阳县",
            342529:"泾县",
            342530:"旌德县",
            342531:"绩溪县",
            342601:"巢湖市",
            342621:"肥东县",
            342622:"庐江县",
            342623:"无为县",
            342624:"巢县",
            342625:"含山县",
            342626:"和县",
            342701:"屯溪市",
            342721:"绩溪县",
            342722:"旌德县",
            342723:"歙县",
            342724:"休宁县",
            342725:"黟县",
            342726:"祁门县",
            342727:"太平县",
            342728:"石台县",
            342821:"怀宁县",
            342822:"桐城县",
            342823:"枞阳县",
            342824:"潜山县",
            342825:"太湖县",
            342826:"宿松县",
            342827:"望江县",
            342828:"岳西县",
            342829:"东至县",
            342830:"贵池县",
            342901:"贵池市",
            342921:"东至县",
            342922:"石台县",
            342923:"青阳县",
            340101:"市辖区",
            340102:"瑶海区",
            340103:"庐阳区",
            340104:"蜀山区",
            340111:"包河区",
            340121:"长丰县",
            340122:"肥西县",
            340123:"肥东县",
            340201:"市辖区",
            340202:"镜湖区",
            340203:"弋江区",
            340204:"新芜区",
            340205:"裕溪口区",
            340206:"四褐山区",
            340207:"鸠江区",
            340208:"三山区",
            340211:"郊区",
            340220:"市区",
            340221:"芜湖县",
            340222:"繁昌县",
            340223:"南陵县",
            340224:"青阳县",
            340301:"市辖区",
            340302:"龙子湖区",
            340303:"蚌山区",
            340304:"禹会区",
            340311:"淮上区",
            340321:"怀远县",
            340322:"五河县",
            340323:"固镇县",
            340401:"市辖区",
            340402:"大通区",
            340403:"田家庵区",
            340404:"谢家集区",
            340405:"八公山区",
            340406:"潘集区",
            340421:"凤台县",
            340501:"市辖区",
            340502:"金家庄区",
            340503:"花山区",
            340504:"雨山区",
            340505:"向山区",
            340521:"当涂县",
            340601:"市辖区",
            340602:"杜集区",
            340603:"相山区",
            340604:"烈山区",
            340621:"濉溪县",
            340701:"市辖区",
            340702:"铜官山区",
            340703:"狮子山区",
            340711:"郊区",
            340721:"铜陵县",
            340801:"市辖区",
            340802:"迎江区",
            340803:"大观区",
            340811:"郊区",
            340821:"桐城县",
            340822:"怀宁县",
            340823:"枞阳县",
            340824:"潜山县",
            340825:"太湖县",
            340826:"宿松县",
            340827:"望江县",
            340828:"岳西县",
            340881:"桐城市",
            341001:"市辖区",
            341002:"屯溪区",
            341003:"黄山区",
            341004:"徽州区",
            341021:"歙县",
            341022:"休宁县",
            341023:"黟县",
            341024:"祁门县",
            341101:"市辖区",
            341102:"琅琊区",
            341103:"南谯区",
            341121:"天长县",
            341122:"来安县",
            341124:"全椒县",
            341125:"定远县",
            341126:"凤阳县",
            341127:"嘉山县",
            341181:"天长市",
            341182:"明光市",
            350901:"市辖区",
            350902:"蕉城区",
            350921:"霞浦县",
            350922:"古田县",
            350923:"屏南县",
            350924:"寿宁县",
            350925:"周宁县",
            350926:"柘荣县",
            350981:"福安市",
            350982:"福鼎市",
            352101:"南平市",
            352102:"邵武市",
            352103:"武夷山市",
            352104:"建瓯市",
            352121:"顺昌县",
            352122:"建阳县",
            352123:"建瓯县",
            352124:"浦城县",
            352125:"邵武县",
            352126:"崇安县",
            352127:"光泽县",
            352128:"松溪县",
            352129:"政和县",
            352201:"宁德市",
            352202:"福安市",
            352203:"福鼎市",
            352221:"宁德县",
            352222:"连江县",
            352223:"罗源县",
            352224:"福鼎县",
            352225:"霞浦县",
            352226:"福安县",
            352227:"古田县",
            352228:"屏南县",
            352229:"寿宁县",
            352230:"周宁县",
            352231:"柘荣县",
            352601:"龙岩市",
            352602:"漳平市",
            352622:"长汀县",
            352623:"永定县",
            352624:"上杭县",
            352625:"武平县",
            352626:"漳平县",
            352627:"连城县",
            352701:"三明市",
            352721:"明溪县",
            352722:"永安县",
            352723:"清流县",
            352724:"宁化县",
            352725:"大田县",
            352726:"尤溪县",
            352727:"沙县",
            352728:"将乐县",
            352729:"泰宁县",
            352730:"建宁县",
            350101:"市辖区",
            350102:"鼓楼区",
            350103:"台江区",
            350104:"仓山区",
            350105:"马尾区",
            350111:"晋安区",
            350120:"市区",
            350121:"闽侯县",
            350122:"连江县",
            350123:"罗源县",
            350124:"闽清县",
            350125:"永泰县",
            350126:"长乐县",
            350127:"福清县",
            350128:"平潭县",
            350181:"福清市",
            350182:"长乐市",
            350201:"市辖区",
            350202:"鼓浪屿区",
            350203:"思明区",
            350204:"开元区",
            350205:"海沧区",
            350206:"湖里区",
            350211:"集美区",
            350212:"同安区",
            350213:"翔安区",
            350301:"市辖区",
            350302:"城厢区",
            350303:"涵江区",
            350304:"荔城区",
            350305:"秀屿区",
            350321:"莆田县",
            350322:"仙游县",
            350401:"市辖区",
            350402:"梅列区",
            350403:"三元区",
            350404:"永安市",
            350420:"永安市",
            350421:"明溪县",
            350422:"永安县",
            350423:"清流县",
            350424:"宁化县",
            350425:"大田县",
            350426:"尤溪县",
            350427:"沙县",
            350428:"将乐县",
            350429:"泰宁县",
            350430:"建宁县",
            350481:"永安市",
            350501:"市辖区",
            350502:"鲤城区",
            350503:"丰泽区",
            350504:"洛江区",
            350505:"泉港区",
            350521:"惠安县",
            350522:"晋江县",
            350523:"南安县",
            350524:"安溪县",
            350525:"永春县",
            350526:"德化县",
            350527:"金门县",
            350581:"石狮市",
            350582:"晋江市",
            350583:"南安市",
            350601:"市辖区",
            350602:"芗城区",
            350603:"龙文区",
            350621:"龙海县",
            350622:"云霄县",
            350623:"漳浦县",
            350624:"诏安县",
            350625:"长泰县",
            350626:"东山县",
            350627:"南靖县",
            350628:"平和县",
            350629:"华安县",
            350681:"龙海市",
            350701:"市辖区",
            350702:"延平区",
            350721:"顺昌县",
            350722:"浦城县",
            350723:"光泽县",
            350724:"松溪县",
            350725:"政和县",
            350781:"邵武市",
            350782:"武夷山市",
            350783:"建瓯市",
            350784:"建阳市",
            350801:"市辖区",
            350802:"新罗区",
            350821:"长汀县",
            350822:"永定县",
            350823:"上杭县",
            350824:"武平县",
            350825:"连城县",
            350881:"漳平市",
            359001:"永安市",
            359002:"石狮市",
            360101:"市辖区",
            360102:"东湖区",
            360103:"西湖区",
            360104:"青云谱区",
            360105:"湾里区",
            360111:"郊区",
            360121:"南昌县",
            360122:"新建县",
            360123:"安义县",
            360124:"进贤县",
            360201:"市辖区",
            360202:"昌江区",
            360203:"珠山区",
            360211:"鹅湖区",
            360212:"蛟潭区",
            360221:"乐平县",
            360222:"浮梁县",
            360281:"乐平市",
            361128:"鄱阳县",
            361129:"万年县",
            361130:"婺源县",
            361181:"德兴市",
            361101:"市辖区",
            361102:"信州区",
            361121:"上饶县",
            361122:"广丰县",
            361123:"玉山县",
            361124:"铅山县",
            361125:"横峰县",
            361126:"弋阳县",
            361127:"余干县",
            362101:"赣州市",
            362102:"瑞金市",
            362103:"南康市",
            362121:"赣县",
            362122:"南康市",
            362123:"信丰县",
            362124:"大余县",
            362125:"上犹县",
            362126:"崇义县",
            362127:"安远县",
            362128:"龙南县",
            362129:"定南县",
            362130:"全南县",
            362131:"宁都县",
            362132:"于都县",
            362133:"兴国县",
            362134:"瑞金市",
            362135:"会昌县",
            362136:"寻乌县",
            362137:"石城县",
            362138:"广昌县",
            362201:"宜春市",
            362202:"丰城市",
            362203:"樟树市",
            362204:"高安市",
            362221:"丰城县",
            362222:"高安县",
            362223:"清江县",
            362224:"新余县",
            362225:"宜春县",
            362226:"奉新县",
            362227:"万载县",
            362228:"上高县",
            362229:"宜丰县",
            362230:"分宜县",
            362231:"安义县",
            362232:"靖安县",
            362233:"铜鼓县",
            362301:"上饶市",
            362302:"德兴市",
            362321:"上饶县",
            362322:"广丰县",
            362323:"玉山县",
            362324:"铅山县",
            362325:"横峰县",
            362326:"弋阳县",
            362327:"贵溪县",
            362328:"余江县",
            362329:"余干县",
            362330:"波阳县",
            362331:"万年县",
            362332:"乐平县",
            362333:"德兴县",
            362334:"婺源县",
            360301:"市辖区",
            360302:"安源区",
            360311:"上栗区",
            360312:"芦溪区",
            360313:"湘东区",
            360321:"莲花县",
            360322:"上栗县",
            360323:"芦溪县",
            360401:"市辖区",
            360402:"庐山区",
            360403:"浔阳区",
            360421:"九江县",
            360422:"瑞昌县",
            360423:"武宁县",
            360424:"修水县",
            360425:"永修县",
            360426:"德安县",
            360427:"星子县",
            360428:"都昌县",
            360429:"湖口县",
            360430:"彭泽县",
            360481:"瑞昌市",
            360501:"市辖区",
            360502:"渝水区",
            360521:"分宜县",
            360601:"市辖区",
            360602:"月湖区",
            360621:"贵溪县",
            360622:"余江县",
            360681:"贵溪市",
            360701:"市辖区",
            360702:"章贡区",
            360721:"赣县",
            360722:"信丰县",
            360723:"大余县",
            360724:"上犹县",
            360725:"崇义县",
            360726:"安远县",
            360727:"龙南县",
            360728:"定南县",
            360729:"全南县",
            360730:"宁都县",
            360731:"于都县",
            360732:"兴国县",
            360733:"会昌县",
            360734:"寻乌县",
            360735:"石城县",
            360781:"瑞金市",
            360782:"南康市",
            360801:"市辖区",
            360802:"吉州区",
            360803:"青原区",
            360821:"吉安县",
            360822:"吉水县",
            360823:"峡江县",
            360824:"新干县",
            360825:"永丰县",
            360826:"泰和县",
            360827:"遂川县",
            360828:"万安县",
            360829:"安福县",
            360830:"永新县",
            360881:"井冈山市",
            360901:"市辖区",
            360902:"袁州区",
            360921:"奉新县",
            360922:"万载县",
            360923:"上高县",
            360924:"宜丰县",
            360925:"靖安县",
            360926:"铜鼓县",
            360981:"丰城市",
            360982:"樟树市",
            360983:"高安市",
            361001:"市辖区",
            361002:"临川区",
            361021:"南城县",
            361022:"黎川县",
            361023:"南丰县",
            361024:"崇仁县",
            361025:"乐安县",
            361026:"宜黄县",
            361027:"金溪县",
            361028:"资溪县",
            361029:"东乡县",
            361030:"广昌县",
            362401:"吉安市",
            362402:"井冈山市",
            362421:"吉安县",
            362422:"吉水县",
            362423:"峡江县",
            362424:"新干县",
            362425:"永丰县",
            362426:"泰和县",
            362427:"遂川县",
            362428:"万安县",
            362429:"安福县",
            362430:"永新县",
            362431:"莲花县",
            362432:"宁冈县",
            362433:"井岗山县",
            362501:"临川市",
            362502:"临川市",
            362521:"临川县",
            362522:"南城县",
            362523:"黎川县",
            362524:"南丰县",
            362525:"崇仁县",
            362526:"乐安县",
            362527:"宜黄县",
            362528:"金溪县",
            362529:"资溪县",
            362530:"进贤县",
            362531:"东乡县",
            362532:"广昌县",
            362621:"九江县",
            362622:"瑞昌县",
            362623:"武宁县",
            362624:"修水县",
            362625:"永修县",
            362626:"德安县",
            362627:"星子县",
            362628:"都昌县",
            362629:"湖口县",
            362630:"彭泽县",
            370212:"崂山区",
            370213:"李沧区",
            370214:"城阳区",
            370220:"市区",
            370221:"崂山县",
            370222:"即墨县",
            370223:"胶南县",
            370224:"胶县",
            370225:"莱西县",
            370226:"平度县",
            370281:"胶州市",
            370282:"即墨市",
            370283:"平度市",
            370284:"胶南市",
            370285:"莱西市",
            370201:"市辖区",
            370202:"市南区",
            370203:"市北区",
            370204:"台东区",
            370205:"四方区",
            370206:"沧口区",
            370211:"黄岛区",
            370301:"市辖区",
            370302:"淄川区",
            370303:"张店区",
            370304:"博山区",
            370305:"临淄区",
            370306:"周村区",
            370321:"桓台县",
            370322:"高青县",
            370323:"沂源县",
            370401:"市辖区",
            370402:"市中区",
            370403:"薛城区",
            370404:"峄城区",
            370405:"台儿庄区",
            370406:"山亭区",
            370420:"市区",
            370421:"滕县",
            370481:"滕州市",
            370501:"市辖区",
            370502:"东营区",
            370503:"河口区",
            370521:"垦利县",
            370522:"利津县",
            370523:"广饶县",
            370601:"市辖区",
            370602:"芝罘区",
            370611:"福山区",
            370612:"牟平区",
            370613:"莱山区",
            370619:"龙口市",
            370620:"威海市",
            370622:"蓬莱县",
            370623:"黄县",
            370624:"招远县",
            370625:"掖县",
            370627:"莱阳县",
            370628:"栖霞县",
            370629:"海阳县",
            370631:"牟平县",
            370632:"文登县",
            370633:"荣城县",
            370634:"长岛县",
            370681:"龙口市",
            370682:"莱阳市",
            370683:"莱州市",
            370684:"蓬莱市",
            370685:"招远市",
            370686:"栖霞市",
            370687:"海阳市",
            370701:"市辖区",
            370702:"潍城区",
            370703:"寒亭区",
            370704:"坊子区",
            370705:"奎文区",
            370719:"青州市",
            370721:"益都县",
            370722:"安丘县",
            370723:"寿光县",
            370724:"临朐县",
            370725:"昌乐县",
            370726:"昌邑县",
            370727:"高密县",
            370728:"诸城县",
            370729:"五莲县",
            370781:"青州市",
            370782:"诸城市",
            370783:"寿光市",
            370784:"安丘市",
            370785:"高密市",
            370786:"昌邑市",
            370801:"市辖区",
            370802:"市中区",
            370811:"任城区",
            370819:"曲阜市",
            370822:"兖州县",
            370823:"曲阜县",
            370825:"邹县",
            370826:"微山县",
            370827:"鱼台县",
            370828:"金乡县",
            370829:"嘉祥县",
            370830:"汶上县",
            370831:"泗水县",
            370832:"梁山县",
            370881:"曲阜市",
            370882:"兖州市",
            370883:"邹城市",
            370901:"市辖区",
            370902:"泰山区",
            370903:"岱岳区",
            370911:"郊区",
            370919:"莱芜市",
            370920:"新泰市",
            370921:"宁阳县",
            370922:"肥城县",
            370923:"东平县",
            370981:"莱芜市",
            370982:"新泰市",
            370983:"肥城市",
            371001:"市辖区",
            371002:"环翠区",
            371021:"乳山县",
            371081:"文登市",
            371082:"荣成市",
            371083:"乳山市",
            371101:"市辖区",
            371102:"东港区",
            371103:"岚山区",
            371121:"五莲县",
            371122:"莒县",
            371201:"市辖区",
            371202:"莱城区",
            371203:"钢城区",
            371301:"市辖区",
            371302:"兰山区",
            371311:"罗庄区",
            371312:"河东区",
            371321:"沂南县",
            371322:"郯城县",
            371323:"沂水县",
            371324:"苍山县",
            371325:"费县",
            371326:"平邑县",
            371327:"莒南县",
            371328:"蒙阴县",
            371329:"临沭县",
            371401:"市辖区",
            371402:"德城区",
            371421:"陵县",
            371422:"宁津县",
            371423:"庆云县",
            371424:"临邑县",
            371425:"齐河县",
            371426:"平原县",
            371427:"夏津县",
            371428:"武城县",
            371481:"乐陵市",
            371482:"禹城市",
            371501:"市辖区",
            371502:"东昌府区",
            371521:"阳谷县",
            371522:"莘县",
            371523:"茌平县",
            371524:"东阿县",
            371525:"冠县",
            371526:"高唐县",
            371581:"临清市",
            371601:"市辖区",
            371602:"滨城区",
            371621:"惠民县",
            371622:"阳信县",
            371623:"无棣县",
            371624:"沾化县",
            371625:"博兴县",
            371626:"邹平县",
            370101:"市辖区",
            370102:"历下区",
            370103:"市中区",
            370104:"槐荫区",
            370105:"天桥区",
            370111:"郊区",
            370112:"历城区",
            370113:"长清区",
            370120:"市区",
            370121:"历城县",
            370122:"章丘县",
            370123:"长清县",
            370124:"平阴县",
            370125:"济阳县",
            370126:"商河县",
            370181:"章丘市",
            371701:"市辖区",
            371702:"牡丹区",
            371721:"曹县",
            371722:"单县",
            371723:"成武县",
            371724:"巨野县",
            371725:"郓城县",
            371726:"鄄城县",
            371727:"定陶县",
            371728:"东明县",
            372101:"烟台市",
            372102:"威海市",
            372121:"福山县",
            372122:"蓬莱县",
            372124:"招远县",
            372125:"掖县",
            372126:"莱西县",
            372127:"莱阳县",
            372128:"栖霞县",
            372129:"海阳县",
            372130:"乳山县",
            372131:"牟平县",
            372132:"文登县",
            372133:"荣城县",
            372134:"长岛县",
            372201:"潍坊市",
            372221:"益都县",
            372222:"安丘县",
            372223:"寿光县",
            372224:"临朐县",
            372225:"昌乐县",
            372226:"昌邑县",
            372227:"高密县",
            372228:"诸城县",
            372230:"平度县",
            372231:"潍县",
            372301:"滨州市",
            372321:"惠民县",
            372322:"滨县",
            372323:"阳信县",
            372324:"无棣县",
            372325:"沾化县",
            372326:"利津县",
            372327:"广饶县",
            372328:"博兴县",
            372329:"桓台县",
            372330:"邹平县",
            372331:"高青县",
            372332:"垦利县",
            372401:"德州市",
            372402:"乐陵市",
            372403:"禹城市",
            372421:"陵县",
            372422:"平原县",
            372423:"夏津县",
            372424:"武城县",
            372425:"齐河县",
            372426:"禹城县",
            372427:"乐陵县",
            372428:"临邑县",
            372429:"商河县",
            372430:"济阳县",
            372431:"宁津县",
            372432:"庆云县",
            372501:"聊城市",
            372502:"东昌府区",
            372521:"阳谷县",
            372522:"莘县",
            372523:"茌平县",
            372524:"东阿县",
            372525:"冠县",
            372526:"高唐县",
            372527:"高唐县",
            372528:"临清县",
            372581:"临清市",
            372601:"泰安市",
            372602:"莱芜市",
            372603:"新泰市",
            372622:"莱芜县",
            372623:"新泰县",
            372624:"宁阳县",
            372625:"肥城县",
            372626:"东平县",
            372627:"平阴县",
            372628:"新汶县",
            372630:"泗水县",
            372701:"济宁市",
            372722:"兖州县",
            372723:"曲阜县",
            372724:"泗水县",
            372725:"邹县",
            372726:"微山县",
            372727:"鱼台县",
            372728:"金乡县",
            372729:"嘉祥县",
            372801:"临沂市",
            372802:"日照市",
            372821:"临沂县",
            372822:"郯城县",
            372823:"苍山县",
            372824:"莒南县",
            372825:"日照县",
            372826:"莒县",
            372827:"沂水县",
            372828:"沂源县",
            372829:"蒙阴县",
            372830:"平邑县",
            372831:"费县",
            372832:"沂南县",
            372833:"临沭县",
            372901:"菏泽市",
            372921:"菏泽县",
            372922:"曹县",
            372923:"定陶县",
            372924:"成武县",
            372925:"单县",
            372926:"巨野县",
            372927:"粱山县",
            372928:"郓城县",
            372929:"鄄城县",
            372930:"东明县",
            379001:"青州市",
            379002:"龙口市",
            379003:"曲阜市",
            379004:"莱芜市",
            379005:"新泰市",
            379006:"胶州市",
            379007:"诸城市",
            379008:"莱阳市",
            379009:"莱州市",
            379010:"滕州市",
            379011:"文登市",
            379012:"荣城市",
            410602:"鹤山区",
            410603:"山城区",
            410611:"淇滨区",
            410621:"浚县",
            410622:"淇县",
            410601:"市辖区",
            410701:"市辖区",
            410702:"红旗区",
            410703:"新华区",
            410704:"北站区",
            410711:"郊区",
            410721:"新乡县",
            410722:"汲县",
            410723:"辉县",
            410724:"获嘉县",
            410725:"原阳县",
            410726:"延津县",
            410727:"封丘县",
            410728:"长垣县",
            410781:"卫辉市",
            410782:"辉县市",
            410801:"市辖区",
            410802:"解放区",
            410803:"中站区",
            410804:"马村区",
            410811:"山阳区",
            410821:"修武县",
            410822:"博爱县",
            410823:"武陟县",
            410824:"沁阳县",
            410825:"温县",
            410826:"孟县",
            410827:"济源县",
            410881:"济源市",
            410882:"沁阳市",
            410883:"孟州市",
            410901:"市辖区",
            410902:"市区",
            410921:"滑县",
            410922:"清丰县",
            410923:"南乐县",
            410924:"内黄县",
            410925:"长垣县",
            410926:"范县",
            410927:"台前县",
            410928:"濮阳县",
            411001:"市辖区",
            411002:"魏都区",
            411021:"禹县",
            411022:"长葛县",
            411023:"许昌县",
            411024:"鄢陵县",
            411025:"襄城县",
            411081:"禹州市",
            411082:"长葛市",
            411101:"市辖区",
            411102:"源汇区",
            411103:"郾城区",
            411104:"召陵区",
            411121:"舞阳县",
            411122:"临颍县",
            411123:"郾城县",
            411201:"市辖区",
            411202:"湖滨区",
            411221:"渑池县",
            411222:"陕县",
            411223:"灵宝县",
            411224:"卢氏县",
            411281:"义马市",
            411282:"灵宝市",
            411301:"市辖区",
            411302:"宛城区",
            411303:"卧龙区",
            411321:"南召县",
            411322:"方城县",
            411323:"西峡县",
            411324:"镇平县",
            411325:"内乡县",
            411326:"淅川县",
            411327:"社旗县",
            411328:"唐河县",
            411329:"新野县",
            411330:"桐柏县",
            411381:"邓州市",
            410101:"市辖区",
            410102:"中原区",
            410103:"二七区",
            410104:"管城回族区",
            410105:"金水区",
            410106:"上街区",
            410107:"新密区",
            410108:"邙山区",
            410111:"金海区",
            410112:"郊区",
            410120:"市区",
            410121:"荥阳县",
            410122:"中牟县",
            410123:"新郑县",
            410124:"巩县",
            410125:"登封县",
            410126:"密县",
            410181:"巩义市",
            410182:"荥阳市",
            410183:"新密市",
            410184:"新郑市",
            410185:"登封市",
            410201:"市辖区",
            410202:"龙亭区",
            410203:"顺河回族区",
            410204:"鼓楼区",
            410205:"禹王台区",
            410211:"金明区",
            410221:"杞县",
            410222:"通许县",
            410223:"尉氏县",
            410224:"开封县",
            410225:"兰考县",
            410301:"市辖区",
            410302:"老城区",
            410303:"西工区",
            410304:"廛河回族区",
            410305:"涧西区",
            410306:"吉利区",
            410307:"洛龙区",
            410311:"郊区",
            410321:"偃师县",
            410322:"孟津县",
            410323:"新安县",
            410324:"栾川县",
            410325:"嵩县",
            410326:"汝阳县",
            410327:"宜阳县",
            410328:"洛宁县",
            410329:"伊川县",
            410381:"偃师市",
            410401:"市辖区",
            410402:"新华区",
            410403:"卫东区",
            410404:"石龙区",
            410411:"湛河区",
            410412:"舞钢区",
            410421:"宝丰县",
            410422:"叶县",
            410423:"鲁山县",
            410424:"临汝县",
            410425:"郏县",
            410426:"襄城县",
            410481:"舞钢市",
            410482:"汝州市",
            410501:"市辖区",
            410502:"文峰区",
            410503:"北关区",
            410504:"铁西区",
            410505:"殷都区",
            410506:"龙安区",
            410511:"郊区",
            410521:"林县",
            410522:"安阳县",
            410523:"汤阴县",
            410524:"淇县",
            410525:"浚县",
            410526:"滑县",
            410527:"内黄县",
            410581:"林州市",
            411401:"市辖区",
            411402:"梁园区",
            411403:"睢阳区",
            411421:"民权县",
            411422:"睢县",
            411423:"宁陵县",
            411424:"柘城县",
            411425:"虞城县",
            411426:"夏邑县",
            411481:"永城市",
            411501:"市辖区",
            411502:"师河区",
            411503:"平桥区",
            411521:"罗山县",
            411522:"光山县",
            411523:"新县",
            411524:"商城县",
            411525:"固始县",
            411526:"潢川县",
            411527:"淮滨县",
            411528:"息县",
            411601:"市辖区",
            411602:"川汇区",
            411621:"扶沟县",
            411622:"西华县",
            411623:"商水县",
            411624:"沈丘县",
            411625:"郸城县",
            411626:"淮阳县",
            411627:"太康县",
            411628:"鹿邑县",
            411681:"项城市",
            411701:"市辖区",
            411702:"驿城区",
            411721:"西平县",
            411722:"上蔡县",
            411723:"平舆县",
            411724:"正阳县",
            411725:"确山县",
            411726:"泌阳县",
            411727:"汝南县",
            411728:"遂平县",
            411729:"新蔡县",
            412301:"商丘市",
            412302:"永城市",
            412321:"虞城县",
            412322:"商丘县",
            412323:"民权县",
            412324:"宁陵县",
            412325:"睢县",
            412326:"夏邑县",
            412327:"柘城县",
            412328:"永城县",
            412421:"杞县",
            412422:"通许县",
            412423:"尉氏县",
            412424:"开封县",
            412425:"中牟县",
            412426:"新郑县",
            412427:"巩县",
            412428:"登封县",
            412429:"密县",
            412430:"兰考县",
            412501:"三门峡市",
            412502:"义马市",
            412521:"偃师县",
            412522:"孟津县",
            412523:"新安县",
            412524:"渑池县",
            412525:"陕县",
            412526:"灵宝县",
            412527:"伊川县",
            412528:"汝阳县",
            412529:"嵩县",
            412530:"洛宁县",
            412531:"卢氏县",
            412532:"栾川县",
            412533:"临汝县",
            412534:"宜阳县",
            412701:"周口市",
            412702:"项城市",
            412721:"扶沟县",
            412722:"西华县",
            412723:"商水县",
            412724:"太康县",
            412725:"鹿邑县",
            412726:"郸城县",
            412727:"淮阳县",
            412728:"沈丘县",
            412801:"驻马店市",
            412821:"确山县",
            412822:"泌阳县",
            412823:"遂平县",
            412824:"西平县",
            412825:"上蔡县",
            412826:"汝南县",
            412827:"平舆县",
            412828:"新蔡县",
            412829:"正阳县",
            412901:"卧龙区",
            412902:"邓州市",
            412903:"宛城区",
            412921:"南召县",
            412922:"方城县",
            412923:"西峡县",
            412924:"南阳县",
            412925:"镇平县",
            412926:"内乡县",
            412927:"淅川县",
            412928:"社旗县",
            412929:"唐河县",
            412930:"邓县",
            412931:"新野县",
            412932:"桐柏县",
            412933:"规划控制区",
            413001:"信阳市",
            413021:"息县",
            413022:"淮滨县",
            413023:"平桥区",
            413024:"潢川县",
            413025:"光山县",
            413026:"固始县",
            413027:"商城县",
            413028:"罗山县",
            413029:"新县",
            412601:"许昌市",
            412602:"漯河市",
            412621:"长葛县",
            412622:"禹县",
            412623:"鄢陵县",
            412624:"许昌县",
            412625:"郏县",
            412626:"临颍县",
            412627:"襄城县",
            412628:"宝丰县",
            412629:"郾城县",
            412630:"叶县",
            412631:"鲁山县",
            412632:"舞阳县",
            419001:"义马市",
            419002:"汝州市",
            419003:"济源市",
            419004:"禹州市",
            419005:"卫辉市",
            419006:"辉县市",
            420501:"市辖区",
            420502:"西陵区",
            420503:"伍家岗区",
            420504:"点军区",
            420505:"猇亭区",
            420506:"夷陵区",
            420521:"宜昌县",
            420523:"枝江县",
            420525:"远安县",
            420526:"兴山县",
            420527:"秭归县",
            420528:"长阳土家族自治县",
            420529:"五峰土家族自治县",
            420581:"宜都市",
            420582:"当阳市",
            420583:"枝江市",
            420601:"市辖区",
            420602:"襄城区",
            420603:"樊东区",
            420604:"樊西区",
            420605:"郊区",
            420606:"樊城区",
            420607:"襄阳区",
            420619:"随州市",
            420620:"老河口市",
            420621:"襄阳县",
            420622:"枣阳县",
            420623:"宜城县",
            420624:"南漳县",
            420625:"谷城县",
            420626:"保康县",
            420681:"随州市",
            420682:"老河口市",
            420683:"枣阳市",
            420684:"宜城市",
            420701:"市辖区",
            420702:"梁子湖区",
            420703:"华容区",
            420704:"鄂城区",
            420801:"市辖区",
            420802:"东宝区",
            420803:"沙洋区",
            420804:"掇刀区",
            420821:"京山县",
            420822:"沙洋县",
            420881:"钟祥市",
            420901:"市辖区",
            420902:"孝南区",
            420921:"孝昌县",
            420922:"大悟县",
            420923:"云梦县",
            420981:"应城市",
            420982:"安陆市",
            420983:"广水市",
            420984:"汉川市",
            421001:"市辖区",
            421002:"沙市区",
            421003:"荆州区",
            421004:"江陵区",
            421021:"松滋县",
            421022:"公安县",
            421023:"监利县",
            421024:"江陵县",
            421081:"石首市",
            421083:"洪湖市",
            421087:"松滋市",
            421101:"市辖区",
            421102:"黄州区",
            421103:"龙感湖管理区",
            421121:"团风县",
            421122:"红安县",
            421123:"罗田县",
            421124:"英山县",
            421125:"浠水县",
            421126:"蕲春县",
            421127:"黄梅县",
            421181:"麻城市",
            421182:"武穴市",
            421201:"市辖区",
            421202:"咸安区",
            421221:"嘉鱼县",
            421222:"通城县",
            421223:"崇阳县",
            421224:"通山县",
            421281:"赤壁市",
            421301:"市辖区",
            421302:"曾都区",
            421381:"广水市",
            422101:"鄂州市",
            422102:"武穴市",
            422103:"黄州市",
            422121:"黄冈县",
            422122:"新洲县",
            422123:"红安县",
            422124:"麻城县",
            422125:"罗田县",
            422126:"英山县",
            422127:"浠水县",
            422128:"蕲春县",
            422129:"广济县",
            422130:"黄梅县",
            422131:"鄂城县",
            422201:"孝感市",
            422202:"应城市",
            422203:"安陆市",
            422204:"广水市",
            422221:"孝感县",
            422222:"黄陂县",
            422223:"大悟县",
            422224:"应山县",
            422225:"安陆县",
            422226:"云梦县",
            422227:"应城县",
            422228:"汉川县",
            422921:"神农架林区",
            429001:"随州市",
            429003:"枣阳市",
            429004:"仙桃市",
            429005:"潜江市",
            429006:"天门市",
            429021:"神农架林区",
            420101:"市辖区",
            420102:"江岸区",
            420103:"江汉区",
            420104:"乔口区",
            420105:"汉阳区",
            420106:"武昌区",
            420107:"青山区",
            420109:"东湖管理分局",
            420111:"洪山区",
            420112:"东西湖区",
            420113:"汉南区",
            420114:"蔡甸区",
            420115:"江夏区",
            420116:"黄陂区",
            420117:"新洲区",
            420121:"蔡甸区",
            420122:"江夏区",
            420123:"黄陂区",
            420124:"新洲县",
            420201:"市辖区",
            420202:"黄石港区",
            420203:"石灰窑区",
            420204:"下陆区",
            420205:"铁山区",
            420220:"市区",
            420221:"大冶县",
            420222:"阳新县",
            420281:"大冶市",
            420301:"市辖区",
            420302:"茅箭区",
            420303:"张湾区",
            420321:"郧县",
            420322:"郧西县",
            420323:"竹山县",
            420324:"竹溪县",
            420325:"房县",
            420381:"丹江口市",
            422301:"咸宁市",
            422302:"赤壁市",
            422321:"咸宁县",
            422322:"嘉鱼县",
            422323:"蒲圻县",
            422324:"通城县",
            422325:"崇阳县",
            422326:"通山县",
            422327:"阳新县",
            422401:"仙桃市",
            422402:"石首市",
            422403:"洪湖市",
            422404:"天门市",
            422405:"潜江市",
            422406:"钟祥市",
            422421:"江陵县",
            422422:"松滋县",
            422423:"公安县",
            422424:"石首县",
            422425:"监利县",
            422426:"洪湖县",
            422427:"沔阳县",
            422428:"天门县",
            422429:"潜江县",
            422430:"荆门县",
            422431:"钟祥县",
            422432:"京山县",
            422501:"随州市",
            422502:"老河口市",
            422521:"襄阳县",
            422522:"枣阳县",
            422524:"宜城县",
            422525:"南漳县",
            422527:"谷城县",
            422528:"保康县",
            422601:"丹江口市",
            422622:"郧县",
            422623:"郧西县",
            422624:"竹山县",
            422625:"竹溪县",
            422626:"房县",
            422627:"神农架林区",
            422701:"枝城市",
            422702:"当阳市",
            422721:"宜昌县",
            422722:"宜都县",
            422723:"枝江县",
            422724:"当阳县",
            422725:"远安县",
            422726:"兴山县",
            422727:"秭归县",
            422728:"长阳土家族自治县",
            422729:"五峰土家族自治县",
            422801:"恩施市",
            422802:"利川市",
            422822:"建始县",
            422823:"巴东县",
            422825:"宣恩县",
            422826:"咸丰县",
            422827:"来凤县",
            422828:"鹤峰县",
            423022:"沙洋监狱管理局",
            430101:"市辖区",
            430102:"芙蓉区",
            430103:"天心区",
            430104:"岳麓区",
            430105:"开福区",
            430111:"雨花区",
            430120:"市区",
            430121:"长沙县",
            430122:"望城县",
            430123:"浏阳县",
            430124:"宁乡县",
            430181:"浏阳市",
            430201:"市辖区",
            430202:"荷塘区",
            430203:"芦淞区",
            430204:"石峰区",
            430211:"天元区",
            430219:"醴陵市",
            430220:"市区",
            430221:"株洲县",
            430222:"醴陵县",
            430223:"攸县",
            430224:"茶陵县",
            430225:"炎陵县",
            430281:"醴陵市",
            430301:"市辖区",
            430302:"雨湖区",
            430303:"湘江区",
            430304:"岳塘区",
            430305:"板塘区",
            430306:"韶山区",
            430311:"郊区",
            430312:"韶山区",
            430321:"湘潭县",
            430322:"湘乡县",
            430381:"湘乡市",
            430382:"韶山市",
            430401:"市辖区",
            430402:"江东区",
            430403:"城南区",
            430404:"城北区",
            430405:"珠晖区",
            430406:"雁峰区",
            430407:"石鼓区",
            430408:"蒸湘区",
            430411:"郊区",
            430412:"南岳区",
            430419:"耒阳市",
            430421:"衡阳县",
            430422:"衡南县",
            430423:"衡山县",
            430424:"衡东县",
            430425:"常宁县",
            430426:"祁东县",
            430427:"耒阳县",
            430481:"耒阳市",
            430482:"常宁市",
            432121:"湘潭县",
            432122:"湘乡县",
            432123:"醴陵县",
            432124:"浏阳县",
            432125:"攸县",
            432126:"茶陵县",
            432127:"酃县",
            432201:"岳阳市",
            432222:"平江县",
            432223:"湘阴县",
            432224:"汨罗县",
            432225:"临湘市",
            432226:"华容县",
            432301:"益阳市",
            432302:"沅江市",
            432321:"益阳县",
            432322:"南县",
            432323:"沅江县",
            432324:"宁乡县",
            432325:"桃江县",
            432326:"安化县",
            432401:"常德市",
            432402:"津市市",
            432421:"常德县",
            432422:"安乡县",
            432423:"汉寿县",
            432424:"澧县",
            432425:"临澧县",
            432426:"桃源县",
            432427:"石门县",
            432428:"慈利县",
            432501:"娄底市",
            432502:"冷水江市",
            432503:"涟源市",
            432521:"涟源县",
            432522:"双峰县",
            432523:"邵东县",
            432524:"新化县",
            432525:"新邵县",
            432621:"邵阳县",
            432622:"隆回县",
            432623:"武冈县",
            432624:"洞口县",
            432625:"新宁县",
            432626:"绥宁县",
            432627:"城步苗族自治县",
            432721:"衡阳县",
            432722:"衡南县",
            432723:"衡山县",
            432724:"衡东县",
            432725:"常宁县",
            432726:"祁东县",
            432727:"祁阳县",
            432801:"郴州市",
            432802:"资兴市",
            432821:"郴县",
            432822:"桂阳县",
            432823:"永兴县",
            432824:"宜章县",
            432825:"资兴县",
            432826:"嘉禾县",
            432827:"临武县",
            432828:"汝城县",
            432829:"桂东县",
            432830:"耒阳县",
            432831:"安仁县",
            432901:"永州市",
            432902:"冷水滩市",
            432921:"零陵县",
            432922:"东安县",
            432923:"道县",
            432924:"宁远县",
            432925:"江永县",
            432926:"江华瑶族自治县",
            432927:"蓝山县",
            432928:"新田县",
            432929:"双牌县",
            432930:"双牌县",
            430501:"市辖区",
            430502:"双清区",
            430503:"大祥区",
            430511:"北塔区",
            430521:"邵东县",
            430522:"新邵县",
            430523:"邵阳县",
            430524:"隆回县",
            430525:"洞口县",
            430526:"武冈县",
            430527:"绥宁县",
            430528:"新宁县",
            430529:"城步苗族自治县",
            430581:"武冈市",
            430601:"市辖区",
            430602:"岳阳楼区",
            430603:"云溪区",
            430611:"君山区",
            430621:"岳阳县",
            430622:"临湘县",
            430623:"华容县",
            430624:"湘阴县",
            430625:"汨罗县",
            430626:"平江县",
            430681:"汨罗市",
            430682:"临湘市",
            430701:"市辖区",
            430702:"武陵区",
            430703:"鼎城区",
            430721:"安乡县",
            430722:"汉寿县",
            430723:"澧县",
            430724:"临澧县",
            430725:"桃源县",
            430726:"石门县",
            430781:"津市市",
            430801:"市辖区",
            430802:"永定区",
            430811:"武陵源区",
            430821:"慈利县",
            430822:"桑植县",
            430901:"市辖区",
            430902:"资阳区",
            430903:"赫山区",
            430911:"市区",
            430921:"南县",
            430922:"桃江县",
            430923:"安化县",
            430981:"沅江市",
            431001:"市辖区",
            431002:"北湖区",
            431003:"苏仙区",
            431021:"桂阳县",
            431022:"宜章县",
            431023:"永兴县",
            431024:"嘉禾县",
            431025:"临武县",
            431026:"汝城县",
            431027:"桂东县",
            431028:"安仁县",
            431081:"资兴市",
            431101:"市辖区",
            431102:"零陵区",
            431103:"冷水滩区",
            431121:"祁阳县",
            431122:"东安县",
            431123:"双牌县",
            431124:"道县",
            431125:"江永县",
            431126:"宁远县",
            431127:"蓝山县",
            431128:"新田县",
            431129:"江华瑶族自治县",
            431201:"市辖区",
            431202:"鹤城区",
            431221:"中方县",
            431222:"沅陵县",
            431223:"辰溪县",
            431224:"溆浦县",
            431225:"会同县",
            431226:"麻阳苗族自治县",
            431227:"新晃侗族自治县",
            431228:"芷江侗族自治县",
            431229:"靖州苗族侗族自治县",
            431230:"通道侗族自治县",
            431281:"洪江市",
            431301:"市辖区",
            431302:"娄星区",
            431321:"双峰县",
            431322:"新化县",
            431381:"冷水江市",
            431382:"涟源市",
            433001:"怀化市",
            433002:"洪江市",
            433021:"黔阳县",
            433022:"沅陵县",
            433023:"辰溪县",
            433024:"溆浦县",
            433025:"麻阳县",
            433026:"新晃侗族自治县",
            433027:"芷江县",
            433028:"怀化县",
            433029:"会同县",
            433030:"靖县",
            433031:"通道侗族自治县",
            433101:"吉首市",
            433121:"吉首县",
            433122:"泸溪县",
            433123:"凤凰县",
            433124:"花垣县",
            433125:"保靖县",
            433126:"古丈县",
            433127:"永顺县",
            433128:"大庸县",
            433129:"桑植县",
            433130:"龙山县",
            439001:"醴陵市",
            439002:"湘乡市",
            439003:"耒阳市",
            439004:"汨罗市",
            439005:"津市市",
            440101:"市辖区",
            440102:"东山区",
            440103:"荔湾区",
            440104:"越秀区",
            440105:"海珠区",
            440106:"天河区",
            440107:"芳村区",
            440111:"白云区",
            440112:"黄埔区",
            440113:"番禺区",
            440114:"花都区",
            440115:"南沙区",
            440116:"萝岗区",
            440120:"市区",
            440121:"花县",
            440122:"从化县",
            440123:"新丰县",
            440124:"龙门县",
            440125:"增城县",
            440126:"番禺县",
            440127:"清远县",
            440128:"佛冈县",
            440181:"番禺市",
            440182:"花都市",
            440183:"增城市",
            440184:"从化市",
            440201:"市辖区",
            440202:"北江区",
            440203:"武江区",
            440204:"浈江区",
            440205:"曲江区",
            440221:"曲江县",
            440222:"始兴县",
            440223:"南雄县",
            440224:"仁化县",
            440225:"乐昌县",
            440226:"连县",
            440227:"阳山县",
            440228:"英德县",
            440229:"翁源县",
            440230:"连山壮族瑶族自治县",
            440231:"连南瑶族自治县",
            440232:"乳源瑶族自治县",
            440233:"新丰县",
            440281:"乐昌市",
            440282:"南雄市",
            440301:"市辖区",
            440302:"盐田区",
            440303:"罗湖区",
            440304:"福田区",
            440305:"南山区",
            440306:"宝安区",
            440307:"龙岗区",
            440308:"盐田区",
            440320:"市区",
            440321:"宝安县",
            440401:"市辖区",
            440402:"香洲区",
            440403:"斗门区",
            440404:"金湾区",
            440421:"斗门县",
            440501:"市辖区",
            440502:"同平区",
            440503:"安平区",
            440504:"公园区",
            440505:"金砂区",
            440506:"达濠区",
            440507:"龙湖区",
            440508:"金园区",
            440509:"升平区",
            440510:"河浦区",
            440511:"金平区",
            440512:"濠江区",
            440513:"潮阳区",
            440514:"潮南区",
            440515:"澄海区",
            440520:"潮州市",
            440521:"澄海县",
            440522:"饶平县",
            440523:"南澳县",
            440524:"潮阳县",
            440525:"揭阳县",
            440526:"揭西县",
            440527:"普宁县",
            440528:"惠来县",
            440581:"潮州市",
            440582:"潮阳市",
            440583:"澄海市",
            440601:"市辖区",
            440602:"城区",
            440603:"石湾区",
            440604:"禅城区",
            440605:"南海区",
            440606:"顺德区",
            440607:"三水区",
            440608:"高明区",
            440620:"中山市",
            440621:"三水县",
            440622:"南海县",
            440623:"顺德县",
            440624:"高明县",
            440681:"顺德市",
            440682:"南海市",
            440683:"三水市",
            440684:"高明市",
            440701:"市辖区",
            440702:"城区",
            440703:"蓬江区",
            440704:"江海区",
            440705:"新会区",
            440711:"郊区",
            440721:"新会县",
            440722:"台山县",
            440723:"恩平县",
            440724:"开平县",
            440725:"鹤山县",
            440726:"阳江县",
            440727:"阳春县",
            440781:"台山市",
            440782:"新会市",
            440783:"开平市",
            440784:"鹤山市",
            440785:"恩平市",
            441882:"连州市",
            441801:"市辖区",
            441802:"清城区",
            441811:"清郊区",
            441821:"佛冈县",
            441822:"英德县",
            441823:"阳山县",
            441824:"连县",
            441825:"连山壮族瑶族自治县",
            441826:"连南瑶族自治县",
            441827:"清新县",
            441881:"英德市",
            442701:"佛山市",
            442702:"江门市",
            442721:"三水县",
            442722:"南海县",
            442723:"顺德县",
            442724:"中山县",
            442725:"斗门县",
            442726:"新会县",
            442727:"台山县",
            442728:"恩平县",
            442729:"开平县",
            442731:"鹤山县",
            442732:"高明县",
            442901:"湛江市",
            442902:"茂名市",
            442921:"阳江县",
            442922:"阳春县",
            442923:"信宜县",
            442924:"高州县",
            442925:"电白县",
            442926:"吴川县",
            442927:"化州县",
            442928:"廉江县",
            442929:"遂溪县",
            442930:"海康县",
            442931:"徐闻县",
            445101:"市辖区",
            445102:"湘桥区",
            445121:"潮安县",
            445122:"饶平县",
            445201:"市辖区",
            445202:"榕城区",
            445221:"揭东县",
            445222:"揭西县",
            445224:"惠来县",
            445281:"普宁市",
            440801:"市辖区",
            440802:"赤坎区",
            440803:"霞山区",
            440804:"坡头区",
            440811:"麻章区",
            440821:"吴川县",
            440822:"廉江县",
            440823:"遂溪县",
            440824:"海康县",
            440825:"徐闻县",
            440881:"廉江市",
            440882:"雷州市",
            440883:"吴川市",
            440901:"市辖区",
            440902:"茂南区",
            440903:"茂港区",
            440921:"信宜县",
            440922:"高州县",
            440923:"电白县",
            440924:"化州县",
            440981:"高州市",
            440982:"化州市",
            440983:"信宜市",
            441201:"市辖区",
            441202:"端州区",
            441203:"鼎湖区",
            441221:"高要县",
            441222:"四会县",
            441223:"广宁县",
            441224:"怀集县",
            441225:"封开县",
            441226:"德庆县",
            441227:"云浮县",
            441228:"新兴县",
            441229:"郁南县",
            441230:"罗定县",
            441281:"云浮市",
            441282:"罗定市",
            441283:"高要市",
            441284:"四会市",
            441301:"市辖区",
            441302:"惠城区",
            441303:"惠阳区",
            441321:"惠阳县",
            441322:"博罗县",
            441323:"惠东县",
            441324:"龙门县",
            441381:"惠阳市",
            441401:"市辖区",
            441402:"梅江区",
            441421:"梅县",
            441422:"大埔县",
            441423:"丰顺县",
            441424:"五华县",
            441425:"兴宁县",
            441426:"平远县",
            441427:"蕉岭县",
            441481:"兴宁市",
            441501:"市辖区",
            441502:"城区",
            441521:"海丰县",
            441522:"陆丰县",
            441523:"陆河县",
            441581:"陆丰市",
            441601:"市辖区",
            441602:"源城区",
            441611:"郊区",
            441621:"紫金县",
            441622:"龙川县",
            441623:"连平县",
            441624:"和平县",
            441625:"东源县",
            441701:"市辖区",
            441702:"江城区",
            441703:"阳东区",
            441721:"阳西县",
            441722:"阳春县",
            441723:"阳东县",
            441781:"阳春市",
            445301:"市辖区",
            445302:"云城区",
            445321:"新兴县",
            445322:"郁南县",
            445323:"云安县",
            445381:"罗定市",
            442524:"连平县",
            442525:"河源县",
            442526:"博罗县",
            442527:"东莞县",
            442528:"惠东县",
            442529:"龙川县",
            442530:"陆丰县",
            442531:"海丰县",
            442621:"始兴县",
            442622:"南雄县",
            442623:"仁化县",
            442624:"乐昌县",
            442628:"清远县",
            442630:"翁源县",
            442631:"连山壮族瑶族自治县",
            442632:"连南瑶族自治县",
            442633:"乳源瑶族自治县",
            442821:"高要县",
            442822:"四会县",
            442823:"广宁县",
            442824:"怀集县",
            442825:"封开县",
            442826:"德庆县",
            442827:"云浮县",
            442828:"新兴县",
            442829:"郁南县",
            442830:"罗定县",
            442501:"惠州市",
            442502:"东莞市",
            442521:"惠阳县",
            442522:"紫金县",
            442523:"和平县",
            451001:"市辖区",
            451002:"右江区",
            451021:"田阳县",
            451022:"田东县",
            451023:"平果县",
            451024:"德保县",
            451025:"靖西县",
            451026:"那坡县",
            451027:"凌云县",
            451028:"乐业县",
            451029:"田林县",
            451030:"西林县",
            451031:"隆林各族自治县",
            451101:"市辖区",
            451102:"八步区",
            451121:"昭平县",
            451122:"钟山县",
            451123:"富川瑶族自治县",
            451201:"市辖区",
            451202:"金城江区",
            451221:"南丹县",
            451222:"天峨县",
            451223:"凤山县",
            451224:"东兰县",
            451225:"罗城仫佬族自治县",
            451226:"环江毛南族自治县",
            451227:"巴马瑶族自治县",
            451228:"都安瑶族自治县",
            451229:"大化瑶族自治县",
            451281:"宜州市",
            451301:"市辖区",
            451302:"兴宾区",
            451321:"忻城县",
            451322:"象州县",
            451323:"武宣县",
            451324:"金秀瑶族自治县",
            451381:"合山市",
            451401:"市辖区",
            451402:"江洲区",
            451421:"扶绥县",
            451422:"宁明县",
            451423:"龙州县",
            451424:"大新县",
            451425:"天等县",
            451481:"凭祥市",
            452101:"凭祥市",
            452122:"横县",
            452123:"宾阳县",
            452124:"上林县",
            452126:"隆安县",
            452127:"马山县",
            452128:"扶绥县",
            452129:"崇左县",
            452130:"大新县",
            452131:"天等县",
            452132:"宁明县",
            452133:"龙州县",
            452201:"合山市",
            452223:"鹿寨县",
            452224:"象州县",
            452225:"武宣县",
            452226:"来宾县",
            452227:"融安县",
            452228:"三江侗族自治县",
            452229:"融水苗族自治县",
            452230:"金秀瑶族自治县",
            452231:"忻城县",
            450101:"市辖区",
            450102:"兴宁区",
            450103:"青秀区",
            450104:"城北区",
            450105:"江南区",
            450106:"永新区",
            450107:"西乡塘区",
            450108:"良庆区",
            450109:"邕宁区",
            450111:"郊区",
            450121:"邕宁县",
            450122:"武鸣县",
            450123:"隆安县",
            450124:"马山县",
            450125:"上林县",
            450126:"宾阳县",
            450127:"横县",
            450201:"市辖区",
            450202:"城中区",
            450203:"鱼峰区",
            450204:"柳南区",
            450205:"柳北区",
            450211:"市郊区",
            450221:"柳江县",
            450222:"柳城县",
            450223:"鹿寨县",
            450224:"融安县",
            450225:"融水苗族自治县",
            450226:"三江侗族自治县",
            450301:"市辖区",
            450302:"秀峰区",
            450303:"叠彩区",
            450304:"象山区",
            450305:"七星区",
            450311:"雁山区",
            450321:"阳朔县",
            450322:"临桂县",
            450323:"灵川县",
            450324:"全州县",
            450325:"兴安县",
            450326:"永福县",
            450327:"灌阳县",
            450328:"龙胜各族自治县",
            450329:"资源县",
            450330:"平乐县",
            450331:"荔蒲县",
            450332:"恭城瑶族自治县",
            450401:"市辖区",
            450402:"白云区",
            450403:"万秀区",
            450404:"蝶山区",
            450405:"长洲区",
            450411:"市郊区",
            450421:"苍梧县",
            450422:"藤县",
            450423:"蒙山县",
            450481:"岑溪市",
            450501:"市辖区",
            450502:"海城区",
            450503:"银海区",
            450512:"铁山港区",
            450521:"合浦县",
            450601:"市辖区",
            450602:"港口区",
            450603:"防城区",
            450621:"上思县",
            450681:"东兴市",
            450701:"市辖区",
            450702:"钦南区",
            450703:"钦北区",
            450721:"灵山县",
            450722:"浦北县",
            450801:"市辖区",
            450802:"港北区",
            450803:"港南区",
            450804:"覃塘区",
            450821:"平南县",
            450881:"桂平市",
            450901:"市辖区",
            450902:"玉州区",
            450921:"容县",
            450922:"陆川县",
            450923:"博白县",
            450924:"兴业县",
            450981:"北流市",
            452321:"临桂县",
            452322:"灵川县",
            452323:"全州县",
            452324:"兴安县",
            452325:"永福县",
            452327:"灌阳县",
            452328:"龙胜各族自治县",
            452329:"资源县",
            452330:" 平乐县",
            452331:"荔蒲县",
            452332:"恭城瑶族自治县",
            452401:"岑溪市",
            452402:"贺州市",
            452421:"岑溪县",
            452422:"苍梧县",
            452423:"藤县",
            452424:"昭平县",
            452425:"蒙山县",
            452426:"贺县",
            452427:"钟山县",
            452428:"富川瑶族自治县",
            452501:"玉林市",
            452502:"贵港市",
            452503:"北流市",
            452504:"桂平市",
            452521:"玉林县",
            452522:"贵县",
            452523:"桂平县",
            452524:"平南县",
            452525:"容县",
            452526:"北流县",
            452527:"陆川县",
            452528:"博白县",
            452601:"百色市",
            452621:"百色县",
            452622:"田阳县",
            452623:"田东县",
            452624:"平果县",
            452625:"德保县",
            452626:"靖西县",
            452627:"那坡县",
            452628:"凌云县",
            452629:"乐业县",
            452630:"田林县",
            452631:"隆林各族自治县",
            452632:"西林县",
            452701:"河池市",
            452702:"宜州市",
            452721:"河池县",
            452722:"宜山县",
            452723:"罗城仫佬族自治县",
            452724:"环江毛南族自治县",
            452725:"南丹县",
            452726:"天峨县",
            452727:"凤山县",
            452728:"东兰县",
            452729:"巴马瑶族自治县",
            452730:"都安瑶族自治县",
            452731:"大化瑶族自治县",
            452801:"北海市",
            452802:"钦州市",
            452821:"上思县",
            452822:"防城各族自治县",
            452823:"钦州县",
            452824:"灵山县",
            452825:"合浦县",
            452826:"浦北县",
            460101:"市辖区",
            460102:"振东区",
            460103:"新华区",
            460104:"秀英区",
            460105:"秀英区",
            460106:"龙华区",
            460107:"琼山区",
            460108:"美兰区",
            460201:"市辖区",
            469034:"陵水黎族自治县",
            469035:"保亭黎族苗族自治县",
            469036:"琼中黎族苗族自治县",
            469037:"西沙群岛",
            469038:"南沙群岛",
            469039:"中沙群岛的岛礁及其海域",
            460001:"通什市",
            460002:"琼海市",
            460003:"儋州市",
            460004:"琼山市",
            460005:"文昌市",
            460006:"万宁市",
            460007:"东方市",
            460021:"琼山县",
            460022:"文昌县",
            460023:"琼海县",
            460024:"万宁县",
            460025:"定安县",
            460026:"屯昌县",
            460027:"澄迈县",
            460028:"临高县",
            460029:"儋县",
            460030:"白沙黎族自治县",
            460031:"昌江黎族自治县",
            460032:"东方黎族自治县",
            460033:"乐东黎族自治县",
            460034:"陵水黎族自治县",
            460035:"保亭黎族苗族自治县",
            460036:"琼中黎族苗族自治县",
            460037:"西沙群岛",
            460038:"南沙群岛",
            460039:"中沙群岛的岛礁及其海域",
            469001:"五指山市",
            469002:"琼海市",
            469003:"儋州市",
            469005:"文昌市",
            469006:"万宁市",
            469007:"东方市",
            469025:"定安县",
            469026:"屯昌县",
            469027:"澄迈县",
            469028:"临高县",
            469030:"白沙黎族自治县",
            469031:"昌江黎族自治县",
            469033:"乐东黎族自治县",
            500381:"江津市",
            500382:"合川市",
            500383:"永川市",
            500384:"南川市",
            500101:"万州区",
            500102:"涪陵区",
            500103:"渝中区",
            500104:"大渡口区",
            500105:"江北区",
            500106:"沙坪坝区",
            500107:"九龙坡区",
            500108:"南岸区",
            500109:"北碚区",
            500110:"万盛区",
            500111:"双桥区",
            500112:"渝北区",
            500113:"巴南区",
            500114:"黔江区",
            500115:"长寿区",
            500201:"渝中区",
            500211:"江北区",
            500212:"沙坪坝区",
            500213:"九龙坡区",
            500214:"南岸区",
            500215:"北碚区",
            500216:"万盛区",
            500217:"双桥区",
            500221:"长寿县",
            500222:"綦江县",
            500223:"潼南县",
            500224:"铜梁县",
            500225:"大足县",
            500226:"荣昌县",
            500227:"璧山县",
            500228:"梁平县",
            500229:"城口县",
            500230:"丰都县",
            500231:"垫江县",
            500232:"武隆县",
            500233:"忠县",
            500234:"开县",
            500235:"云阳县",
            500236:"奉节县",
            500237:"巫山县",
            500238:"巫溪县",
            500239:"黔江土家族苗族自治县",
            500240:"石柱土家族自治县",
            500241:"秀山土家族苗族自治县",
            500242:"酉阳土家族苗族自治县",
            500243:"彭水苗族土家族自治县",
            510301:"市辖区",
            510302:"自流井区",
            510303:"贡井区",
            510304:"大安区",
            510311:"沿滩区",
            510321:"荣县",
            510322:"富顺县",
            510401:"市辖区",
            510402:"东区",
            510403:"西区",
            510411:"仁和区",
            510421:"米易县",
            510422:"盐边县",
            510501:"市辖区",
            510502:"江阳区",
            510503:"纳溪区",
            510504:"龙马潭区",
            510521:"泸县",
            510522:"合江县",
            510523:"纳溪县",
            510524:"叙永县",
            510525:"古蔺县",
            510601:"市辖区",
            510602:"市中区",
            510603:"旌阳区",
            510622:"绵竹县",
            510623:"中江县",
            510624:"广汉县",
            510625:"什邡县",
            510626:"罗江县",
            510681:"广汉市",
            510682:"什邡市",
            510683:"绵竹市",
            510701:"市辖区",
            510702:"市中区",
            510703:"涪城区",
            510704:"游仙区",
            510721:"江油县",
            510722:"三台县",
            510723:"盐亭县",
            510724:"安县",
            510725:"梓潼县",
            510726:"北川县",
            510727:"平武县",
            510781:"江油市",
            510801:"市辖区",
            510802:"市中区",
            510811:"元坝区",
            510812:"朝天区",
            510821:"旺苍县",
            510822:"青川县",
            510823:"剑阁县",
            510824:"苍溪县",
            510901:"市辖区",
            510902:"市中区",
            510903:"船山区",
            510904:"安居区",
            510921:"蓬溪县",
            510922:"射洪县",
            510923:"大英县",
            511001:"市辖区",
            511002:"市中区",
            511011:"东兴区",
            511021:"内江县",
            511022:"乐至县",
            511023:"安岳县",
            511024:"威远县",
            511025:"资中县",
            511026:"资阳市",
            511027:"简阳县",
            511028:"隆昌县",
            511081:"资阳市",
            512226:"奉节县",
            512227:"巫山县",
            512228:"巫溪县",
            512229:"城口县",
            512201:"万县市",
            512221:"万县",
            512222:"开县",
            512223:"忠县",
            512224:"梁平县",
            512225:"云阳县",
            512301:"涪陵市",
            512302:"南川市",
            512321:"涪陵县",
            512322:"垫江县",
            512323:"南川县",
            512324:"丰都县",
            512325:"石柱县",
            512326:"武隆县",
            512327:"彭水县",
            512328:"黔江县",
            512329:"酉阳县",
            512330:"秀山县",
            512501:"宜宾市",
            512527:"宜宾县",
            512528:"南溪县",
            512529:"江安县",
            512530:"长宁县",
            512531:"高县",
            512532:"筠连县",
            512533:"珙县",
            512534:"兴文县",
            512535:"屏山县",
            512901:"南充市",
            512902:"华蓥市",
            512903:"阆中市",
            512921:"南充县",
            512922:"南部县",
            512923:"岳池县",
            512924:"营山县",
            512925:"广安县",
            512926:"蓬安县",
            512927:"仪陇县",
            512928:"武胜县",
            512929:"西充县",
            512930:"阆中县",
            512931:"苍溪县",
            512932:"华云工农区",
            513001:"达川市",
            513002:"万源市",
            513021:"达县",
            513022:"宣汉县",
            513023:"开江县",
            513024:"万源县",
            513025:"通江县",
            513026:"南江县",
            513027:"巴中县",
            513028:"平昌县",
            513029:"大竹县",
            513030:"渠县",
            513031:"邻水县",
            513032:"白沙工农区",
            513101:"雅安市",
            513122:"名山县",
            513123:"荥经县",
            513124:"汉源县",
            513125:"石棉县",
            513126:"天全县",
            513127:"芦山县",
            513128:"宝兴县",
            513221:" 汶川县",
            513222:"理县",
            513223:"茂县",
            513224:"松潘县",
            513225:"九寨沟县",
            513226:"金川县",
            513227:"小金县",
            513228:"黑水县",
            513229:"马尔康县",
            513230:"壤塘县",
            513231:"阿坝县",
            513232:"若尔盖县",
            513233:"红原县",
            513321:"康定县",
            513322:"泸定县",
            513323:"丹巴县",
            513324:"九龙县",
            513325:"雅江县",
            513326:"道孚县",
            513327:"炉霍县",
            513328:"甘孜县",
            513329:"新龙县",
            513330:"德格县",
            513331:"白玉县",
            513332:"石渠县",
            513333:"色达县",
            513334:"理塘县",
            513335:"巴塘县",
            513336:"乡城县",
            513337:"稻城县",
            513338:"得荣县",
            513401:"西昌市",
            513422:"木里藏族自治县",
            513423:"盐源县",
            513424:"德昌县",
            513425:"会理县",
            513426:"会东县",
            513427:"宁南县",
            513428:"普格县",
            513429:"布拖县",
            513430:"金阳县",
            513431:"昭觉县",
            513432:"喜德县",
            513433:"冕宁县",
            513434:"越西县",
            513435:"甘洛县",
            513436:"美姑县",
            513437:"雷波县",
            510101:"市辖区",
            510102:"东城区",
            510103:"西城区",
            510104:"锦江区",
            510105:"青羊区",
            510106:"金牛区",
            510107:"武侯区",
            510108:"成华区",
            510111:"金牛区",
            510112:"龙泉驿区",
            510113:"青白江区",
            510114:"新都区",
            510120:"市区",
            510121:"金堂县",
            510122:"双流县",
            510123:"温江县",
            510124:"郫县",
            510125:"新都县",
            510126:"彭县",
            510127:"灌县",
            510128:"崇庆县",
            510129:"大邑县",
            510130:"邛崃县",
            510131:"蒲江县",
            510132:"新津县",
            510181:"都江堰市",
            510182:"彭州市",
            510183:"邛崃市",
            510184:"崇州市",
            510201:"市辖区",
            510202:"市中区",
            510203:"大渡口区",
            510211:"江北区",
            510212:"沙坪坝区",
            510213:"九龙坡区",
            510214:"南岸区",
            510215:"北碚区",
            510216:"万盛区",
            510217:"双桥区",
            510218:"渝北区",
            510219:"巴南区",
            510220:"市区",
            510221:"长寿县",
            510222:"巴县",
            510223:"綦江县",
            510224:"江北县",
            510225:"江津县",
            510226:"合川县",
            510227:"潼南县",
            510228:"铜梁县",
            510229:"永川县",
            510230:"大足县",
            510231:"北碚区",
            510232:"璧山县",
            510281:"永川市",
            510282:"江津市",
            510283:"合川市",
            511101:"市辖区",
            511102:"市中区",
            511111:"沙湾区",
            511112:"五通桥区",
            511113:"金口河区",
            511121:"仁寿县",
            511122:"眉山县",
            511123:"犍为县",
            511124:"井研县",
            511125:"峨眉县",
            511126:"夹江县",
            511127:"洪雅县",
            511128:"彭山县",
            511129:"沐川县",
            511130:"青神县",
            511131:"丹棱县",
            511132:"峨边彝族自治县",
            511133:"马边彝族自治县",
            511181:"峨眉山市",
            511201:"市辖区",
            511202:" 龙宝区",
            511203:"天成区",
            511204:"五桥区",
            511221:"开县",
            511222:"忠县",
            511223:"梁平县",
            511224:"云阳县",
            511225:"奉节县",
            511226:"巫山县",
            511227:"巫溪县",
            511228:"城口县",
            511301:"市辖区",
            511302:"顺庆区",
            511303:"高坪区",
            511304:"嘉陵区",
            511321:"南部县",
            511322:"营山县",
            511323:"蓬安县",
            511324:"仪陇县",
            511325:"西充县",
            511381:"阆中市",
            511401:"市辖区",
            511402:"东坡区",
            511421:"仁寿县",
            511422:"彭山县",
            511423:"洪雅县",
            511424:"丹棱县",
            511425:"青神县",
            511501:"市辖区",
            511502:"翠屏区",
            511521:"宜宾县",
            511522:"南溪县",
            511523:"江安县",
            511524:"长宁县",
            511525:"高县",
            511526:"珙县",
            511527:"筠连县",
            511528:"兴文县",
            511529:"屏山县",
            511601:"市辖区",
            511602:"广安区",
            511621:"岳池县",
            511622:"武胜县",
            511623:"邻水县",
            511681:"华蓥市",
            511701:"市辖区",
            511702:"通川区",
            511721:"达县",
            511722:"宣汉县",
            511723:"开江县",
            511724:"大竹县",
            511725:"渠县",
            511781:"万源市",
            511801:"市辖区",
            511802:"雨城区",
            511821:"名山县",
            511822:"荥经县",
            511823:"汉源县",
            511824:"石棉县",
            511825:"天全县",
            511826:"芦山县",
            511827:"宝兴县",
            511901:"市辖区",
            511902:"巴州区",
            511921:"通江县",
            511922:"南江县",
            511923:"平昌县",
            512001:"市辖区",
            512002:"雁江区",
            512021:"安岳县",
            512022:"乐至县",
            512081:"简阳市",
            513521:"石柱土家族自治县",
            513522:"秀山土家族苗族自治县",
            513523:"黔江土家族苗族自治县",
            513524:"酉阳土家族苗族自治县",
            513601:"华蓥市",
            513621:"岳池县",
            513622:"广安县",
            513623:"武胜县",
            513624:"邻水县",
            513701:"巴中市",
            513721:"通江县",
            513722:"南江县",
            513723:"平昌县",
            513821:"眉山县",
            513822:"仁寿县",
            513823:"彭山县",
            513824:"洪雅县",
            513825:"丹棱县",
            513826:"青神县",
            513901:"资阳市",
            513902:"简阳市",
            513921:"安岳县",
            513922:"乐至县",
            519001:"广汉市",
            519002:"江油市",
            519003:"都江堰市",
            519004:"峨眉山市",
            520101:"市辖区",
            520102:"南明区",
            520103:"云岩区",
            520111:"花溪区",
            520112:"乌当区",
            520113:"白云区",
            520114:"小河区",
            520121:"开阳县",
            520122:"息烽县",
            520123:"修文县",
            520181:"清镇市",
            520201:"钟山区",
            520202:"盘县特区",
            520203:"六枝特区",
            520221:"水城县",
            520222:"盘县",
            520301:"市辖区",
            520302:"红花岗区",
            520303:"汇川区",
            520321:"遵义县",
            520322:"桐梓县",
            520323:"绥阳县",
            520324:"正安县",
            520325:"道真仡佬族苗族自治县",
            520326:"务川仡佬族苗族自治县",
            520327:"凤冈县",
            520328:"湄潭县",
            520329:"余庆县",
            520330:"习水县",
            520381:"赤水市",
            520382:"仁怀市",
            520401:"市辖区",
            520402:"西秀区",
            520421:"平坝县",
            520422:"普定县",
            520423:"镇宁布依族苗族自治县",
            520424:"关岭布依族苗族自治县",
            520425:"紫云苗族布依族自治县",
            522101:"遵义市",
            522102:"赤水市",
            522103:"仁怀市",
            522121:"遵义县",
            522122:"桐梓县",
            522123:"绥阳县",
            522124:"正安县",
            522125:"道真仡佬族苗族自治县",
            522126:"务川仡佬族苗族自治县",
            522127:"凤冈县",
            522128:"湄潭县",
            522129:"余庆县",
            522130:"仁怀县",
            522131:"赤水县",
            522132:"习水县",
            522201:"铜仁市",
            522221:"铜仁县",
            522222:"江口县",
            522223:"玉屏侗族自治县",
            522224:"石阡县",
            522225:"思南县",
            522226:"印江土家族苗族自治县",
            522227:"德江县",
            522228:"沿河土家族自治县",
            522229:"松桃苗族自治县",
            522230:"万山特区",
            522301:"兴义市",
            522302:"赤壁市",
            522321:"兴义县",
            522322:"兴仁县",
            522323:"普安县",
            522324:"晴隆县",
            522325:"贞丰县",
            522326:"望谟县",
            522327:"册亨县",
            522328:"安龙县",
            522401:"毕节市",
            522421:"毕节县",
            522422:"大方县",
            522423:"黔西县",
            522424:"金沙县",
            522425:"织金县",
            522426:"纳雍县",
            522427:"威宁彝族回族苗族自治县",
            522428:"赫章县",
            522501:"安顺市",
            522502:"清镇市",
            522521:"安顺县",
            522522:"开阳县",
            522523:"息烽县",
            522524:"修文县",
            522525:"清镇县",
            522526:"平坝县",
            522527:"普定县",
            522528:"关岭布依族苗族自治县",
            522529:"镇宁布依族苗族自治县",
            522530:"紫云苗族布依族自治县",
            522601:"凯里市",
            522622:"黄平县",
            522623:"施秉县",
            522624:"三穗县",
            522625:"镇远县",
            522626:"岑巩县",
            522627:"天柱县",
            522628:"锦屏县",
            522629:"剑河县",
            522630:"台江县",
            522631:"黎平县",
            522632:"榕江县",
            522633:"从江县",
            522634:"雷山县",
            522635:"麻江县",
            522636:"丹寨县",
            522701:"都匀市",
            522702:"福泉市",
            522722:"荔波县",
            522723:"贵定县",
            522724:"福泉县",
            522725:"瓮安县",
            522726:"独山县",
            522727:"平塘县",
            522728:"罗甸县",
            522729:"长顺县",
            522730:"龙里县",
            522731:"惠水县",
            522732:"三都水族自治县",
            530629:"威信县",
            530630:"水富县",
            530601:"市辖区",
            530602:"昭阳区",
            530621:"鲁甸县",
            530622:"巧家县",
            530623:"盐津县",
            530624:"大关县",
            530625:"永善县",
            530626:"绥江县",
            530627:"镇雄县",
            530628:"彝良县",
            530701:"市辖区",
            530702:"古城区",
            530721:"玉龙纳西族自治县",
            530722:"永胜县",
            530723:"华坪县",
            530724:"宁蒗彝族自治县",
            530801:"市辖区",
            530802:"翠云区",
            530821:"普洱哈尼族彝族自治县",
            530822:"墨江哈尼族自治县",
            530823:"景东彝族自治县",
            530824:"景谷傣族彝族自治县",
            530825:"镇沅彝族哈尼族拉祜族自治县",
            530826:"江城哈尼族彝族自治县",
            530827:"孟连傣族拉祜族佤族自治县",
            530828:"澜沧拉祜族自治县",
            530829:"西盟佤族自治县",
            530901:"市辖区",
            530902:"临翔区",
            530921:"凤庆县",
            530922:"云县",
            530923:"永德县",
            530924:"镇康县",
            530925:"双江拉祜族佤族布朗族傣族自治县",
            530926:"耿马傣族佤族自治县",
            530927:"沧源佤族自治县",
            532101:"昭通市",
            532122:"鲁甸县",
            532123:"巧家县",
            532124:"盐津县",
            532125:"大关县",
            532126:"永善县",
            532127:"绥江县",
            532128:"镇雄县",
            532129:"彝良县",
            532130:"威信县",
            532131:"水富县",
            532201:"麒麟区",
            532221:"曲靖县",
            532222:"沾益县",
            532223:"马龙县",
            532224:"宣威市",
            532225:"富源县",
            532226:"罗平县",
            532227:"师宗县",
            532228:"陆良县",
            532229:"宜良县",
            532230:"路南彝族自治县",
            532231:"寻甸回族彝族自治县",
            532232:"嵩明县",
            532233:"会泽县",
            532301:"楚雄市",
            532321:"楚雄县",
            532322:"双柏县",
            532323:"牟定县",
            532324:"南华县",
            532325:"姚安县",
            532326:"大姚县",
            532327:"永仁县",
            532328:"元谋县",
            532329:"武定县",
            532330:"禄劝县",
            532331:"禄丰县",
            533024:"龙陵县",
            533025:"昌宁县",
            533001:"保山市",
            533022:"施甸县",
            533023:"腾冲县",
            533101:"畹町市",
            533102:"瑞丽市",
            533103:"潞西市",
            533121:"潞西县",
            533122:"梁河县",
            533123:"盈江县",
            533124:"陇川县",
            533125:"瑞丽县",
            533126:"畹町镇",
            533221:"丽江纳西族自治县",
            533222:"永胜县",
            533223:"华坪县",
            533224:"宁蒗彝族自治县",
            533321:"泸水县",
            533322:"碧江县",
            533323:"福贡县",
            533324:"贡山独龙族怒族自治县",
            533325:"兰坪白族普米族自治县",
            530101:"市辖区",
            530102:"五华区",
            530103:"盘龙区",
            530111:"官渡区",
            530112:"西山区",
            530113:"东川区",
            530121:"呈贡县",
            530122:"晋宁县",
            530123:"安宁县",
            530124:"富民县",
            530125:"宜良县",
            530126:"石林彝族自治县",
            530127:"嵩明县",
            530128:"禄劝彝族苗族自治县",
            530129:"寻甸回族彝族自治县",
            530181:"安宁市",
            530301:"市辖区",
            530302:"麒麟区",
            530321:"马龙县",
            530322:"陆良县",
            530323:"师宗县",
            530324:"罗平县",
            530325:"富源县",
            530326:"会泽县",
            530328:"沾益县",
            530381:"宣威市",
            530401:"市辖区",
            530402:"红塔区",
            530421:"江川县",
            530422:"澄江县",
            530423:"通海县",
            530424:"华宁县",
            530425:"易门县",
            530426:"峨山彝族自治县",
            530427:"新平彝族傣族自治县",
            530428:"元江哈尼族彝族傣族自治县",
            530501:"市辖区",
            530502:"隆阳区",
            530521:"施甸县",
            530522:" 腾冲县",
            530523:"龙陵县",
            530524:"昌宁县",
            532401:"玉溪市",
            532421:"玉溪县",
            532422:"江川县",
            532423:"澄江县",
            532424:"通海县",
            532425:"华宁县",
            532426:"易门县",
            532427:"峨山彝族自治县",
            532428:"新平彝族傣族自治县",
            532429:"元江哈尼族彝族傣族自治县",
            532501:"个旧市",
            532502:"开远市",
            532522:"蒙自县",
            532523:"屏边苗族自治县",
            532524:"建水县",
            532525:"石屏县",
            532526:"弥勒县",
            532527:"泸西县",
            532528:"元阳县",
            532529:"红河县",
            532530:"金平苗族瑶族傣族自治县",
            532531:"绿春县",
            532532:"河口瑶族自治县",
            532621:"文山县",
            532622:"砚山县",
            532623:"西畴县",
            532624:"麻栗坡县",
            532625:"马关县",
            532626:"丘北县",
            532627:"广南县",
            532628:"富宁县",
            532701:"思茅市",
            532722:"普洱哈尼族彝族自治县",
            532723:"墨江哈尼族自治县",
            532724:"景东彝族自治县",
            532725:"景谷傣族彝族自治县",
            532726:"镇沅彝族哈尼族拉祜族自治县",
            532727:"江城哈尼族彝族自治县",
            532728:"孟连傣族拉祜族佤族自治县",
            532729:"澜沧拉祜族自治县",
            532730:"西盟佤族自治县",
            532801:"景洪市",
            532822:"勐海县",
            532823:" 勐腊县",
            532901:"大理市",
            532922:"漾濞彝族自治县",
            532923:"祥云县",
            532924:"宾川县",
            532925:"弥渡县",
            532926:"南涧彝族自治县",
            532927:"巍山彝族回族自治县",
            532928:"永平县",
            532929:"云龙县",
            532930:"洱源县",
            532931:"剑川县",
            532932:"鹤庆县",
            533421:"香格里拉县",
            533422:"德钦县",
            533423:"维西傈僳族自治县",
            533521:"临沧县",
            533522:"凤庆县",
            533523:"云县",
            533524:"永德县",
            533525:"彭水苗族土家族自治县",
            533526:"双江拉祜族佤族布朗族傣族自治县",
            533527:"耿马傣族佤族自治县",
            533528:"沧源佤族自治县",
            542221:"乃东县",
            542222:"扎囊县",
            542223:"贡嘎县",
            542224:"桑日县",
            542225:"琼结县",
            542226:"曲松县",
            542227:"措美县",
            542228:"洛扎县",
            542229:"加查县",
            542230:"朗县",
            542231:"隆子县",
            542232:"错那县",
            542233:"浪卡子县",
            542301:"日喀则市",
            542321:"日喀则县",
            542322:"南木林县",
            542323:"江孜县",
            542324:"定日县",
            542325:"萨迦县",
            542326:"拉孜县",
            542327:"昂仁县",
            542328:"谢通门县",
            542329:"白朗县",
            542330:"仁布县",
            542331:"康马县",
            542332:"定结县",
            542333:"仲巴县",
            542334:"亚东县",
            542335:"吉隆县",
            542336:"聂拉木县",
            542337:"萨嘎县",
            542338:"岗巴县",
            542421:"那曲县",
            542422:"嘉黎县",
            542423:"比如县",
            542424:"聂荣县",
            542425:"安多县",
            542426:"申扎县",
            542427:"索县",
            542428:"班戈县",
            542429:"巴青县",
            542430:"尼玛县",
            540101:"市辖区",
            540102:"城关区",
            540120:"市区",
            540121:"林周县",
            540122:"当雄县",
            540123:"尼木县",
            540124:"曲水县",
            540125:"堆龙德庆县",
            540126:"达孜县",
            540127:"墨竹工卡县",
            540128:"工布江达县",
            540129:"林芝县",
            540130:"米林县",
            540131:"墨脱县",
            542121:"昌都县",
            542122:"江达县",
            542123:"贡觉县",
            542124:"类乌齐县",
            542125:"丁青县",
            542126:"察雅县",
            542127:"八宿县",
            542128:"左贡县",
            542129:"芒康县",
            542132:"洛隆县",
            542133:"边坝县",
            542134:"盐井县",
            542135:"碧土县",
            542136:"妥坝县",
            542137:"生达县",
            542521:"普兰县",
            542522:"札达县",
            542523:"噶尔县",
            542524:"日土县",
            542525:"革吉县",
            542526:"改则县",
            542527:"措勤县",
            542528:"隆格尔县",
            542621:"林芝县",
            542622:"工布江达县",
            542623:"米林县",
            542624:"墨脱县",
            542625:"波密县",
            542626:"察隅县",
            542627:"朗县",
            542721:"江孜县",
            542722:"浪卡子县",
            542723:"白朗县",
            542724:"仁布县",
            542725:"康马县",
            542726:"亚东县",
            542727:"岗巴县",
            610101:"市辖区",
            610102:"新城区",
            610103:"碑林区",
            610104:"莲湖区",
            610106:"经济开发区",
            610111:"灞桥区",
            610112:"未央区",
            610113:"雁塔区",
            610114:"阎良区",
            610115:"临潼区",
            610116:"长安区",
            610121:"长安县",
            610122:"蓝田县",
            610123:"临潼县",
            610124:"周至县",
            610125:"户县",
            610126:"高陵县",
            610927:"镇坪县",
            610928:"旬阳县",
            610929:"白河县",
            610901:"市辖区",
            610902:"汉滨区",
            610921:"汉阴县",
            610922:"石泉县",
            610923:"宁陕县",
            610924:"紫阳县",
            610925:"岚皋县",
            610926:"平利县",
            611001:"市辖区",
            611002:"商州区",
            611021:"洛南县",
            611022:"丹凤县",
            611023:"商南县",
            611024:"山阳县",
            611025:"镇安县",
            611026:"柞水县",
            612101:"渭南市",
            612102:"韩城市",
            612103:"华阴市",
            612121:"蓝田县",
            612122:"临潼县",
            612123:"渭南县",
            612124:"华县",
            612125:"华阴县",
            612126:"潼关县",
            612127:"大荔县",
            612128:"蒲城县",
            612129:"澄城县",
            612130:"白水县",
            612131:"韩城县",
            612132:"合阳县",
            612133:"富平县",
            612201:"咸阳市",
            612221:"兴平县",
            612222:"周至县",
            612223:"户县",
            612224:"三原县",
            612225:"泾阳县",
            612226:"高陵县",
            612227:"乾县",
            612228:"礼泉县",
            612229:"永寿县",
            612230:"彬县",
            612231:"长武县",
            612232:"旬邑县",
            612233:"淳化县",
            612301:"汉中市",
            612321:"南郑县",
            612322:"城固县",
            612323:"洋县",
            612324:"西乡县",
            612325:"勉县",
            612326:"宁强县",
            612327:"略阳县",
            612328:"镇巴县",
            612329:"留坝县",
            612330:"佛坪县",
            612401:"安康市",
            612421:"安康县",
            612422:"汉阴县",
            612423:"石泉县",
            612424:"宁陕县",
            612425:"紫阳县",
            612426:"岚皋县",
            612427:"平利县",
            612428:"镇坪县",
            612429:"旬阳县",
            612430:"白河县",
            610201:"市辖区",
            610202:"城区",
            610203:"郊区",
            610204:"耀州区",
            610220:"市区",
            610221:"耀县",
            610222:"宜君县",
            610301:"市辖区",
            610302:"渭滨区",
            610303:"金台区",
            610304:"陈仓区",
            610320:"市区",
            610321:"宝鸡县",
            610322:"凤翔县",
            610323:"岐山县",
            610324:"扶风县",
            610325:"武功县",
            610326:"眉县",
            610327:"陇县",
            610328:"千阳县",
            610329:"麟游县",
            610330:"凤县",
            610331:"太白县",
            610401:"市辖区",
            610402:"秦都区",
            610403:"杨陵区",
            610404:"渭城区",
            610421:"兴平县",
            610422:"三原县",
            610423:"泾阳县",
            610424:"乾县",
            610425:"礼泉县",
            610426:"永寿县",
            610427:"彬县",
            610428:"长武县",
            610429:"旬邑县",
            610430:"淳化县",
            610431:"武功县",
            610481:"兴平市",
            610501:"市辖区",
            610502:"临渭区",
            610521:"华县",
            610522:"潼关县",
            610523:"大荔县",
            610524:"合阳县",
            610525:"澄城县",
            610526:"蒲城县",
            610527:"白水县",
            610528:"富平县",
            610581:"韩城市",
            610582:"华阴市",
            610601:"市辖区",
            610602:"宝塔区",
            610621:"延长县",
            610622:"延川县",
            610623:"子长县",
            610624:"安塞县",
            610625:"志丹县",
            610626:"吴起县",
            610627:"甘泉县",
            610628:"富县",
            610629:"洛川县",
            610630:"宜川县",
            610631:"黄龙县",
            610632:"黄陵县",
            610701:"市辖区",
            610702:"汉台区",
            610721:"南郑县",
            610722:"城固县",
            610723:"洋县",
            610724:"西乡县",
            610725:"勉县",
            610726:"宁强县",
            610727:"略阳县",
            610728:"镇巴县",
            610729:"留坝县",
            610730:"佛坪县",
            610801:"市辖区",
            610802:"榆阳区",
            610821:"神木县",
            610822:"府谷县",
            610823:"横山县",
            610824:"靖边县",
            610825:"定边县",
            610826:"绥德县",
            610827:"米脂县",
            610828:"佳县",
            610829:"吴堡县",
            610830:"清涧县",
            610831:"子洲县",
            612501:"商州市",
            612522:"洛南县",
            612523:"丹凤县",
            612524:"商南县",
            612525:"山阳县",
            612526:"镇安县",
            612527:"柞水县",
            612601:"延安市",
            612621:"延长县",
            612622:"延川县",
            612623:"子长县",
            612624:"安塞县",
            612625:"志丹县",
            612626:"吴旗县",
            612627:"甘泉县",
            612628:"富县",
            612629:"洛川县",
            612630:"宜川县",
            612631:"黄龙县",
            612632:"黄陵县",
            612633:"宜君县",
            612701:"榆林市",
            612721:"榆林县",
            612722:"神木县",
            612723:"府谷县",
            612724:"横山县",
            612725:"靖边县",
            612726:"定边县",
            612727:"绥德县",
            612728:"米脂县",
            612729:"佳县",
            612730:"吴堡县",
            612731:"清涧县",
            612732:"子洲县",
            620825:"庄浪县",
            620826:"静宁县",
            620801:"市辖区",
            620802:"崆峒区",
            620821:"泾川县",
            620822:"灵台县",
            620823:"崇信县",
            620824:"华亭县",
            620901:"市辖区",
            620902:"肃州区",
            620921:"金塔县",
            620922:"瓜州县",
            620923:"肃北蒙古族自治县",
            620924:"阿克塞哈萨克族自治县",
            620981:"玉门市",
            620982:"敦煌市",
            621001:"市辖区",
            621002:"西峰区",
            621021:"庆城县",
            621022:"环县",
            621023:"华池县",
            621024:"合水县",
            621025:"正宁县",
            621026:"宁县",
            621027:"镇原县",
            621101:"市辖区",
            621102:"安定区",
            621121:"通渭县",
            621122:"陇西县",
            621123:"渭源县",
            621124:"临洮县",
            621125:"漳县",
            621126:"岷县",
            621201:"市辖区",
            621202:"武都区",
            621221:"成县",
            621222:"文县",
            621223:"宕昌县",
            621224:"康县",
            621225:"西和县",
            621226:"礼县",
            621227:"徽县",
            621228:"两当县",
            622101:"玉门市",
            622102:"酒泉市",
            622103:"敦煌市",
            622123:"金塔县",
            622124:"肃北蒙古族自治县",
            622125:"阿克塞哈萨克族自治县",
            622126:"安西县",
            622201:"张掖市",
            622222:"肃南裕固族自治县",
            622223:"民乐县",
            622224:"临泽县",
            622225:"高台县",
            622226:"山丹县",
            622301:"武威市",
            622322:"民勤县",
            622323:"古浪县",
            622326:"天祝藏族自治县",
            622327:"连古城国家级自然保护区管理局",
            622328:"石羊河林业总场",
            622329:"苏武山林场",
            622421:"定西县",
            622424:"通渭县",
            622425:"陇西县",
            622426:"渭源县",
            622427:"临洮县",
            622428:"漳县",
            622429:"岷县",
            622621:"武都县",
            622623:"宕昌县",
            622624:"成县",
            622625:"康县",
            622626:"文县",
            622627:"西和县",
            622628:"礼县",
            622629:" 两当县",
            622630:"徽县",
            622701:"平凉市",
            622722:"泾川县",
            622723:"灵台县",
            622724:"崇信县",
            622725:"华亭县",
            622726:"庄浪县",
            622727:"静宁县",
            622801:"西峰市",
            622821:"庆阳县",
            622822:"环县",
            622823:"华池县",
            622824:"合水县",
            622825:"正宁县",
            622826:"宁县",
            622827:"镇原县",
            622901:"临夏市",
            622921:"临夏县",
            622922:"康乐县",
            622923:"永靖县",
            622924:"广河县",
            622925:"和政县",
            622926:"东乡族自治县",
            622927:"积石山保安族东乡族撒拉族自治县",
            623001:"合作市",
            623021:"临潭县",
            623022:"卓尼县",
            623023:"舟曲县",
            623024:"迭部县",
            623025:"玛曲县",
            623026:"碌曲县",
            623027:"夏河县",
            620101:"市辖区",
            620102:"城关区",
            620103:"七里河区",
            620104:"西固区",
            620105:"安宁区",
            620111:"红古区",
            620121:"永登县",
            620122:"皋兰县",
            620123:"榆中县",
            620201:"市辖区",
            620202:"嘉峪关区",
            620301:"市辖区",
            620302:"金川区",
            620321:"永昌县",
            620401:"市辖区",
            620402:"白银区",
            620403:"平川区",
            620421:"靖远县",
            620422:"会宁县",
            620423:"景泰县",
            620501:"市辖区",
            620502:"秦州区",
            620503:"麦积区",
            620521:"清水县",
            620522:"秦安县",
            620523:"甘谷县",
            620524:"武山县",
            620525:"张家川回族自治县",
            620601:"市辖区",
            620602:"凉州区",
            620621:"民勤县",
            620622:"古浪县",
            620623:"天祝藏族自治县",
            620701:"市辖区",
            620702:"甘州区",
            620721:"肃南裕固族自治县",
            620722:" 民乐县",
            620723:"临泽县",
            620724:"高台县",
            620725:"山丹县",
            630101:"市辖区",
            630102:"城东区",
            630103:"城中区",
            630104:"城西区",
            630105:"城北区",
            630121:"大通回族土族自治县",
            630122:"湟中县",
            630123:"湟源县",
            632521:"共和县",
            632522:"同德县",
            632523:"贵德县",
            632524:"兴海县",
            632525:"贵南县",
            632621:"玛沁县",
            632622:"班玛县",
            632623:"甘德县",
            632624:"达日县",
            632625:"久治县",
            632626:"玛多县",
            632632:"班玛县",
            632721:"玉树县",
            632722:"杂多县",
            632723:"称多县",
            632724:"治多县",
            632725:"囊谦县",
            632726:"曲麻莱县",
            632801:"格尔木市",
            632802:"德令哈市",
            632821:"乌兰县",
            632822:"都兰县",
            632823:"天峻县",
            632824:"大柴旦行委",
            632825:"冷湖行委",
            632826:"茫崖行委",
            632121:"平安县",
            632122:"民和回族土族自治县",
            632123:"乐都县",
            632124:"湟中县",
            632125:"湟源县",
            632126:"互助土族自治县",
            632127:"化隆回族自治县",
            632128:" 循化撒拉族自治县",
            632221:"门源回族自治县",
            632222:"祁连县",
            632223:"海晏县",
            632224:"刚察县",
            632321:"同仁县",
            632322:"尖扎县",
            632323:"泽库县",
            632324:"河南蒙古族自治县",
            640101:"市辖区",
            640102:"城区",
            640103:"新城区",
            640104:"兴庆区",
            640105:"西夏区",
            640106:"金凤区",
            640111:"郊区",
            640121:"永宁县",
            640122:"贺兰县",
            640181:"灵武市",
            640201:"市辖区",
            640202:"大武口区",
            640203:"石嘴山区",
            640204:"石炭井区",
            640205:"惠农区",
            640211:"郊区",
            640220:"市区",
            640221:"平罗县",
            640222:"陶乐县",
            640223:"惠农县",
            640301:"市辖区",
            640302:"利通区",
            640321:"中卫县",
            640322:"中宁县",
            640323:"盐池县",
            640324:"同心县",
            640381:"青铜峡市",
            640382:"灵武市",
            640401:"市辖区",
            640402:"原州区",
            640421:"海原县",
            640422:"西吉县",
            640423:"隆德县",
            640424:"泾源县",
            640425:"彭阳县",
            640501:"市辖区",
            640502:"沙坡头区",
            640521:"中宁县",
            640522:"海原县",
            642101:"吴忠市",
            642102:"利通区",
            642103:"灵武市",
            642121:"吴忠县",
            642122:"青铜峡县",
            642123:"中卫县",
            642124:"中宁县",
            642125:"灵武县",
            642126:"盐池县",
            642127:"同心县",
            642221:"固原县",
            642222:"海原县",
            642223:"西吉县",
            642224:"隆德县",
            642225:"泾源县",
            642226:"彭阳县",
            650101:"市辖区",
            650102:"天山区",
            650103:"沙依巴克区",
            650104:"新市区",
            650105:"水磨沟区",
            650106:"头屯河区",
            650107:"南山矿区",
            650108:"东山区",
            650121:"乌鲁木齐县",
            650201:"市辖区",
            650202:"独山子区",
            650203:"克拉玛依区",
            650204:"白碱滩区",
            650205:"乌尔禾区",
            652101:"吐鲁番市",
            652122:"鄯善县",
            652123:"托克逊县",
            652201:"哈密市",
            652222:"巴里坤哈萨克自治县",
            652223:"伊吾县",
            652301:"昌吉市",
            652302:"阜康市",
            652303:"米泉市",
            652322:"米泉市",
            652323:"呼图壁县",
            652324:"玛纳斯县",
            652325:"奇台县",
            652326:"阜康市",
            652327:"吉木萨尔县",
            652328:"木垒哈萨克自治县",
            652401:"伊宁市",
            652402:"奎屯市",
            652421:"伊宁县",
            652422:"察布查尔锡伯自治县",
            652423:"霍城县",
            652424:"巩留县",
            652425:"新源县",
            652426:"昭苏县",
            652427:"特克斯县",
            652428:"尼勒克县",
            652521:"塔城县",
            652522:"额敏县",
            652523:"乌苏市",
            652524:"沙湾县",
            652525:"托里县",
            652526:"裕民县",
            652527:"和布克赛尔蒙古自治县",
            652601:"阿勒泰县",
            652622:"布尔津县",
            652623:"富蕴县",
            652624:" 福海县",
            652625:"哈巴河县",
            652626:"青河县",
            652627:"吉木乃县",
            654322:"富蕴县",
            654323:"福海县",
            654324:"哈巴河县",
            654325:"青河县",
            654326:"吉木乃县",
            654301:"阿勒泰市",
            654321:"布尔津县",
            659001:"石河子市",
            659002:"阿拉尔市",
            659003:"图木舒克市",
            659004:"五家渠市",
            652701:"博乐市",
            652721:"博乐县",
            652722:"精河县",
            652723:"温泉县",
            652801:"库尔勒市",
            652821:"库尔勒县",
            652822:"轮台县",
            652823:"尉犁县",
            652824:"若羌县",
            652825:"且末县",
            652826:"焉耆回族自治县",
            652827:"和静县",
            652828:"和硕县",
            652829:"博湖县",
            652901:"阿克苏市",
            652921:"阿克苏县",
            652922:"温宿县",
            652923:"库车县",
            652924:"沙雅县",
            652925:"新和县",
            652926:"拜城县",
            652927:"乌什县",
            652928:"阿瓦提县",
            652929:"柯坪县",
            653001:"阿图什市",
            653021:"阿图什县",
            653022:"阿克陶县",
            653023:"阿合奇县",
            653024:"乌恰县",
            653101:"喀什市",
            653121:"疏附县",
            653122:"疏勒县",
            653123:"英吉沙县",
            653124:"泽普县",
            653125:"莎车县",
            653126:"叶城县",
            653127:"麦盖提县",
            653128:"岳普湖县",
            653129:"伽师县",
            653130:"巴楚县",
            653131:"塔什库尔干塔吉克自治县",
            653201:"和田市",
            653221:"和田县",
            653222:"墨玉县",
            653223:"皮山县",
            653224:"洛浦县",
            653225:"策勒县",
            653226:"于田县",
            653227:"民丰县",
            654001:"奎屯市",
            654002:"伊宁市",
            654003:"奎屯市",
            654021:"伊宁县",
            654022:"察布查尔锡伯自治县",
            654023:"霍城县",
            654024:"巩留县",
            654025:"新源县",
            654026:"昭苏县",
            654027:"特克斯县",
            654028:"尼勒克县",
            654101:"伊宁市",
            654121:"伊宁县",
            654122:"察布查尔锡伯自治县",
            654123:"霍城县",
            654124:"巩留县",
            654125:"新源县",
            654126:"昭苏县",
            654127:"特克斯县",
            654128:"尼勒克县",
            654201:"塔城市",
            654202:"乌苏市",
            654221:"额敏县",
            654222:"乌苏市",
            654223:"沙湾县",
            654224:"托里县",
            654225:"裕民县",
            654226:"和布克赛尔蒙古自治县"
        }
    }


}
export default id_card_area;

