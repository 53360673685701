<!--
 * @Author: your name
 * @Date: 2021-02-24 13:30:28
 * @LastEditTime: 2021-05-21 23:01:11
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one\src\views\reportview2\reportview2.vue
-->
<template>
  <div class="allmsgclass">
    <div v-if="isloadmm" class="loadmm">
      <div>
        <div style="text-align: center">
          <img class="loadmimg" src="../../assets/loading.gif" alt="" />
        </div>
        <div class="fonttextclass" style="text-align: center">{{ tipmsg }}</div>
      </div>

      <!-- <van-loading type="spinner" color="#1989fa" /> -->
    </div>
    <van-popup class="tanluo" v-model="show">
      <div>
        <img src="../../assets/users.png" alt="" />
        <div class="luomsg">
          <div class="luo1">
            <div>本报告仅限委托方公司内部</div>
            <div>使用，严禁扩散</div>
          </div>
          <div class="luo2">
            <div>「萝卜猎手，永远守护您企</div>
            <div>业的用人安全」</div>
          </div>
        </div>
      </div>
      <button @click="show = false" class="bt">确认</button>
    </van-popup>
    <div class="topmsgclass">
      <div class="topmsgtheclass">
        <div>
          <div class="luoclass">
            <img src="../../assets/luobo3.png" />
          </div>
          <div class="toprightclass">
            <div>{{ name }}个人多维度背景调查报告</div>
            <div>
              <div class="imgbao">
                <img
                  v-show="personalmsg.is_insurance == 1"
                  src="../../assets/ok.png"
                />
                <img
                  v-show="personalmsg.is_insurance == 0"
                  src="../../assets/no.png"
                />
                <div>保险保障</div>
              </div>
              <div class="imgbao">
                <img src="../../assets/ok.png" />
                <div>本人授权</div>
              </div>
              <div class="imgbao">
                <img src="../../assets/ok.png" />
                <div>信息确认</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img @click="tobackmeth" class="rightimgclass" :src="rightimg" alt="" />
    </div>

    <div class="tipclass">
      <div>机密警示：</div>
      <div>
        本报告属于<span>机密信息</span>，未经候选人本人授权许可，不得以任何理由任何形式向第三方转发本报告内容。
      </div>
    </div>
    <div class="persionmsg">
      <div>报告候选人</div>
      <div>{{ name }}</div>
      <div class="per1xlass per">
        <img src="../../assets/p1.png" />
        <div>{{ personalmsg.sex }}</div>
        <div>{{ age }}岁</div>
        <div>{{ constellation }}</div>
        <div>{{ zodiac }}</div>
      </div>
      <div class="per2xlass per">
        <img src="../../assets/p2.png" />
        <div>手机号码：{{ mobile }}</div>
      </div>
      <div class="per3xlass per">
        <img src="../../assets/p3.png" />
        <div>身份证号：{{ id_no }}</div>
      </div>
      <div class="per4xlass per">
        <img src="../../assets/p4.png" />
        <div>血<span></span>型：保密</div>
      </div>
      <div class="per5xlass per">
        <img src="../../assets/p5.png" />
        <div>来<span></span>自：{{ address }}</div>
      </div>
      <div class="per6xlass per">
        <img src="../../assets/p6.png" />
        <div>主要居住：{{ phoneaddress }}</div>
        <div class="jgclass"></div>
      </div>
      <div class="nomsgclass"></div>
      <div class="baomsgclass">
        <div><span>报告类型：</span>{{ personalmsg.product_edition }}</div>
        <div>
          <span>参保类型：</span
          >{{ personalmsg.is_insurance == 0 ? "无" : "员工入离职损失保" }}
        </div>
        <div>
          <span>承保日期：</span
          >{{ personalmsg.is_insurance == 0 ? "无" : personalmsg.back_time }}
        </div>
        <div class="nei">
          <div><span>保障内容：</span></div>
          <div v-show="personalmsg.is_insurance == 1">
            经计算，候选人若于<span class="thespanclass">{{ totimebao }}</span
            >之前离职，且对贵司造成损失的，可获得赔付率为<span
              class="thespanclass"
              >100%～300%</span
            >
          </div>
          <div v-show="personalmsg.is_insurance == 0">
            本报告未参与招聘费用损失补偿保险计划，如有损失无法获得理赔
          </div>
        </div>
      </div>
      <div class="nomsgclass2"></div>
      <div class="danweiclass">
        <span>报告使用单位：</span><span>{{ company }}</span>
      </div>
      <div class="nomsgclass3"></div>
      <div class="caoclass">
        <span>操作人员：</span><span>{{ creatuser }}</span>
      </div>
      <div class="nomsgclass4"></div>
      <div class="bao2">
        <span>报告编码：</span><span>{{ backNo }}</span>
      </div>
      <div class="bao3">
        <span>报告时间：</span><span>{{ backTime }}</span>
      </div>
      <img
        v-show="(personalmsg.sex + '').indexOf('男') != -1"
        class="toux"
        src="../../assets/peo1.png"
      />
      <img
        v-show="(personalmsg.sex + '').indexOf('女') != -1"
        class="toux"
        src="../../assets/peo2.png"
      />
    </div>
    <div class="shengmingclass">
      <div>重要声明：</div>
      <div>
        {{ allmsg.statement1 }}
      </div>
    </div>
    <div class="tabclass">
      <van-sticky>
        <van-tabs :swipe-threshold="3" @click="anchor">
          <van-tab
            v-for="(item, index) in tablist"
            v-bind:key="index"
            :title="item.value"
          >
          </van-tab>
        </van-tabs>
      </van-sticky>
    </div>
    <div id="overview" class="overviewclass">
      <div class="topimgthe">
        <div>
          <div class="numberclass">{{ risknum.no }}</div>
        </div>
        <div>
          <div class="numberclass">{{ risknum.yes }}</div>
        </div>
      </div>
      <div class="dunmsgclass">
        <div
          v-for="(item, index) in projectmsg"
          v-bind:key="index"
          class="menuclass"
        >
          <img v-show="item.isok == 'true'" :src="dunyes" />
          <img v-show="item.isok == 'false'" :src="dunno" />
          <div>{{ index }}</div>
        </div>
      </div>
      <div class="nomsgclass5"></div>
      <div class="bgsm">报告说明：</div>
      <div class="smclass2" id="htid" v-html="baogao"></div>
      <div class="nomsgclass5"></div>
      <div class="zhishuclass">萝卜个人背景指数：</div>
      <div class="zhishu2">
        当前萝卜个人背景指数为{{ backnum }}分，等级为{{
          backnum > 60 ? "低风险" : backnum > 40 ? "中风险" : "高风险"
        }}
      </div>
      <div class="zhishuimgdiv">
        <div :class="backnum > 60 ? 'ncl3' : backnum > 40 ? 'ncl2' : 'ncl1'">
          <div>{{ backnum }}</div>
          <div class="fx">
            {{ backnum > 61 ? "低风险" : backnum > 41 ? "中风险" : "高风险" }}
          </div>
        </div>
      </div>
      <div class="shuomingclassthe">
        <div class="smclass">
          <div class="sm1 sm5">说明</div>
          <div class="sm2">
            <div></div>
            <div>
              萝卜背景指数反映了候选人的背景指标，指数越高说明匹配性越好。本指数仅为企业提供雇佣参考，请谨慎使用。
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      :id="index.charCodeAt()"
      v-for="(item, index) in projectmsg"
      v-bind:key="index"
    >
      <div class="tipmsg">
        <div></div>
        <div>{{ index }}：</div>
      </div>
      <div class="shengmingclass2">
        <div
          v-for="(itemes, indexes) in item"
          v-bind:key="indexes"
          class="allhengmsg"
        >
          <div>
            <div class="hengmsg">
              <div>项<span class="xmwid"></span>目：</div>
              <div class="heng2">{{ itemes.api_name }}</div>
            </div>
            <div
              v-if="
                itemes.api_name == '身份核验' ||
                itemes.api_name.indexOf('手机') != -1 ||
                itemes.api_name.indexOf('护照') != -1
              "
            >
              <div class="hengmsg">
                <div>姓<span class="xmwid"></span>名：</div>
                <div>{{ name }}</div>
              </div>
              <div class="hengmsg">
                <div>身份证号：</div>
                <div>{{ id_no }}</div>
              </div>
            </div>
            <div v-else>
              <div class="hengmsg">
                <div>查询结果：</div>
                <!-- "国内学历查询" -->
                <div
                  v-if="
                    itemes.parameters == 'educationInfo_query' &&
                    itemes.description_back_results.indexOf('{') != -1
                  "
                >
                  <!-- <div>
                    任务状态：{{
                      JSON.parse(itemes.description_back_results).state == "1"
                        ? "查询中"
                        : JSON.parse(itemes.description_back_results).state ==
                          "2"
                        ? "查询失败"
                        : JSON.parse(itemes.description_back_results).state ==
                          "3"
                        ? "查询成功，无数据或身份核验未通过"
                        : JSON.parse(itemes.description_back_results).state ==
                          "4"
                        ? "查询成功，有数据"
                        : "无数据"
                    }}
                  </div> -->
                  <div>
                    证书编号：{{
                      JSON.parse(itemes.description_back_results).certificateNo
                    }}
                  </div>
                  <div>
                    学校名称：{{
                      JSON.parse(itemes.description_back_results).school
                    }}
                  </div>
                  <div>
                    专业：{{
                      JSON.parse(itemes.description_back_results).major
                    }}
                  </div>
                  <div>
                    学历类别：{{
                      JSON.parse(itemes.description_back_results).educationType
                    }}
                  </div>
                  <div>
                    学制：{{
                      JSON.parse(itemes.description_back_results).schoolSystem
                    }}
                  </div>
                  <div>
                    学习形式：{{
                      JSON.parse(itemes.description_back_results).learningForm
                    }}
                  </div>
                  <div>
                    层次：{{
                      JSON.parse(itemes.description_back_results).educationLevel
                    }}
                  </div>
                  <div>
                    毕（结）业：{{
                      JSON.parse(itemes.description_back_results).graduation
                    }}
                  </div>
                  <!-- <div>
                    入学时间：{{
                      JSON.parse(itemes.description_back_results).admissionDate
                    }}
                  </div> -->
                  <div>
                    毕（结）业日期：{{
                      JSON.parse(itemes.description_back_results).graduationDate
                    }}
                  </div>
                  <!-- <div>
                    校（院）长姓名：{{
                      JSON.parse(itemes.description_back_results).presidentName
                    }}
                  </div> -->
                </div>
                <!--职业技能资格核验-->
                <div
                  v-else-if="
                    itemes.parameters == 'certQuery' &&
                    itemes.description_back_results.indexOf('{') != -1
                  "
                >
                  <!-- <div>
                    姓名：{{ JSON.parse(itemes.description_back_results).name }}
                  </div>
                  <div>
                    性别：{{ JSON.parse(itemes.description_back_results).sex }}
                  </div>
                  <div>
                    身份证号码：{{
                      JSON.parse(itemes.description_back_results).cardNo
                    }}
                  </div> -->

                  <!--证书列表-->
                  <div
                    v-for="(item5, index5) in JSON.parse(
                      itemes.description_back_results
                    ).certs"
                    v-bind:key="index5"
                  >
                    <div>职业资格：{{ item5.certName }}</div>
                    <div>技能等级：{{ item5.certLevel }}</div>
                    <div>证书编号：{{ item5.certId }}</div>
                    <div>鉴定中心：{{ item5.identification }}</div>
                    <div>发证日期：{{ item5.issueDate }}</div>
                    <!-- <div>数据责任单位：{{ item5.dataResponsibleUnit }}</div> -->
                    <div>发证机关：{{ item5.licenseOrg }}</div>
                  </div>
                  <div>
                    查询平台：{{
                      JSON.parse(itemes.description_back_results).queryPlatform
                    }}
                  </div>
                </div>
                <!--个人涉诉查询-->
                <div
                  v-else-if="
                    itemes.parameters == 'detailJudgeStruct' &&
                    itemes.description_back_results.indexOf('{') != -1
                  "
                >
                  <div>
                    标题：{{
                      JSON.parse(itemes.description_back_results).title
                    }}
                  </div>
                  <div>
                    案号：{{
                      JSON.parse(itemes.description_back_results).caseCode
                    }}
                  </div>
                  <div>
                    法院：{{
                      JSON.parse(itemes.description_back_results).court
                    }}
                  </div>
                  <div>
                    查询主体立场：{{
                      JSON.parse(itemes.description_back_results).standPoint
                    }}
                  </div>
                  <div>
                    案由：{{
                      JSON.parse(itemes.description_back_results).reason
                    }}
                  </div>
                  <div>
                    裁判日期：{{
                      JSON.parse(itemes.description_back_results).judgeDate
                    }}
                  </div>
                  <div>
                    公布日期：{{
                      JSON.parse(itemes.description_back_results).publishDate
                    }}
                  </div>
                  <!-- <div>
                    案件金额：{{
                      JSON.parse(itemes.description_back_results).realPriceSum
                    }}
                  </div> -->
                  <div>
                    败胜诉：{{
                      JSON.parse(itemes.description_back_results).winLosse
                    }}
                  </div>
                  <div>
                    案由标签：{{
                      JSON.parse(itemes.description_back_results).tag
                    }}
                  </div>

                  <!-- <div>"匹配文本缩略内容"：</div>
                  <div
                    v-html="
                      JSON.parse(
                        itemes.description_back_results
                      ).content.substr(0, 300) +
                      moremsg +
                      JSON.parse(
                        itemes.description_back_results
                      ).content.substr(-300, 300)
                    "
                  ></div> -->
                </div>
                <!--被执行人查询-->
                <div
                  v-else-if="
                    itemes.parameters == 'bzxrList' &&
                    itemes.description_back_results.indexOf('{') != -1
                  "
                >
                  <div>
                    案号：{{
                      JSON.parse(itemes.description_back_results).caseCode
                    }}
                  </div>
                  <div>
                    法院：{{
                      JSON.parse(itemes.description_back_results).courtName
                    }}
                  </div>
                  <div>
                    执行标的：{{
                      JSON.parse(itemes.description_back_results).execMoney
                    }}
                  </div>
                  <div>
                    案件状态：{{
                      JSON.parse(itemes.description_back_results).caseState
                    }}
                  </div>
                  <div>
                    立案时间：{{
                      JSON.parse(itemes.description_back_results).regDate
                    }}
                  </div>
                  <div>
                    命中类型(被执行人、案件当事人、曾用名)：{{
                      JSON.parse(itemes.description_back_results).relateType
                    }}
                  </div>
                  <!-- <div>
                    主体名称：{{
                      JSON.parse(itemes.description_back_results).entityName
                    }}
                  </div>
                  <div>
                    主体代码：{{
                      JSON.parse(itemes.description_back_results).entityId
                    }}
                  </div> -->
                </div>
                <!--失信被执行人查询-->
                <div
                  v-else-if="
                    itemes.parameters == 'sxrListv4' &&
                    itemes.description_back_results.indexOf('{') != -1
                  "
                >
                  <div>
                    案号：{{
                      JSON.parse(itemes.description_back_results).caseCode
                    }}
                  </div>
                  <div>
                    法院：{{
                      JSON.parse(itemes.description_back_results).courtName
                    }}
                  </div>
                  <div>
                    执行依据文号：{{
                      JSON.parse(itemes.description_back_results).gistId
                    }}
                  </div>
                  <div>
                    立案时间：{{
                      JSON.parse(itemes.description_back_results).regDate
                    }}
                  </div>
                  <div>
                    失信类型：{{
                      JSON.parse(itemes.description_back_results)
                        .disruptTypeName
                    }}
                  </div>
                  <div>
                    发布日期：{{
                      JSON.parse(itemes.description_back_results).publishDate
                    }}
                  </div>
                  <div>
                    命中类型(失信被执行人、关联案件、曾用名)：{{
                      JSON.parse(itemes.description_back_results).relateType
                    }}
                  </div>
                  <div>
                    下架状态：{{
                      JSON.parse(itemes.description_back_results).status
                    }}
                  </div>
                  <!-- <div>
                    主体名称：{{
                      JSON.parse(itemes.description_back_results).entityName
                    }}
                  </div>
                  <div>
                    主体代码：{{
                      JSON.parse(itemes.description_back_results).entityId
                    }}
                  </div>
                  
                  <div>
                    年龄：{{ JSON.parse(itemes.description_back_results).age }}
                  </div>
                  <div>
                    性别：{{ JSON.parse(itemes.description_back_results).sex }}
                  </div>
                  <div>
                    法定代表人或者负责人姓名：{{
                      JSON.parse(itemes.description_back_results)
                        .bussinessEntity
                    }}
                  </div>
                  
                  <div>
                    省份：{{
                      JSON.parse(itemes.description_back_results).areaName
                    }}
                  </div>
                  <div>
                    类型：{{
                      JSON.parse(itemes.description_back_results).partyTypeName
                    }}
                  </div>

                  <div>
                    做出执行依据单位：{{
                      JSON.parse(itemes.description_back_results).gistUnit
                    }}
                  </div>
                  <div>
                    生效法律文书确定的义务：{{
                      JSON.parse(itemes.description_back_results).duty
                    }}
                  </div>
                  <div>
                    被执行人的履行情况：{{
                      JSON.parse(itemes.description_back_results).performance
                    }}
                  </div>
                  <div>
                    已履行：{{
                      JSON.parse(itemes.description_back_results).performedPart
                    }}
                  </div>
                  <div>
                    未履行：{{
                      JSON.parse(itemes.description_back_results).unPerformPart
                    }}
                  </div> -->
                </div>
                <!--限制高消费查询-->
                <div
                  v-else-if="
                    itemes.parameters == 'limitConsumee' &&
                    itemes.description_back_results.indexOf('{') != -1
                  "
                >
                  <div>
                    案号：{{
                      JSON.parse(itemes.description_back_results).caseCode
                    }}
                  </div>
                  <div>
                    法院：{{
                      JSON.parse(itemes.description_back_results).courtName
                    }}
                  </div>
                  <div>
                    日期：{{
                      JSON.parse(itemes.description_back_results).regDate
                    }}
                  </div>
                  <!-- <div>
                    公司：{{
                      JSON.parse(itemes.description_back_results).company
                    }}
                  </div>
                  <div>
                    编码：{{
                      JSON.parse(itemes.description_back_results).entityId
                    }}
                  </div>
                  <div>
                    姓名：{{
                      JSON.parse(itemes.description_back_results).entityName
                    }}
                  </div> -->
                  <div>
                    状态：{{
                      JSON.parse(itemes.description_back_results).state
                    }}
                  </div>
                </div>
                <!--行政处罚查询-->
                <div
                  v-else-if="
                    itemes.parameters == 'per_punishmentNew' &&
                    itemes.description_back_results.indexOf('{') != -1
                  "
                >
                  <div>
                    违法行为类型：{{
                      JSON.parse(itemes.description_back_results).criminalType
                    }}
                  </div>
                  <div>
                    行政处罚决定书文号：{{
                      JSON.parse(itemes.description_back_results).decideNo
                    }}
                  </div>
                  <div>
                    违法行为类型：{{
                      JSON.parse(itemes.description_back_results).criminalType
                    }}
                  </div>
                  <div>
                    行政处罚内容：{{
                      JSON.parse(itemes.description_back_results).punishContent
                    }}
                  </div>
                  <div>
                    行政处罚决定机关名称：{{
                      JSON.parse(itemes.description_back_results).decideOrg
                    }}
                  </div>
                  <div>
                    作出行政处罚决定日期：{{
                      JSON.parse(itemes.description_back_results).decideDate
                    }}
                  </div>
                  <div>
                    公示机关：{{
                      JSON.parse(itemes.description_back_results).publishOrg
                    }}
                  </div>
                  <!-- <div>
                    当事人类型，0：自然人，1：法人：{{
                      JSON.parse(itemes.description_back_results).partyType
                    }}
                  </div>
                  <div>
                    当事人名称：{{
                      JSON.parse(itemes.description_back_results).partiesName
                    }}
                  </div>
                  <div>
                    当事人注册号：{{
                      JSON.parse(itemes.description_back_results).regNo
                    }}
                  </div>
                  <div>
                    法定代表人（当事人）身份证号：{{
                      JSON.parse(itemes.description_back_results).idNumber
                    }}
                  </div>
                  <div>
                    法定代表人（负责人）：{{
                      JSON.parse(itemes.description_back_results).legalPerson
                    }}
                  </div>
                  <div>
                    行政法律处罚依据：{{
                      JSON.parse(itemes.description_back_results).decideBasis
                    }}
                  </div>
                  
                  <div>
                    当事人历史名称：{{
                      JSON.parse(itemes.description_back_results).oldPartiesName
                    }}
                  </div>
                  <div>
                    处罚类型：{{
                      JSON.parse(itemes.description_back_results).punishType
                    }}
                  </div>
                  <div>
                    区域名称：{{
                      JSON.parse(itemes.description_back_results).areaName
                    }}
                  </div> -->
                </div>
                <!--金融监管机构处罚信息查询-->
                <div
                  v-else-if="
                    itemes.parameters == 'futuresMarketCreditQueryService' &&
                    itemes.description_back_results.indexOf('{') != -1
                  "
                >
                  <div>
                    违法违规失信者名称/姓名：{{
                      JSON.parse(itemes.description_back_results).name
                    }}
                  </div>
                  <div>
                    身份证号码/组织机构代码/统一社会信用代码：{{
                      JSON.parse(itemes.description_back_results).creditCode
                    }}
                  </div>
                  <div>
                    处罚内容：{{
                      JSON.parse(itemes.description_back_results).fileContent
                    }}
                  </div>
                  <div>
                    处罚种类：{{
                      JSON.parse(itemes.description_back_results).punishmentType
                    }}
                  </div>
                  <div>
                    处罚处理时间：{{
                      JSON.parse(itemes.description_back_results).punishmentTime
                    }}
                  </div>
                  <div>
                    处罚处理机构：{{
                      JSON.parse(itemes.description_back_results).punishmentOrg
                    }}
                  </div>
                  <!-- <div>
                    处罚处理文件：{{
                      JSON.parse(itemes.description_back_results).filename
                    }}
                  </div>
                  <div>
                    处罚处理种类：{{
                      JSON.parse(itemes.description_back_results).punishmentType
                    }}
                  </div> -->
                </div>
                <!--税务负面信息查询-->
                <div
                  v-else-if="
                    itemes.parameters == 'taxBlack' &&
                    itemes.description_back_results.indexOf('{') != -1
                  "
                >
                  <div>
                    纳税人名称：{{
                      JSON.parse(itemes.description_back_results).regName
                    }}
                  </div>
                  <div>
                    统一社会信用代码：{{
                      JSON.parse(itemes.description_back_results).creditNo
                    }}
                  </div>
                  <div>
                    黑名单类型：{{
                      JSON.parse(itemes.description_back_results).blackType ==
                      "0"
                        ? "重大违法案件"
                        : JSON.parse(itemes.description_back_results)
                            .blackType == "1"
                        ? "欠税户"
                        : JSON.parse(itemes.description_back_results)
                            .blackType == "2"
                        ? "非正常户"
                        : JSON.parse(itemes.description_back_results)
                            .blackType == "3"
                        ? "失效户"
                        : JSON.parse(itemes.description_back_results)
                            .blackType == "4"
                        ? "违法户"
                        : JSON.parse(itemes.description_back_results)
                            .blackType == "5"
                        ? "限期改正（除去催报、催缴的情况）"
                        : JSON.parse(itemes.description_back_results)
                            .blackType == "6"
                        ? "注销"
                        : JSON.parse(itemes.description_back_results)
                            .blackType == "7"
                        ? "文书送达"
                        : JSON.parse(itemes.description_back_results)
                            .blackType == "8"
                        ? "催报"
                        : JSON.parse(itemes.description_back_results)
                            .blackType == "9"
                        ? "催缴"
                        : "无数据"
                    }}
                  </div>
                  <div>
                    认定原因：{{
                      JSON.parse(itemes.description_back_results).evalReason
                    }}
                  </div>
                  <div>
                    认定日期或所属日期：{{
                      JSON.parse(itemes.description_back_results).evalDate
                    }}
                  </div>
                  <div>
                    违法事实及处罚情况：{{
                      JSON.parse(itemes.description_back_results).illegal
                    }}
                  </div>
                  <div>
                    发布机构：{{
                      JSON.parse(itemes.description_back_results).taxOrg
                    }}
                  </div>

                  <!-- <div>
                    区域名称：{{
                      JSON.parse(itemes.description_back_results).areaName
                    }}
                  </div>
                  <div>
                    税务局分类：{{
                      JSON.parse(itemes.description_back_results).taxOrgType
                    }}
                  </div>
                  <div>
                    信息发布税务局：{{
                      JSON.parse(itemes.description_back_results).taxOrg
                    }}
                  </div>
                  <div>
                    管理或征管机关：{{
                      JSON.parse(itemes.description_back_results).managerOrg
                    }}
                  </div>
                  
                  <div>
                    计算机代码：{{
                      JSON.parse(itemes.description_back_results).computerNo
                    }}
                  </div>
                  <div>
                    税务管理代码：{{
                      JSON.parse(itemes.description_back_results).taxManageNo
                    }}
                  </div>
                  <div>
                    纳税人识别码：{{
                      JSON.parse(itemes.description_back_results).taxNo
                    }}
                  </div>

                  <div>
                    企业注册号：{{
                      JSON.parse(itemes.description_back_results).regNo
                    }}
                  </div>
                  <div>
                    组织机构代码：{{
                      JSON.parse(itemes.description_back_results).orgCode
                    }}
                  </div>
                  <div>
                    登记户类别：{{
                      JSON.parse(itemes.description_back_results).regType
                    }}
                  </div>
                  <div>
                    法定代表人(负责人)姓名：{{
                      JSON.parse(itemes.description_back_results).frName
                    }}
                  </div>
                  <div>
                    证件种类：{{
                      JSON.parse(itemes.description_back_results).cardType
                    }}
                  </div>
                  <div>
                    证件号码：{{
                      JSON.parse(itemes.description_back_results).cardNo
                    }}
                  </div>
                  <div>
                    财务负责人姓名：{{
                      JSON.parse(itemes.description_back_results).financeName
                    }}
                  </div>
                  <div>
                    财务负责人证件号码：{{
                      JSON.parse(itemes.description_back_results).financeCardNo
                    }}
                  </div>
                  <div>
                    生产经营地址：{{
                      JSON.parse(itemes.description_back_results).address
                    }}
                  </div>
                  <div>
                    联系电话：{{
                      JSON.parse(itemes.description_back_results).contactPhone
                    }}
                  </div>
                  
                  <div>
                    限缴日期：{{
                      JSON.parse(itemes.description_back_results).payLimitDate
                    }}
                  </div>
                  
                  <div>
                    有无欠税：{{
                      JSON.parse(itemes.description_back_results).isOwingTax
                    }}
                  </div>
                  <div>
                    税种：{{
                      JSON.parse(itemes.description_back_results).taxType
                    }}
                  </div>
                  <div>
                    余额或金额：{{
                      JSON.parse(itemes.description_back_results).taxSum
                    }}
                  </div>
                  <div>
                    当期新发生欠税额或金额：{{
                      JSON.parse(itemes.description_back_results).taxSumNew
                    }}
                  </div>
                  
                  <div>
                    信息来源网址：{{
                      JSON.parse(itemes.description_back_results).crawlSource
                    }}
                  </div>
                  <div>
                    组代号码办证机构名称：{{
                      JSON.parse(itemes.description_back_results).bizOrg
                    }}
                  </div>
                  <div>
                    组代号码办证日期：{{
                      JSON.parse(itemes.description_back_results).bizDate
                    }}
                  </div> -->
                </div>
                <!--航空限飞查询-->
                <div
                  v-else-if="
                    itemes.parameters == 'aviationDiscredit' &&
                    itemes.description_back_results.indexOf('{') != -1
                  "
                >
                  <!-- <div>
                    姓名：{{ JSON.parse(itemes.description_back_results).name }}
                  </div>
                  <div>
                    身份证号码：{{
                      JSON.parse(itemes.description_back_results).cardNo
                    }}
                  </div> -->
                  <div>
                    列入原因：{{
                      JSON.parse(itemes.description_back_results).decideTitle
                    }}
                  </div>
                  <!-- <div>
                    法律文书名称：{{
                      JSON.parse(itemes.description_back_results).decideTitle
                    }}
                  </div>
                  <div>
                    法律文书编号：{{
                      JSON.parse(itemes.description_back_results).lawNo
                    }}
                  </div>
                  <div>
                    公布文件：{{
                      JSON.parse(itemes.description_back_results).content
                    }}
                  </div> -->
                  <div>
                    公布时间：{{
                      JSON.parse(itemes.description_back_results).publishDate
                    }}
                  </div>
                  <div>
                    公示机关：{{
                      JSON.parse(itemes.description_back_results).publishOrg
                    }}
                  </div>
                </div>
                <!--铁路限乘查询-->
                <div
                  v-else-if="
                    itemes.parameters == 'railwayForbiddenSearch' &&
                    itemes.description_back_results.indexOf('{') != -1
                  "
                >
                  <!-- <div>
                    失信人姓名：{{
                      JSON.parse(itemes.description_back_results).passenger_name
                    }}
                  </div> -->
                  <div>
                    列入原因：{{
                      JSON.parse(itemes.description_back_results)
                        .passenger_id_no
                    }}
                    <div>
                      限制起始时间：{{
                        JSON.parse(itemes.description_back_results).start_date
                      }}
                      <div>
                        类别，1：公示中；2：限制中：{{
                          JSON.parse(itemes.description_back_results).check_flag
                        }}
                      </div>
                    </div>
                    <!-- <div>
                    证件号码：{{
                      JSON.parse(itemes.description_back_results)
                        .passenger_id_no
                    }}
                  </div> -->
                  </div>
                </div>
                <!--个人工商-->
                <div
                  v-else-if="
                    itemes.parameters == 'idEntityNumberService' &&
                    itemes.description_back_results.indexOf('{') != -1
                  "
                >
                  <!--股东信息-->
                  <div
                    v-for="(itemone, indexone) in JSON.parse(
                      itemes.description_back_results
                    )"
                    v-bind:key="indexone"
                  >
                    <!-- <div style="color：red">=============》{{indexone=='ryposshas'?'股东信息':indexone=='ryposfrs'?'法人信息':'高管信息'}}：</div> -->
                    <div class="gudong">
                      {{
                        indexone == "ryposshas"
                          ? "股东信息"
                          : indexone == "ryposfrs"
                          ? "法人信息"
                          : "高管信息"
                      }}
                    </div>
                    <div
                      class="thewe"
                      v-for="(itemtwo, indextwo) in itemone"
                      v-bind:key="indextwo"
                    >
                      <div>企业（机构）名称：{{ itemtwo.entname }}</div>
                      <div>统一社会信用代码：{{ itemtwo.creditcode }}</div>
                      <div>成立时间：{{ itemtwo.esdate }}</div>
                      <div>企业（机构）类型：{{ itemtwo.enttype }}</div>
                      <div>行业名称：{{ itemtwo.industryphyname }}</div>
                      <div>注册资本（万元）：{{ itemtwo.regcap }}</div>
                      <div>企业状态：{{ itemtwo.entstatus }}</div>
                      <div>
                        职务：{{
                          indexone == "ryposshas"
                            ? "股东"
                            : indexone == "ryposfrs"
                            ? "法人"
                            : itemtwo.position
                        }}
                      </div>

                      <!-- <div>省份：{{itemtwo.regorgprovince}}</div>
                        <div>个人识别码：{{itemtwo.palgorithmid}}</div>
                        <div>认缴出资额（万元）：{{itemtwo.subconam}}</div>
                        <div>注册号：{{itemtwo.regno}}</div>
                        <div>高管信息：{{itemtwo.regcapcur}}</div>
                        <div>出资比例：{{itemtwo.fundedratio}}</div>
                        <div>认缴出资币种：{{itemtwo.currency}}</div>
                        <div>出资方式：{{itemtwo.conform}}</div> 
                        <div>查询人名称：{{ itemtwo.ryname }}</div>
                        -->

                      <div class="fenge"></div>
                    </div>
                  </div>
                </div>

                <div v-else>
                  <div>
                    {{ itemes.description_back_results
                    }}<span style="color: #ff4800">{{
                      itemes.parameters == "educationInfo_query"
                        ? personalmsg.certId
                          ? "(" + personalmsg.certId + ")"
                          : "(候选人未填写)"
                        : ""
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="hengmsg">
              <div>检验结果：</div>
              <img
                class="oknoimg"
                :src="itemes.back_results == 40 ? noimg : okimg"
              />
            </div>
            <div class="hengmsg">
              <div>信息来源：</div>
              <div>{{ itemes.api }}</div>
            </div>
            <div class="hengmsg6">
              <div>说明：{{ itemes.field_value }}</div>
            </div>
          </div>
        </div>
        <div class="smclass">
          <div class="sm1">{{ index }}说明</div>
          <div class="sm2">
            <div></div>
            <div>{{ projectdescription[index] }}</div>
          </div>
          <!-- <div class="sm2">
            <div>手机核实</div>
            <div>通过运营商系统，验证手机号是否实名认证</div>
          </div>
          <div class="sm2">
            <div>护照核实</div>
            <div>通过运营商系统，验证手机号是否实名认证</div>
          </div> -->
        </div>
      </div>
    </div>

    <!-- <div class="tipmsg">
      <div></div>
      <div>社会负面安全记录核实：</div>
    </div>
    <div class="shengmingclass2">
      <div class="allhengmsg">
        <div class="hengmsg">
          <div>项<span class="xmwid"></span>目：</div>
          <div class="heng2">社会负面安全记录核实</div>
        </div>
        <div class="hengmsg">
          <div>查询结果：</div>
          <div>无记录</div>
        </div>
        <div class="hengmsg">
          <div>核验结果：</div>
          <img class="oknoimg" :src="okimg" />
        </div>
        <div class="hengmsg">
          <div>信息来源：</div>
          <div>公安部</div>
        </div>
      </div>
      <div class="smclass">
        <div class="sm1">社会负面安全记录核实说明</div>
        <div class="sm2">
          <div>社会负面安全记录</div>
          <div>通过公民身份信息系统， 验证身份信息一致性和合法性</div>
        </div>
      </div>
    </div>
    <div class="tipmsg">
      <div></div>
      <div>教育背景和职业资质核验：</div>
    </div>
    <div class="shengmingclass2">
      <div class="allhengmsg">
        <div class="hengmsg">
          <div>项<span class="xmwid"></span>目：</div>
          <div class="heng2">国内学历核验</div>
        </div>
        <div class="hengmsg hengmsg2">
          <div>查询结果：</div>
          <div>
            <div>姓名：陈**</div>
            <div>身份证号：310107************</div>
            <div>性别：男</div>
            <div>生日：19**/**/**</div>
            <div>入学日期：2009年3月1日</div>
            <div>毕(结)业日期：2009年3月1日</div>
            <div>学校名称：上海大学</div>
            <div>专 业：计算机科学与技术</div>
            <div>学历类别：普通高等</div>
            <div>学 制：3</div>
            <div>学习形式：全日制</div>
            <div>层 次：本科</div>
            <div>毕(结)业： 毕业</div>
            <div>校(院)长姓名：***</div>
            <div>证书编号：102805201205001083</div>
          </div>
        </div>
        <div class="hengmsg">
          <div>核验结果：</div>
          <img class="oknoimg" :src="okimg" />
        </div>
        <div class="hengmsg">
          <div>信息来源：</div>
          <div>学信网</div>
        </div>
      </div>
      <div class="allhengmsg">
        <div class="hengmsg">
          <div>项<span class="xmwid"></span>目：</div>
          <div class="heng2">国内职业资质核验</div>
        </div>
        <div class="hengmsg hengmsg2">
          <div>查询结果：</div>
          <div>
            <div>识 别：上海市职业资格鉴定中心</div>
            <div>证书级别：三级/高级技能</div>
            <div>证书：维修电工（公众）</div>
            <div>实施部门：上海市职业资格鉴定中心</div>
            <div>认证号：112000100730197</div>
            <div>发行日期：2009年3月1日</div>
          </div>
        </div>
        <div class="hengmsg">
          <div>核验结果：</div>
          <img class="oknoimg" :src="okimg" />
        </div>
        <div class="hengmsg">
          <div>信息来源：</div>
          <div>人力资源及社会保障部</div>
        </div>
      </div>
      <div class="smclass">
        <div class="sm1">教育背景和职业资质核验说明</div>
        <div class="sm2">
          <div>国内学历核验</div>
          <div>通过教育部数据库，根据输入证书编号，核验学位学历真实性</div>
        </div>
        <div class="sm2">
          <div>国内职业资质核验</div>
          <div>
            根据证书编号，对人社部登记的国家职业资格技能证书真伪查询验证
          </div>
        </div>
      </div>
    </div>
    <div class="tipmsg">
      <div></div>
      <div>涉诉及处罚风险：</div>
    </div>
    <div class="shengmingclass2">
      <div class="allhengmsg">
        <div class="hengmsg">
          <div>项<span class="xmwid"></span>目：</div>
          <div class="heng2">个人涉诉记录查询</div>
        </div>
        <div class="hengmsg hengmsg2">
          <div>查询结果：</div>
          <div>
            <div>法院：上海市虹口区人民法院</div>
            <div>查询主体立场：有限合伙企业</div>
            <div></div>
            <div>
              文书缩略内容：…上海**酒店管理有限
              公司，陈**委托合同纠纷其他执行裁定 书，上海市虹口区人民法院
            </div>
            <div></div>
            <div>
              执行裁定书：(2019)沪0109执1996 申请执行人：****信息科技有限公司，
              住所地上海…
            </div>
            <div>
              标题：****信息科技有限公司与上海 **酒店管理有限公司、陈**委托合同
              纠纷其他执行判定书
            </div>
            <div></div>
            <div>案号：(2019)沪0109执1996</div>
            <div>胜败诉： 无胜败诉</div>
            <div>案件金额：无</div>
            <div>公布日期：2019-07-01</div>
          </div>
        </div>
        <div class="hengmsg">
          <div>核验结果：</div>
          <img class="oknoimg" :src="okimg" />
        </div>
        <div class="hengmsg">
          <div>信息来源：</div>
          <div>全国高法、省高法、地市级中级人民 法院</div>
        </div>
      </div>
      <div class="allhengmsg">
        <div class="hengmsg">
          <div>项<span class="xmwid"></span>目：</div>
          <div class="heng2">被执行人核验</div>
        </div>
        <div class="hengmsg hengmsg2">
          <div>查询结果：</div>
          <div>
            <div>
              经核实，截止本报告出具前候选人在
              中国大陆地区有被执行人名单中有记录
            </div>
          </div>
        </div>
        <div class="hengmsg">
          <div>核验结果：</div>
          <img class="oknoimg" :src="okimg" />
        </div>
        <div class="hengmsg">
          <div>信息来源：</div>
          <div>全国高法、省高法、地市级中级人民 法院</div>
        </div>
      </div>
      <div class="allhengmsg">
        <div class="hengmsg">
          <div>项<span class="xmwid"></span>目：</div>
          <div class="heng2">被执行人查询</div>
        </div>
        <div class="hengmsg hengmsg2">
          <div>查询结果：</div>
          <div>
            <div>主体名称：陈**</div>
            <div>案号：310101100000******90839</div>
            <div>法院：上海市虹口区人民法院</div>
            <div>案件状态：已下架</div>
            <div>案号：(2019)沪0109执恢00827</div>
            <div>执行标的：139162</div>
            <div>立案时间：2020-05-12</div>
          </div>
        </div>
        <div class="hengmsg">
          <div>核验结果：</div>
          <img class="oknoimg" :src="okimg" />
        </div>
        <div class="hengmsg">
          <div>信息来源：</div>
          <div>全国高法、省高法、地市级中级人民 法院</div>
        </div>
      </div>
      <div class="allhengmsg">
        <div class="hengmsg">
          <div>项<span class="xmwid"></span>目：</div>
          <div class="heng2">失信被执行人核验</div>
        </div>
        <div class="hengmsg hengmsg2">
          <div>查询结果：</div>
          <div>
            <div>
              经核实，截止本报告出具前候选人在 中国大陆地区无失信被执行人名单中
              记录
            </div>
          </div>
        </div>
        <div class="hengmsg">
          <div>核验结果：</div>
          <img class="oknoimg" :src="okimg" />
        </div>
        <div class="hengmsg">
          <div>信息来源：</div>
          <div>全国高法、省高法、地市级中级人民 法院</div>
        </div>
      </div>
      <div class="allhengmsg">
        <div class="hengmsg">
          <div>项<span class="xmwid"></span>目：</div>
          <div class="heng2">失信被执行人查询</div>
        </div>
        <div class="hengmsg hengmsg2">
          <div>查询结果：</div>
          <div>
            <div>
              经查询，截止本报告出具前候选人在 中国大陆地区无失信被执行人名单中
              记录
            </div>
          </div>
        </div>
        <div class="hengmsg">
          <div>核验结果：</div>
          <img class="oknoimg" :src="okimg" />
        </div>
        <div class="hengmsg">
          <div>信息来源：</div>
          <div>全国高法、省高法、地市级中级人民 法院</div>
        </div>
      </div>
      <div class="allhengmsg">
        <div class="hengmsg">
          <div>项<span class="xmwid"></span>目：</div>
          <div class="heng2">行政处罚核验</div>
        </div>
        <div class="hengmsg hengmsg2">
          <div>查询结果：</div>
          <div>
            <div>
              经查询，截止本报告出具前候选人在 中国大陆地区无失信被执行人名单中
              记录
            </div>
          </div>
        </div>
        <div class="hengmsg">
          <div>核验结果：</div>
          <img class="oknoimg" :src="okimg" />
        </div>
        <div class="hengmsg">
          <div>信息来源：</div>
          <div>全国高法、省高法、地市级中级人民 法院</div>
        </div>
      </div>
      <div class="allhengmsg">
        <div class="hengmsg">
          <div>项<span class="xmwid"></span>目：</div>
          <div class="heng2">限制高消费查询</div>
        </div>
        <div class="hengmsg hengmsg2">
          <div>查询结果：</div>
          <div>
            <div>案号：(2019)沪0109执恢00827</div>
            <div>法院：上海市松江区人民法院</div>
            <div>立案时间：2019/04/12</div>
            <div>公司：上海市***实业有限公司</div>
            <div>状态：公开中</div>
            <div>主体代码：31011000********98117</div>
            <div>主体名称：陈**</div>
          </div>
        </div>
        <div class="hengmsg">
          <div>核验结果：</div>
          <img class="oknoimg" :src="okimg" />
        </div>
        <div class="hengmsg">
          <div>信息来源：</div>
          <div>全国高法、省高法、地市级中级人民 法院</div>
        </div>
      </div>
      <div class="allhengmsg">
        <div class="hengmsg">
          <div>项<span class="xmwid"></span>目：</div>
          <div class="heng2">税务负面信息查询</div>
        </div>
        <div class="hengmsg hengmsg2">
          <div>查询结果：</div>
          <div>
            <div>经核实在本报告出具前，候选人在中 共大陆地区无税务负面信息</div>
          </div>
        </div>
        <div class="hengmsg">
          <div>核验结果：</div>
          <img class="oknoimg" :src="okimg" />
        </div>
        <div class="hengmsg">
          <div>信息来源：</div>
          <div>全国高法、省高法、地市级中级人民 法院</div>
        </div>
      </div>
      <div class="smclass">
        <div class="sm1">涉诉及处罚风险说明</div>
        <div class="sm2">
          <div>个人涉诉记录查询</div>
          <div>
            含劳动仲裁、经济纠纷等个人起诉/被诉记录，核验结果为有/无记录及记录详情（如有）
          </div>
        </div>
        <div class="sm2">
          <div>被执行人核验</div>
          <div>法院被执行人查询验证，核验结果为有/无被执行人记录</div>
        </div>
        <div class="sm2">
          <div>被执行人查询</div>
          <div>
            法院被执行人查询验证，核验结果为有/无被执行人记录及记录详情（如有）
          </div>
        </div>
        <div class="sm2">
          <div>失信被执行人核验</div>
          <div>法院失信被执行人查询验证，核验结果为有/无失信被执行人记录</div>
        </div>
        <div class="sm2">
          <div>失信被执行人查询</div>
          <div>
            法院失信被执行人查询验证，核验结果为有/无失信被执行人记录及记录详情（如有）
          </div>
        </div>
        <div class="sm2">
          <div>行政处罚核验</div>
          <div>
            行政主体对违反行政法律法规、尚未构成犯罪行为的处罚，核验结果为有/无处罚记录
          </div>
        </div>
        <div class="sm2">
          <div>限制高消费查询</div>
          <div>
            法院对被执行人限制高消费查询验证，核验结果为有/无限制高消费记录及记录详情（如有）
          </div>
        </div>
        <div class="sm2">
          <div>税务负面信息查询</div>
          <div>
            通过税务系统查询该纳税人/纳税人名下企业的税务负面记录，核验结果为有/无税务负面记录及记录详情（如有）
          </div>
        </div>
      </div>
    </div> -->

    <!--萝卜行为指数-->
    <!-- <div class="shengmingclass2">
      <div class="zhi1">萝卜行为指数：</div>
      <div class="zhi2">当前萝卜行为指数为53分，等级为低风险</div>
      <canvas id="canvasid" :width="202" :height="202"></canvas>
      <div class="smclass">
        <div class="sm1">萝卜行为指数说明</div>
        <div class="sm2">
          <div></div>
          <div>
            查询候选人有无道德行为处罚，能够更好的为企业提供候选人的行为道德风险诊断，本指数仅为企业提供够雇佣参考请谨慎使用。
          </div>
        </div>
      </div>
    </div> -->

    <!--道德风险-->
    <!-- <div class="tipmsg">
      <div></div>
      <div>道德风险：</div>
    </div>
    <div class="shengmingclass2">
      <div class="allhengmsg">
        <div class="hengmsg">
          <div>项<span class="xmwid"></span>目：</div>
          <div class="heng2">铁路限乘查询</div>
        </div>
        <div class="hengmsg hengmsg2">
          <div>查询结果：</div>
          <div>
            <div>
              经核实，截止本报告出具前候选人在
              中国大陆地区无铁路限乘处罚记录核验结果
            </div>
          </div>
        </div>
        <div class="hengmsg">
          <div>核验结果：</div>
          <img class="oknoimg" :src="okimg" />
        </div>
        <div class="hengmsg">
          <div>信息来源：</div>
          <div>全国高法、省高法、地市级中级人民 法院</div>
        </div>
      </div>
      <div class="allhengmsg">
        <div class="hengmsg">
          <div>项<span class="xmwid"></span>目：</div>
          <div class="heng2">航空限飞查询</div>
        </div>
        <div class="hengmsg hengmsg2">
          <div>查询结果：</div>
          <div>
            <div>
              经核实，截止本报告出具前候选人在 中国大陆地区无航空限飞处罚记录
            </div>
          </div>
        </div>
        <div class="hengmsg">
          <div>核验结果：</div>
          <img class="oknoimg" :src="okimg" />
        </div>
        <div class="hengmsg">
          <div>信息来源：</div>
          <div>全国高法、省高法、地市级中级人民 法院</div>
        </div>
      </div>
      <div class="smclass">
        <div class="sm1">道德风险说明</div>
        <div class="sm2">
          <div>铁路限乘查询</div>
          <div>
            根据铁路系统查询失信（身份造假、逃票等行为）情况，核验结果为有/无限乘记录及详情（如有）
          </div>
        </div>
        <div class="sm2">
          <div>航空限飞查询</div>
          <div>
            根据民航系统查询失信（逃票、纠纷等行为）情况，核验结果为有/无航空限飞记录及详情（如有）
          </div>
        </div>
      </div>
    </div> -->
    <!-- <div class="shengmingclass2">
      <div class="zhi1">萝卜商业利益风险：</div>
      <div class="zhi2">当前萝卜商业利益风险为高风险</div>
      <canvas
        id="canvasid2"
        :width="(mypx / 100) * 90"
        :height="(mypx / 100) * 30"
      ></canvas>
      <div class="smclass">
        <div class="sm1">说明</div>
        <div class="sm2">
          <div></div>
          <div>
            萝卜商业利益冲突指数反映了候选人对雇佣企业的同行商业利益的风险，指数越高说明风险越大，本指数仅为企业提供够雇佣参考请谨慎使用。
          </div>
        </div>
      </div>
    </div> -->
    <!--商业利益冲突-->
    <!-- <div class="tipmsg">
      <div></div>
      <div>商业利益冲突</div>
    </div>
    <div class="shengmingclass2">
      <div class="allhengmsg">
        <div class="hengmsg">
          <div>项<span class="xmwid"></span>目：</div>
          <div class="heng2">商业利益冲突</div>
        </div>
        <div class="hengmsg hengmsg2">
          <div>查询结果：</div>
          <div>
            <div>主体名称：上海*****商务咨询中心 (有限合伙)</div>
            <div>注册号：31011******981117</div>
            <div>企业类型：有限合伙公司</div>
            <div>注册资本(万元)：人民币1000万(元)</div>
            <div>企业状态：注销</div>
            <div>职务：公司法人</div>
          </div>
        </div>
        <div class="hengmsg">
          <div>核验结果：</div>
          <img class="oknoimg" :src="okimg" />
        </div>
        <div class="hengmsg">
          <div>信息来源：</div>
          <div>全国工商</div>
        </div>
      </div>
      <div class="allhengmsg">
        <div class="hengmsg">
          <div>项<span class="xmwid"></span>目：</div>
          <div class="heng2">商业利益冲突</div>
        </div>
        <div class="hengmsg hengmsg2">
          <div>查询结果：</div>
          <div>
            <div>主体名称：上海*****商务咨询中心 (有限合伙)</div>
            <div>注册号：31011******981117</div>
            <div>企业类型：有限合伙公司</div>
            <div>注册资本(万元)：人民币300万(元)</div>
            <div>企业状态：注销</div>
            <div>职务：公司法人</div>
          </div>
        </div>
        <div class="hengmsg">
          <div>核验结果：</div>
          <img class="oknoimg" :src="okimg" />
        </div>
        <div class="hengmsg">
          <div>信息来源：</div>
          <div>全国工商</div>
        </div>
      </div>
      <div class="allhengmsg">
        <div class="hengmsg">
          <div>项<span class="xmwid"></span>目：</div>
          <div class="heng2">商业利益冲突</div>
        </div>
        <div class="hengmsg hengmsg2">
          <div>查询结果：</div>
          <div>
            <div>主体名称：上海*****商务咨询中心 (有限合伙)</div>
            <div>注册号：31011******981117</div>
            <div>企业类型：有限合伙公司</div>
            <div>注册资本(万元)：人民币500万(元)</div>
            <div>企业状态：注销</div>
            <div>职务：公司法人</div>
          </div>
        </div>
        <div class="hengmsg">
          <div>核验结果：</div>
          <img class="oknoimg" :src="okimg" />
        </div>
        <div class="hengmsg">
          <div>信息来源：</div>
          <div>全国工商</div>
        </div>
      </div>
      <div class="smclass">
        <div class="sm1">商业利益冲突说明</div>
        <div class="sm2">
          <div>商业利益冲突</div>
          <div>
            查询候选人有无开过企业，并且这些企业和入职的公司有无同行业竞争的利益冲突
          </div>
        </div>
      </div>
    </div> -->

    <!--加150元，查看过往工作表现-->
    <!-- <div class="shengmingclass2 shengmingclass3">
      <img class="jiesuoclass" src="../../assets/jiesuo.png" alt="" />
      <div class="jia1">加150元，查看过往工作表现</div>
      <div class="jia2">+150 立即查看 ></div>
      <div class="jia3">查看样本 ></div>
      <div class="jia4">— 过往工作表现分析报告包含以下板块 —</div>
      <div class="allzclass">
        <div class="allz1">
          <div>
            <div class="zallclass">
              <img src="../../assets/z1.png" alt="" />
            </div>
            <div>
              <div>岗位及岗</div>
              <div>位职责</div>
            </div>
          </div>
          <div>
            <div class="zallclass">
              <img src="../../assets/z2.png" alt="" />
            </div>
            <div>专业能力</div>
          </div>
          <div>
            <div class="zallclass">
              <img src="../../assets/z3.png" alt="" />
            </div>
            <div>
              <div>优点或</div>
              <div>业绩</div>
            </div>
          </div>
          <div>
            <div class="zallclass">
              <img src="../../assets/z4.png" alt="" />
            </div>
            <div>
              <div>待改进</div>
              <div>之处</div>
            </div>
          </div>
        </div>
        <div class="allz2">
          <div>
            <div class="zallclass">
              <img src="../../assets/z1.png" alt="" />
            </div>
            <div>
              <div>岗位及岗</div>
              <div>位职责</div>
            </div>
          </div>
          <div>
            <div class="zallclass">
              <img src="../../assets/z2.png" alt="" />
            </div>
            <div>专业能力</div>
          </div>
          <div>
            <div class="zallclass">
              <img src="../../assets/z3.png" alt="" />
            </div>
            <div>
              <div>优点或</div>
              <div>业绩</div>
            </div>
          </div>
        </div>
      </div>
      <div class="smclass smclass_2">
        <div class="sm3">板块说明</div>
        <div id="sm4" class="sm2">
          <div></div>
          <div>
            我们将通过客服电话问询的方式，了解候选人在以往单位的岗位及岗位职责、专业能力、优点或业绩、待改进之处、团队合作/人际关系、抗压能力、是否愿意再次聘用这几个维度，为企业提供候选人在本报告之前的工作履历的主观调查信息，使得企业能够更好的了解候选人的优缺点和工作状况。
          </div>
        </div>
      </div>
    </div> -->

    <!--加150元，道德风险-->
    <!-- <div class="shengmingclass2 shengmingclass3">
      <img class="jiesuoclass" src="../../assets/jiesuo.png" alt="" />
      <div class="jia1">加150元，道德风险</div>
      <div class="jia2">+150 立即查看 ></div>
      <div class="jia3">查看样本 ></div>
      <div class="jia4">— 道德风险包含以下板块 —</div>
      <div>
        <div class="chaclass chaclass2">
          <div>
            <div class="chaclass3">
              <img src="../../assets/dao1.png" alt="" />
            </div>
            <div>铁路限乘查询</div>
          </div>
          <div class="wencla">?</div>
        </div>
        <div class="chaclass">
          <div>
            <div class="chaclass3">
              <img src="../../assets/dao1.png" alt="" />
            </div>
            <div>航空限飞查询</div>
          </div>
          <div class="wencla">?</div>
        </div>
      </div>
      <div class="smclass smclass_2">
        <div class="sm3">板块说明</div>
        <div id="sm4" class="sm2">
          <div></div>
          <div>
            查询候选人有无道德行为处罚，能够更好的为企业提供候选人的行为道德风险诊断，本指数仅为企业提供够雇佣参考请谨慎使用。
          </div>
        </div>
      </div>
    </div> -->

    <!--候选人技能统计-->
    <!-- <div class="shengmingclass2">
      <div class="peo2class"><img src="../../assets/peo3.png" alt="" /></div>
      <div class="jineng">候选人技能统计</div>
      <div class="jineng1">
        <div class="jineng2">
          <div>32</div>
          <div>条</div>
        </div>
        <div class="jineng3">技能及特长</div>
      </div>

      <div class="jineng1">
        <div class="jineng2">
          <div id="jitwo">12</div>
          <div>条</div>
        </div>
        <div class="jineng3">技能及特长获得了过往公司管理层的认证</div>
      </div>

      <div class="jia1 jiajia">
        <div>加19.9元，查看全部技能特长及</div>
        <div>多样化技能评价.</div>
      </div>
      <div class="jia2">+150 立即查看 ></div>
      <div class="jia3 jiajia2">查看样本 ></div>

      <div class="smclass">
        <div class="sm3">板块说明</div>
        <div id="sm4" class="sm2">
          <div></div>
          <div>
            此板块展现了候选人的技能和相关特长，能够为候选人建立更全面的人物画像，使企业能够全面的了解候选人的过往工作状态，降低招聘风险，减少招聘成本。
          </div>
        </div>
      </div>
    </div> -->
    <div class="guanyu">关于萝卜猎手</div>
    <div class="shengmingclass2">
      <div class="themsgluo">
        萝卜猎手（上海猎后征信服务有限公司）成立于2016年，致力于用数字化帮助中国1000万家企业精准招人、高效用人。<br /><br />
        作为行业领先的互联网+用工评估机构，萝卜猎手通过大数据、云计算，机器学习等先进技术，客观综合呈现个人用工的信用状况，已经在物业，科技，家政，区块链，酒店，租房，公共事业服务等上百个场景为企业，雇主及各类用户提供用工信用服务。<br /><br />
        我们将持续深耕互联网+用工评估，保护信息安全，助力企业降本增效，提高行业协作效率，为社会发展保驾护航，让企业和员工充分享受信息对等、公平招聘带来的便利，让人和人、人和企业之间因为信用而简单。
      </div>
      <div class="guanyu1">了解更多详情，请关注萝卜猎手公众号</div>
      <!-- <div class="guanyu2">
        <img :src="require('../../assets/zho.png')" />
      </div>
      <div class="guanyu3">公众号二维码</div> -->
    </div>
    <div class="zcc">技术支持：上海猎后征信服务有限公司</div>
    <sharepage ref="sharitid"></sharepage>
    <share ref="shareid"></share>
  </div>
</template>
<script>
import fenxiang from "../../assets/fenxiang.png";
import sharepage from "../../views/sharepage/index";
import share from "../../components/share/index";
import trans from "../../../util/trans.js";
export default {
  components: {
    sharepage,
    share,
  },
  data() {
    return {
      okimg: require("../../assets/okimg.png"),
      noimg: require("../../assets/noimg.png"),
      rightimg: require("../../assets/right.png"),
      tablist: [],
      mypx: window.document.documentElement.clientWidth,
      inaaa: 53, //传入的角度（共100）
      allaaa: 2.25, //指针总计角度
      aaa: 0.75, //指针初始角度
      bbb: 0,
      obj: "",
      aaamsg: "无风险",
      drawing: "", //画布对象
      context: "", //画布2D实例
      initX: 0, //轮盘初始X角度
      initY: 0, //轮盘初始Y角度

      allmsg: "", //所有数据
      personalmsg: "", //个人信息
      projectmsg: "", //项目信息

      dunyes: require("../../assets/dunyes.png"),
      dunno: require("../../assets/dunno.png"),

      name: "", //名字
      age: "", //年龄
      mobile: "", //手机号
      id_no: "", //身份证号
      company: "", //公司名
      zodiac: "", //生肖
      constellation: "", //星座
      backNo: "", //报告编码
      backTime: "", //报告时间
      projecttip: "", //项目名
      risknum: {
        yes: 0,
        no: 0,
      }, //风险项数目
      userback: {
        z_id: "",
        r_id: "",
      }, //数据信息
      islockmsg: false, //是否加密数据（分享时加密）
      projectdescription: "", //项目说明
      baogao: "", //套餐说明
      creatuser: "", //操作人员
      totimebao: "", //保险到期时间
      moremsg: "<span> .................. </span>",
      backnum: 0, //背景指数
      isloadmm: true, //是否展示加载框
      address: "", //归属地（来自哪里）
      phoneaddress: "", //现住地
      timees: "", //延迟
      show: false,
      baoid: "", //报告id
      tipmsg: "身份确认中...", //
    };
  },
  destroyed() {
    sessionStorage.removeItem("sharemsg");
    sessionStorage.removeItem("istop");
  },
  created() {
    let sharemm = JSON.parse(sessionStorage.getItem("sharemsg"));
    if (sharemm != "" && sharemm != undefined && sharemm.urlsharees) {
      if (sharemm.isshare && sharemm.isshare == 1) {
        this.islockmsg = true;
      }
      this.userback.z_id = sharemm.z_id[0];
      this.userback.r_id = sharemm.r_id[0];
    } else {
      this.userback.z_id = localStorage.getItem("z_id");
      this.userback.r_id = localStorage.getItem("r_id");
    }

    this.clickshare();
    // 加载提醒文字
    this.fontmeth();
    this.timees = setTimeout(() => {
      this.isloadmm = true;
    }, 600);
    this.$http
      .post("/firm/v1/Reportinfo/readNew", {
        reqType: "profile",
        id: this.userback.z_id, //用户id
        l_id: this.userback.r_id,
      })
      .then((res) => {
        if (this.timees) {
          setTimeout(() => {
            this.isloadmm = false;
            clearTimeout(this.timees);
          }, 3000);
        }
        this.allmsg = JSON.parse(res.data).data;
        console.log(this.allmsg);
        this.personalmsg = this.allmsg.report.report_data;
        this.projectmsg = this.allmsg.report.questLogsDate.info;

        //报告id
        this.baoid = this.allmsg.report.id;

        //背景指数
        this.backnum = this.allmsg.report.questLogsDate.percent;
        let ye = (this.personalmsg.back_time + "").split("-");
        //保险到期时间
        let d = new Date(ye[0] + "/" + ye[1] + "/" + ye[2]);
        d.setMonth(d.getMonth() + 3);
        this.totimebao = d.toLocaleString();
        //项目说明
        this.projectdescription = this.allmsg.report.questLogsDate.info_desc;

        //操作人员
        this.creatuser =
          this.personalmsg.create_user &&
          this.personalmsg.create_user.substr(0, 1) +
            new Array(this.personalmsg.create_user.length - 1).join("*");
        //套餐说明
        this.baogao = this.allmsg.report.selling_point;

        let n = this.personalmsg.id_no.substr(6, 8);
        //年龄
        this.age = this.getAge(
          n.substr(0, 4) + "-" + n.substr(4, 2) + "-" + n.substr(6, 2)
        );

        //归属地
        try {
          if (this.personalmsg.id_no) {
            let ad = trans.getAreaByIdCard(this.personalmsg.id_no.substr(0, 6));
            this.address = ad;
          }
        } catch (e) {
          this.address = "保密";
        }

        // //编号
        // this.baskNo =
        //是否是分享进入
        if (this.islockmsg) {
          //弹窗提醒
          this.show = true;

          //统计分享
          this.tongji(this.baoid);

          //姓名
          let a = this.personalmsg.name;
          this.name = a.substr(0, 1) + new Array(a.length).join("*");

          // 身份证号
          let id = this.personalmsg.id_no;
          this.id_no = id.substr(0, 6) + "********" + id.substr(-4, 4);

          //公司名
          let com = this.personalmsg.company;
          if (com.length < 7) {
            this.company =
              com.substr(0, 2) + new Array(com.length - 2).join("*");
          } else {
            this.company =
              com.substr(0, 2) +
              new Array(com.length - 2).join("*") +
              com.substr(-2, 3);
          }

          // 手机号
          let c = this.personalmsg.mobile + "";
          this.mobile = c.substr(0, 3) + "****" + c.substr(7);
        } else {
          //姓名
          this.name = this.personalmsg.name;

          // 身份证号
          this.id_no = this.personalmsg.id_no;

          //公司名
          this.company = this.personalmsg.company;

          // 手机号
          this.mobile = this.personalmsg.mobile + "";
        }

        //生肖
        this.zodiac = this.getshengxiao(this.personalmsg.id_no.substr(6, 4));

        // 星座
        this.constellation = this.getxingzuo(
          this.personalmsg.id_no.substr(10, 2),
          this.personalmsg.id_no.substr(12, 2)
        );

        //报告编码
        this.backNo = this.getNo(this.personalmsg.back_time); //编号

        //报告时间
        this.backTime = this.personalmsg.back_time;

        //所有项目名称
        this.getprojectname();

        //查询所有子项目，判断是否含有不为真的结果
        this.getprojectistrue();

        //查询手机号归属地
        this.getphoneaddress();
      });
  },
  methods: {
    /**
     * 字体更换
     */
    fontmeth() {
      let a = false;
      setInterval(() => {
        if (a) {
          this.tipmsg = "身份确认中...";
        } else {
          this.tipmsg = "合规性分析中...";
        }
        a = !a;
      }, 2000);
    },
    anchor(ti, anchorName) {
      /*找到锚点*/
      let anchorElement = document.getElementById(anchorName.charCodeAt());
      /*如果对应id的锚点存在，就跳转到锚点*/
      if (anchorElement) {
        anchorElement.scrollIntoView();
      }
    },

    tongji(baoid) {
      this.$http
        .post("/firm/v1/Login/getSource", {
          reqType: "userinfo",
          report_id: baoid,
        })
        .then(() => {});
    },
    /**查询手机号归属地 */
    getphoneaddress() {
      this.$http
        .post("/firm/v1/Login/getPhone", {
          reqType: "Login",
          phone: this.personalmsg.mobile,
        })
        .then((res) => {
          let ad = JSON.parse(res.data).data;
          this.phoneaddress = ad.province + "" + ad.city;
        });
    },
    tobackmeth() {
      this.$router.go(-1);
    },
    clickshare() {
      let obj = JSON.stringify({
        sharetitle: "查看报告",
        sharedesc: "点击查看报告",
        isshare: "1",
        z_id: this.userback.z_id,
        r_id: this.userback.r_id,
        share_phone: localStorage.getItem("phone"),
      });
      localStorage.setItem("shareobj", obj);
      // window.location = "/sharepage";
    },
    /**查询所有子项目（是否为真） */
    getprojectistrue() {
      Object.keys(this.projectmsg).forEach((res) => {
        let isnoes = true;
        if (this.projectmsg[res]) {
          for (let i = 0; i < this.projectmsg[res].length; i++) {
            if (this.projectmsg[res][i].back_results == "40") {
              isnoes = false;
              this.risknum.no++;
            } else {
              this.risknum.yes++;
            }
          }
        }
        if (!isnoes) {
          this.projectmsg[res].isok = "false";
        } else {
          this.projectmsg[res].isok = "true";
        }
      });
    },
    /**
     * 所有项目名称
     */
    getprojectname() {
      let ides = 1;
      Object.keys(this.projectmsg).forEach((res) => {
        this.tablist.push({
          id: ides,
          value: res + "",
        });
        ides++;
      });
      this.tablist.unshift({
        id: "0",
        value: "概述",
      });
    },
    //报告编码
    getNo(data) {
      var text = "";
      if (data) {
        text = data.split(" ").join("").split("-").join("").split(":").join("");
      }
      return text;
    },
    // 取星座, 参数分别是 月份和日期
    getxingzuo(m, d) {
      let aa = "摩羯水瓶双鱼白羊金牛双子巨蟹狮子处女天秤天蝎射手摩羯".substr(
        m * 2 - (d < "102223444433".charAt(m - 1) - -19) * 2,
        2
      );
      return aa;
    },
    /**
     * 获取生肖
     */
    getshengxiao(yyyy) {
      //by Go_Rush(阿舜) from http://ashun.cnblogs.com/
      var arr = [
        "猴",
        "鸡",
        "狗",
        "猪",
        "鼠",
        "牛",
        "虎",
        "兔",
        "龙",
        "蛇",
        "马",
        "羊",
      ];
      return /^\d{4}$/.test(yyyy) ? arr[yyyy % 12] : null;
    },
    /**获取年龄 */
    getAge(msg) {
      var birthday = new Date(msg.replace(/-/g, "/"));
      var d = new Date();
      var age =
        d.getFullYear() -
        birthday.getFullYear() -
        (d.getMonth() < birthday.getMonth() ||
        (d.getMonth() == birthday.getMonth() &&
          d.getDate() < birthday.getDate())
          ? 1
          : 0);
      return age;
    },
    getNo(data) {
      var text = "";
      if (data) {
        text = data.split(" ").join("").split("-").join("").split(":").join("");
      }
      return text;
    },
    clicktabs(title, mags) {
      if (title == 0) {
        window.location = "#overview";
      } else {
        window.location = "#" + mags.charCodeAt();
      }
    },
    dong(context) {
      context.beginPath();
      context.translate(this.initX, this.initY);
      context.strokeStyle = "#0056FF";
      context.lineWidth = "9";
      context.arc(0, 0, 80, 0.75 * Math.PI, this.aaa * Math.PI, false);
      context.stroke();
    },
    zhizhen(context) {
      //指针
      context.beginPath();
      context.rotate((this.bbb * Math.PI) / 180);
      context.fillStyle = "#0056FF";
      context.moveTo(-5, -5);
      context.lineTo(-44, 44);
      context.lineTo(5, 5);
      context.fill();
    },

    initText(context) {
      context.beginPath();
      context.font = "bold 15px Arial";
      context.textAlign = "center";
      context.fillText(this.aaamsg, 100, 150);

      context.beginPath();
      context.font = "bold 13px Arial";
      context.textAlign = "center";
      context.fillStyle = "#696969";
      context.fillText("0", 65, 170);
      context.fillText("低风险", 65, 190);

      context.fillText("100", 135, 170);
      context.fillText("低风险", 135, 190);
    },

    /**
     * 轮盘初始化
     */
    initRoulette() {
      this.drawing = document.getElementById("canvasid");

      // 确保浏览器支持<canvas>
      if (this.drawing.getContext) {
        this.context = this.drawing.getContext("2d");
        let context = this.context;

        this.initX = this.drawing.width / 2;
        this.initY = this.drawing.height / 2;

        if (this.aaa > (this.inaaa * (2.25 - 0.75)) / 100 + 0.75) {
          return;
        }
        if (this.aaa > 0.75 && this.aaa < 0.75 + ((2.25 - 0.75) / 100) * 33) {
          this.aaamsg = "低风险";
        } else if (
          this.aaa > 0.75 + ((2.25 - 0.75) / 100) * 33 &&
          this.aaa <
            0.75 + ((2.25 - 0.75) / 100) * 33 + ((2.25 - 0.75) / 100) * 33
        ) {
          this.aaamsg = "中风险";
        } else if (
          this.aaa >
          0.75 + ((2.25 - 0.75) / 100) * 33 + ((2.25 - 0.75) / 100) * 33
        ) {
          this.aaamsg = "高风险";
        } else {
          this.aaamsg = "无风险";
        }
        this.aaa += 0.01;
        this.bbb += 1.8;
        context.restore();
        context.clearRect(0, 0, this.drawing.width, this.drawing.height);
        context.save();
        context.beginPath();

        context.strokeStyle = "#DCDCDC";
        context.arc(
          this.initX,
          this.initY,
          100,
          0.75 * Math.PI,
          2.25 * Math.PI,
          false
        );
        context.stroke();

        context.beginPath();
        context.strokeStyle = "#0056FF";
        context.lineWidth = "3";
        context.arc(
          this.initX,
          this.initY,
          80,
          0.75 * Math.PI,
          2.25 * Math.PI,
          false
        );
        context.stroke();

        context.beginPath();
        context.fillStyle = "#0056FF";
        context.arc(this.initX, this.initY, 10, 0, 2 * Math.PI, false);
        context.fill();

        //绘制字体
        this.initText(context);

        //大圈
        this.dong(context);

        //指针
        this.zhizhen(context);

        //白圈
        context.beginPath();
        context.fillStyle = "white";
        context.arc(0, 0, 5, 0, 2 * Math.PI, false);
        context.fill();
        window.requestAnimationFrame(this.initRoulette);
      }
    },

    /**
     * 横柱初始化
     */
    // initColumn() {
    //   let columndoc = document.getElementById("canvasid2");
    //   let columnContext = columndoc.getContext("2d");
    //   columnContext.fillStyle = "red";

    //   columnContext.beginPath();
    //   columnContext.lineCap = "round";
    //   columnContext.lineWidth = (this.mypx / 100) * 5;
    //   let gradient = columnContext.createLinearGradient(
    //     (this.mypx / 100) * 5,
    //     (this.mypx / 100) * 15,
    //     (this.mypx / 100) * 5 + (this.mypx / 100) * 80,
    //     (this.mypx / 100) * 15
    //   );
    //   gradient.addColorStop(0, "#FEF900");
    //   gradient.addColorStop(1, "#FF0300");
    //   columnContext.strokeStyle = gradient;
    //   columnContext.moveTo((this.mypx / 100) * 5, (this.mypx / 100) * 15);
    //   columnContext.lineTo(
    //     (this.mypx / 100) * 5 + (this.mypx / 100) * 80,
    //     (this.mypx / 100) * 15
    //   );
    //   columnContext.stroke();

    //   //字体
    //   columnContext.beginPath();
    //   columnContext.font = "13px Arial";
    //   columnContext.textAlign = "center";
    //   columnContext.fillStyle = "#5F5F5F";
    //   columnContext.fillText(
    //     "0",
    //     (this.mypx / 100) * 3.9,
    //     (this.mypx / 100) * 23
    //   );
    //   columnContext.fillText(
    //     "100",
    //     (this.mypx / 100) * 5 + (this.mypx / 100) * 79,
    //     (this.mypx / 100) * 23
    //   );

    //   //进度
    //   columnContext.beginPath();
    //   columnContext.translate(-10, 0);
    //   // columnContext.fillStyle = "red";
    //   columnContext.lineWidth = 1;
    //   columnContext.strokeStyle = "red";
    //   // columnContext.lineJoin = "round";
    //   // columnContext.lineWidth = 20;
    //   // columnContext.fillRect(0,0,40,30);
    //   columnContext.moveTo(20, 10);
    //   columnContext.lineTo(50, 10);
    //   columnContext.arcTo(70, 30, 30, 30, 10);
    //   columnContext.lineTo(20, 30);
    //   columnContext.arcTo(0, 30, 10, 20, 10);
    //   columnContext.closePath();
    //   columnContext.stroke();
    // },
  },
  mounted() {
    setTimeout(() => {
      sessionStorage.setItem("istop", "1");
    });

    // window.requestAnimationFrame(this.initRoulette); //圆盘
    // this.initColumn();  //横柱
  },
};
</script>
<style scoped>
.fonttextclass {
  color: #ffffff;
  animation: fontani 1s infinite alternate;
}
@keyframes fontani {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.allz1 .zallclass img,
.allz2 .zallclass img {
  width: 0.46rem;
}
.zallclass {
  width: 0.89rem;
  height: 0.89rem;
  border-radius: 50%;
  background: linear-gradient(224deg, #facdab 0%, #fbb98a 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 50%;
  transform: translateX(-50%);
  margin-bottom: 0.16rem;
}
.allzclass {
  background: #fffaf6;
  border-radius: 0.18rem;
  border: 0.01rem solid #fbbc8f;
  padding: 0.5rem 0.46rem;
  margin-bottom: 0.7rem;
}
.jiesuoclass {
  position: absolute;
  right: -0.075rem;
  top: -0.075rem;
  width: 1.4rem;
}
.wencla {
  font-size: 0.3rem;
  padding: 0 0.14rem;
  font-weight: 600;
  color: #44494c;
  line-height: 0.42rem;
  border-radius: 50%;
  background: linear-gradient(245deg, #facdab 0%, #fbb98a 100%);
}
.ncl1 {
  color: #dc143c;
}
.ncl2 {
  color: #800080;
}
.ncl3 {
  color: #0000ff;
}
.luo1 {
  font-size: 0.36rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #576170;
  line-height: 0.5rem;
  margin-bottom: 0.2rem;
}
.luo2 {
  font-size: 0.34rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #888888;
  line-height: 0.5rem;
}
.luomsg {
  margin-top: 0.27rem;
  margin-bottom: 0.35rem;
}
.bt {
  font-size: 0.36rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 0.5rem;
  padding: 0.18rem 0;
  background: #ff6700;
  width: 100%;
  border: none;
  margin-bottom: 0;
}
.tanluo {
  background: #ffffff;
  border-radius: 0.3rem;
  padding-top: 0.32rem;
  text-align: center;
  font-size: 0.36rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #576170;
  line-height: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
}
.tanluo > div:first-child {
  margin: 0 0.43rem;
}
.tanluo img {
  width: 1.2rem;
}
.rightimgclass {
  width: 0.45rem;
}
.topmsgtheclass {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.topmsgtheclass > div:first-child {
  display: flex;
  align-items: center;
}
.hengmsg6 {
  font-size: 0.24rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #686868;
  line-height: 0.35rem;
  margin-top: 0.13rem;
}
.loadmm {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 1000;
  /* background-color: rgba(255, 255, 255, 0.5); */
  /* background-color: #ffffff; */
  /* background-color: rgba(255, 255, 255, 1); */
  background-color: rgba(0, 0, 0, 0.7);
  font-size: 0.28rem;
  font-weight: 600;
  color: #999999;
  line-height: 0.4rem;
}
.loadmm .loadmimg {
  width: 3rem;
  height: 3rem;
}
.chaclass {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  font-size: 0.28rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #44494c;
  line-height: 0.4rem;
  justify-content: space-between;
  background: #fbf3ed;
  padding: 0.11rem 0.08rem;
  padding-right: 0.2rem;
  border-radius: 0.5rem;
}
.chaclass2 {
  margin-bottom: 0.2rem;
}
.chaclass > div:first-child {
  display: flex;
  align-items: center;
}
.chaclass3 {
  width: 0.69rem;
  height: 0.69rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(245deg, #facdab 0%, #fbb98a 100%);
  margin-right: 0.23rem;
}
.chaclass img {
  width: 0.32rem;
  height: 0.32rem;
}
.gudong {
  font-weight: bold;
  color: #ff4800;
}
.fenge {
  height: 1px;
  background-color: #bbbbbb;
  margin: 0.35rem 0;
}
.thewe div {
  width: 100%;
}
.zcc {
  font-size: 0.18rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ff3100;
  line-height: 0.2rem;
  text-align: center;
  opacity: 0.4;
  padding-top: 0.02rem;
  padding-bottom: 0.66rem;
}
.guanyu {
  font-size: 0.3rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ff4800;
  line-height: 0.42rem;
  margin: 0.22rem 0.4rem;
}
.guanyu1 {
  font-size: 0.32rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #222222;
  line-height: 0.33rem;
  margin-top: 0.53rem;
  margin-bottom: 0.46rem;
  text-align: center;
}
.guanyu2 {
  text-align: center;
  margin-bottom: 0.27rem;
}
.guanyu2 img {
  width: 2.61rem;
}
.guanyu3 {
  font-size: 0.28rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #686868;
  line-height: 0.33rem;
  text-align: center;
}
.themsgluo {
  font-size: 0.24rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #686868;
  line-height: 0.33rem;
}
.jiajia {
  margin-top: 0.36rem;
}
.allmsgclass {
  width: 100%;
  overflow: hidden;
}
.jineng {
  font-size: 0.32rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #686868;
  line-height: 0.45rem;
  text-align: center;
  margin-top: 0.23rem;
  margin-bottom: 0.3rem;
}
.jineng1 {
  background: #ffffff;
  box-shadow: 0px 0px 0.1rem 0px rgba(144, 144, 144, 0.5);
  display: flex;
  padding: 0.2rem;
  align-items: center;
  margin-bottom: 0.23rem;
}
.jineng2 {
  padding: 0 0.1rem;
  display: flex;
  align-items: center;
  border-right-color: #dedede;
  border-right-style: solid;
  border-right-width: 1px;
  margin-right: 0.1rem;
}

.jineng2 > div:first-child {
  font-size: 0.6rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ff3131;
  line-height: 0.64rem;
  margin-right: 0.1rem;
}
.jineng2 #jitwo {
  color: #ffa500;
}
.jineng2 > div:nth-child(2) {
  font-size: 0.24rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #686868;
  line-height: 0.33rem;
}
.jineng3 {
  font-size: 0.24rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #686868;
  line-height: 0.33rem;
}
.peo2class {
  text-align: center;
}
.peo2class img {
  width: 1.86rem;
}
#sm4 {
  margin-top: -0.25rem;
}
#sm4 > div:nth-child(2) {
  font-size: 0.28rem;
  font-weight: 400;
  color: #606266;
  line-height: 0.4rem;
}
.allz1,
.allz2 {
  display: flex;
  text-align: center;
  justify-content: space-between;
  font-size: 0.24rem;
  font-weight: 600;
  color: #d5a07b;
  line-height: 0.34rem;
}
.allz2 {
  justify-content: center;
  margin-top: 0.36rem;
}
.allz2 > div:nth-child(2) {
  margin: 0 0.7rem;
}
.allz1 img {
  width: 0.89rem;
}
.allz2 img {
  width: 0.89rem;
}
.jia1 {
  text-align: center;
  font-size: 0.28rem;
  font-weight: 600;
  color: #303133;
  line-height: 0.4rem;
}
.jia2 {
  text-align: center;
  margin: 0.27rem 0;
  padding: 0.21rem 0;
  background: #fd6061;
  border-radius: 0.12rem;
  font-size: 0.32rem;
  font-weight: 600;
  color: #ffffff;
  line-height: 0.5rem;
}
.jia3 {
  margin-bottom: 0.38rem;
  text-align: center;
  font-size: 0.28rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #dfa176;
  line-height: 0.5rem;
}
.jiajia2 {
  margin-bottom: 0.77rem;
}
.jia4 {
  text-align: center;
  margin-bottom: 0.42rem;
  font-size: 0.28rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #303133;
  line-height: 0.5rem;
}
#canvasid2 {
  margin-left: -0.2rem;
}
#canvasid {
  margin-left: 50%;
  transform: translateX(-50%);
  margin-bottom: 0.3rem;
}
.zhi1 {
  font-size: 0.3rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ff4800;
  line-height: 0.42rem;
}
.zhi2 {
  font-size: 0.28rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #686868;
  line-height: 0.4rem;
  margin-top: 0.24rem;
  margin-bottom: 0.38rem;
}
.hengmsg:last-child {
  align-items: baseline;
}
.hengmsg:last-child > div:first-child {
  min-width: 1.4rem;
}
.xmwid {
  width: 0.55rem;
  display: inline-block;
}
.sm1 {
  background: #ff4800;
  box-shadow: 0px 0px 10px 0px rgba(137, 137, 137, 0.5);
  border-radius: 0.18rem 0px 0.18rem 0px;
  font-size: 0.28rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 0.4rem;
  padding: 0.07rem 0.28rem;
  width: 3.94rem;
}
.sm3 {
  background: linear-gradient(250deg, #facdab 0%, #fbb98a 100%);
  border-radius: 0.5rem;
  font-size: 0.28rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #353432;
  line-height: 0.5rem;
  padding: 0.13rem 0;
  width: 2.58rem;
  text-align: center;
  margin-left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.sm2 {
  margin: 0 0.19rem;
  margin-top: 0.24rem;
}
.sm2 > div:first-child {
  font-size: 0.28rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 0.4rem;
  margin-bottom: 0.07rem;
}
.sm2 > div:nth-child(2) {
  font-size: 0.24rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #686868;
  line-height: 0.33rem;
}
.sm5 {
  width: 0.6rem;
}
.smclass {
  background: #ecf3fd;
  box-shadow: 0px 0px 0.1rem 0px rgba(137, 137, 137, 0.5);
  border-radius: 0.18rem;
  padding-bottom: 0.37rem;
}
.smclass_2 {
  background: #fffaf6;
  border-radius: 0.18rem;
  border: 0.01rem solid #fbbc8f;
  box-shadow: none;
}
.oknoimg {
  width: 0.4rem;
}
.allhengmsg {
  padding-bottom: 0.37rem;
  border-bottom-color: #bbbbbb;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  margin-bottom: 0.23rem;
}
.allhengmsg:last-child {
  border: none;
  padding: 0;
}
.hengmsg {
  display: flex;
  align-items: center;
  font-size: 0.28rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #686868;
  line-height: 0.5rem;
}
.hengmsg2 {
  align-items: baseline;
}
.hengmsg2 > div:first-child {
  min-width: 1.4rem;
}
.heng2 {
  color: #2f86fb;
}
.tipmsg {
  display: flex;
  align-items: center;
  margin: 0 0.3rem;
}
.tipmsg > div:first-child {
  width: 0.14rem;
  height: 0.4rem;
  background: #ff4800;
  border-radius: 0.07rem;
}
.tipmsg > div:nth-child(2) {
  font-size: 0.32rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #222222;
  line-height: 0.45rem;
  margin-left: 0.29rem;
}

.shuomingclassthe img {
  width: 100%;
}
.fx {
  font-size: 0.24rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  line-height: 0.4rem;
  text-align: center;
}
.zhishuimgdiv {
  width: 4.66rem;
  height: 4.66rem;
  background-image: url("../../assets/zhishu.png");
  background-size: 100% auto;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 1.2rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ff0000;
  line-height: 1.18rem;
  margin-left: 50%;
  transform: translateX(-50%);

  margin-top: 0.22rem;
  margin-bottom: 0.45rem;
}
.zhishu2 {
  font-size: 0.28rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #686868;
  line-height: 0.4rem;
}
.zhishuclass {
  margin-top: 0.42rem;
  margin-bottom: 0.24rem;
  font-size: 0.3rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ff4800;
  line-height: 0.42rem;
}
.smclass2 {
  font-size: 0.28rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #686868;
  line-height: 0.4rem;
  margin-bottom: 0.27rem;
}
#htid >>> b {
  font-weight: bold !important;
}
#htid >>> i {
  font-style: italic;
}
#htid >>> u {
  text-decoration: underline;
}
.bgsm {
  font-size: 0.3rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ff4800;
  line-height: 0.42rem;
  margin-top: 0.25rem;
  margin-bottom: 0.26rem;
}
.nomsgclass5 {
  height: 1px;
  background-color: #bbbbbb;
}
.dunmsgclass {
  margin-top: 0.55rem;
  margin-bottom: 0.39rem;
}
.menuclass img {
  width: 0.31rem;
  height: 0.41rem;
  margin-right: 0.32rem;
}
.menuclass {
  display: flex;
  align-items: center;

  font-size: 0.28rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #707070;
  line-height: 0.3rem;
  margin-top: 0.27rem;
}
.numberclass {
  position: absolute;
  width: 1.4rem;
  height: 0.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0.18rem;
  right: 0.33rem;
  font-size: 0.5rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 0.7rem;
  overflow-x: auto;
}
.topimgthe {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.topimgthe > div {
  width: 3rem;
  height: 1.51rem;
  position: relative;
}
.topimgthe > div:first-child {
  background-image: url("../../assets/noback.png");
  background-size: 100% 100%;
}
.topimgthe > div:nth-child(2) {
  background-image: url("../../assets/yesback.png");
  background-size: 100% 100%;
}
.overviewclass {
  margin: 0.32rem 0.24rem;
  padding: 0.32rem 0.38rem;
  background: #ffffff;
  box-shadow: 0px 0px 0.1rem 0px rgba(73, 73, 73, 0.5);
  border-radius: 0.18rem;
}
.tabclass {
  margin-top: 0.35rem;
  margin-bottom: 0.32rem;
  width: 100%;
  overflow: hidden;
}
.shengmingclass2 {
  margin: 0.36rem 0.24rem;
  padding: 0.31rem 0.37rem;
  background: #ffffff;
  box-shadow: 0px 0px 0.1rem 0px rgba(73, 73, 73, 0.5);
  border-radius: 0.18rem;
}
.shengmingclass3 {
  background: #ffffff;
  box-shadow: 0rem 0rem 0.1rem 0rem #bfbfbf;
  border-radius: 0.18rem;
  border: 0.07rem solid #353432;
  position: relative;
}
.shengmingclass {
  margin: 0.36rem 0.24rem;
  padding: 0.31rem 0.37rem;
  background: #ffffff;
  box-shadow: 0px 0px 0.1rem 0px rgba(73, 73, 73, 0.5);
  border-radius: 0.18rem;
}
.shengmingclass > div:first-child {
  font-size: 0.3rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ff4800;
  line-height: 0.42rem;
}
.shengmingclass > div:nth-child(2) {
  margin-top: 0.05rem;
  font-size: 0.24rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #686868;
  line-height: 0.4rem;
}
.bao2 {
  font-size: 0.28rem;

  font-weight: 600;
  color: #999999;
  line-height: 0.55rem;
}
.bao2 span:nth-child(2) {
  color: #ff4800;
}
.bao3 {
  font-size: 0.28rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #999999;
  line-height: 0.55rem;
}
.bao3 span:nth-child(2) {
  color: #686868;
}
.nomsgclass4 {
  height: 1px;
  background-color: #bbbbbb;
  margin-top: 0.21rem;
  margin-bottom: 0.21rem;
}
.caoclass {
  font-size: 0.28rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #999999;
  line-height: 0.55rem;
}
.caoclass span:nth-child(2) {
  color: #686868;
}
.nomsgclass3 {
  margin-top: 0.25rem;
  margin-bottom: 0.21rem;
}
.danweiclass {
  font-size: 0.28rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #999999;
  line-height: 0.55rem;
}
.danweiclass span:nth-child(2) {
  color: #686868;
}
.nomsgclass2 {
  height: 1px;
  background-color: #bbbbbb;
  margin-top: 0.17rem;
  margin-bottom: 0.25rem;
}
.baomsgclass .thespanclass {
  color: #ff0900;
}
.nei {
  display: flex;
}
.nei > div:first-child {
  min-width: 1.4rem;
}
.baomsgclass span {
  color: #999999;
}
.baomsgclass {
  font-size: 0.28rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #686868;
  line-height: 0.55rem;
}
.nomsgclass {
  height: 1px;
  background-color: #bbbbbb;
  margin-top: 0.27rem;
  margin-bottom: 0.15rem;
}
.per span {
  display: inline-block;
  width: 0.55rem;
}
.per {
  display: flex;
  align-items: center;

  font-size: 0.28rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #686868;
  line-height: 0.55rem;
}
.per1xlass > div:nth-child(2) {
  margin-right: 0.2rem;
}
.per1xlass > div:nth-child(3) {
  margin-right: 0.3rem;
}
.per1xlass > div:nth-child(4) {
  margin-right: 0.3rem;
}
.per1xlass {
  margin-top: 0.2rem;
}
.persionmsg {
  position: relative;
}
.persionmsg .toux {
  position: absolute;
  width: 1.4rem;
  top: 0.38rem;
  right: 0.56rem;
}
.persionmsg img {
  width: 0.27rem;
  margin-right: 0.18rem;
}
.persionmsg > div:first-child {
  font-size: 0.28rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #999999;
  line-height: 0.4rem;
}
.persionmsg > div:nth-child(2) {
  font-size: 0.5rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #222222;
  line-height: 0.7rem;
}
.persionmsg {
  margin: 0.26rem 0.24rem;
  padding: 0.41rem 0.45rem;
  background: #ffffff;
  box-shadow: 0px 0px 0.1rem 0px rgba(73, 73, 73, 0.5);
  border-radius: 0.1rem;
}
.tipclass > div:first-child {
  color: #ff4800;
  font-size: 0.3rem;
  line-height: 0.42rem;
  font-weight: 600;
}
.tipclass > div:nth-child(2) {
  font-size: 0.28rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #999999;
  line-height: 0.4rem;
  margin-top: 0.08rem;
}
.tipclass span {
  color: #ff0000;
}
.tipclass {
  margin: 0.24rem;
  background: #ffffff;
  box-shadow: 0px 0px 0.1rem 0px rgba(73, 73, 73, 0.5);
  border-radius: 0.18rem;
  padding: 0.31rem 0.37rem;
  margin-top: -1.8rem;
}
.imgbao {
  display: flex;
  align-items: center;
  margin-right: 0.26rem;
}
.toprightclass > div:nth-child(2) {
  font-size: 0.24rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.33rem;
  display: flex;
  align-items: center;
}
.toprightclass > div:nth-child(2) img {
  width: 0.2rem;
  height: 0.2rem;
  margin-right: 0.08rem;
}
.toprightclass > div:first-child {
  font-size: 0.32rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 0.45rem;
  margin-bottom: 0.13rem;
}
.luoclass img {
  width: 0.78rem;
  height: 0.99rem;
  margin-right: 0.19rem;
}
.topmsgclass {
  display: flex;
  align-items: center;
  background: linear-gradient(
    360deg,
    rgba(255, 127, 0, 0) 0%,
    #ff7f00 9%,
    #ff4800 100%
  );
  justify-content: space-between;
  height: 1.9rem;
  padding: 0 0.24rem;
  padding-bottom: 1.8rem;
  padding-top: 0.6rem;
}
.shu {
  opacity: 0.9;
}
</style>>
